import React, { useEffect, useState, useCallback } from 'react';
import styles from './SettingsPageInformationEdit.module.css';
import { request } from '../../../service/request';
import { useDispatch, useSelector } from 'react-redux';
import {
  setInformationAboutUser,
  getListOfCountries,
} from '../../../redux/actions';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import { fetch_request } from '../../../service/request';
import isEmpty from 'lodash.isempty';
import { FormattedMessage, useIntl } from 'react-intl';
import Input from '../../../components/Input/Input';
import debounce from 'lodash.debounce';
import GroupButtons from '../../NewAuth/CreateMembership/GroupButtons';
import Loader from '../../../components/Loader/Loader';

const SettingsPersonalInformationEdit = ({ setIsEditing }) => {
  const user = useSelector((state) => state.user.user || {});
  const intl = useIntl();
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    gender: '',
    phone_number: '',
    occupation: '',
    address: '',
    birthDate: null,
    language_id: '',
    time_zone: '',
    country_id: '',
    timeZone: '',
  });
  const [languages, setLanguages] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    if (!isEmpty(user)) {
      setFormData({
        fullName: user.full_name ? user.full_name : '',
        gender: user.gender ? user.gender : '',
        email: user.email ? user.email : '',
        phone_number: user.phone_number ? user.phone_number : '',
        occupation:
          user.occupation && user.occupation !== 'null' ? user.occupation : '',
        address: user.address ? user.address : '',
        country_id: user.country_id ? user.country_id : '',
        birthDate: user.date_of_birth ? user.date_of_birth : null,
        time_zone: user.time_zone ? user.time_zone : '',
        language_id: !isEmpty(user.preferred_language)
          ? user.preferred_language.id
          : '',
      });
    }
    setLoading(false);
  }, [user]);

  const handleCancel = (event) => {
    event.preventDefault();
    setIsEditing(false);
  };

  useEffect(() => {
    fetch_request('/api/languages', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then((res) => res.json())
      .then((data) => setLanguages(data.languages));
  }, []);

  useEffect(() => {
    fetch_request('/api/countries')
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        dispatch(getListOfCountries(data));
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  const editOrCompleteUserInfo = async () => {
    try {
      const userData = {
        user: {
          email: formData.email,
          full_name: formData.fullName,
          occupation: formData.occupation,
          phone_number: formData.phone_number,
          gender: formData.gender,
          date_of_birth: formData.birthDate,
          user_address_attributes: {
            address: formData.address,
          },
          country_id: formData.country_id,
          time_zone: formData.timeZone,
          preferred_language_attributes: {
            language_id: formData.language_id,
          },
        },
      };

      const response = await request(`/users/${user.slug}`, userData, 'put');
      if (response.status >= 200 && response.status < 300 && response.data) {
        try {
          dispatch(setInformationAboutUser(response.data));
          localStorage.setItem('user-slug', response.data.user?.slug);
          setIsEditing(false);
          showUserNotification(
            intl.formatMessage({ id: 'personal_information_updated' }),
            'success'
          );
        } catch (err) {
          console.log(err);
          showUserNotification(
            err.response.data.error || err.message,
            'success'
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validateEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const debouncedValidate = useCallback(
    debounce((value) => {
      if (validateEmail(value)) {
        setIsValidEmail(false);
      } else {
        setIsValidEmail(true);
      }
    }, 500),
    []
  );

  const handleEmailChange = (value) => {
    setFormData({ ...formData, email: value });
    debouncedValidate(value);
  };

  const handlePhoneNumberChange = (value) => {
    const str = value.replace(/[^\d]/g, '');
    if (str.length > 0) {
      setFormData({ ...formData, phone_number: str });
    } else {
      setFormData({ ...formData, phone_number: '' });
    }
  };

  const findCountry = (countries) => {
    if (countries.length > 0) {
      const defaultCountry = countries.find(
        (country) => country.dial_code === '+47'
      );
      const country = countries.find(
        (country) => country.id === formData.country_id
      );
      return country ? country : defaultCountry;
    }
  };

  let formInputs = [
    {
      label: 'settings_fullName',
      type: 'text',
      placeholder: 'auth_enterFullName',
      value: formData.fullName,
      autoComplete: 'off',
      error: false,
      disabled: false,
      action: (value) => {
        if (value[0] !== ' ') {
          setFormData({
            ...formData,
            fullName: value
              .replace(/[0-9]/g, '')
              .replace(/[\"'@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/g, ''),
          });
        }
      },
    },
    {
      label: 'settings_billingEmail',
      type: 'email',
      placeholder: 'type_your_email',
      value: formData.email,
      autoComplete: 'off',
      error: isValidEmail,
      errorKey: 'email_error',
      disabled: user.is_email_verified,
      isVerified: user.is_email_verified,
      infoLabel: 'read_only',
      action: (value) => {
        handleEmailChange(value);
      },
    },
    {
      label: 'auth_mobileNumber',
      type: 'text',
      placeholder: 'auth_enterPhoneNumber',
      value: formData.phone_number,
      error: false,
      infoLabel: 'read_only',
      errorKey: 'add_validNumber',
      isVerified: user.is_phone_number_verified,
      disabled: user.is_phone_number_verified,
      action: (value) => {
        handlePhoneNumberChange(value);
      },
      country: {
        country: findCountry(countries),
        setCountry: (value) =>
          setFormData({ ...formData, country_id: value.id }),
      },
    },
    {
      label: 'settings_address',
      type: 'text',
      placeholder: 'settings_address',
      value: formData.address,
      error: false,
      disabled: false,
      action: (value) => {
        setFormData({ ...formData, address: value });
      },
    },
    {
      label: 'settings_occupation',
      type: 'text',
      placeholder: 'settings_occupation',
      value: formData.occupation,
      error: false,
      disabled: false,
      action: (value) => {
        setFormData({ ...formData, occupation: value });
      },
    },
    {
      label: 'settings_birthday',
      type: 'datePicker',
      placeholder: 'settings_birthday',
      value: formData.birthDate,
      error: false,
      disabled: false,
      action: (value) => {
        setFormData({ ...formData, birthDate: value });
      },
    },
    {
      label: 'settings_language',
      type: 'select',
      placeholder: 'settings_language',
      list: languages,
      value: formData.language_id,
      error: false,
      disabled: false,
      action: (value) => {
        setFormData({ ...formData, language_id: value });
      },
    },
    {
      label: 'settings_gender',
      type: 'gender',
      gendersList: ['mann', 'kvinne', 'annen'],
      action: (value) => {
        setFormData({ ...formData, gender: value });
      },
      value: formData.gender,
      autoComplete: 'off',
      error: false,
      errorKey: 'auth_notSame',
      disabled: false,
    },
  ];

  if (loading || countries.length === 0) {
    return <Loader />;
  }

  return (
    <div className={styles['settings-personal-information']}>
      <div className={styles['settings-personal-information_content']}>
        <div
          className={
            styles['settings-personal-information-edit_content-headers_header']
          }
        >
          <FormattedMessage id='settings_personalInformation' />
        </div>
        {formInputs.map((item, index) => (
          <Input item={item} key={index} />
        ))}
        <GroupButtons
          onNext={editOrCompleteUserInfo}
          onBack={handleCancel}
          backKey='settings_cancel'
          nextKey='auth_save'
        />
      </div>
    </div>
  );
};

export default SettingsPersonalInformationEdit;
