import React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import styles from './SubscriptionMembers.module.css';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  large: {
    width: 50,
    height: 50,
    borderRadius: 10,
  },
}));

const SubscriptionMember = ({
  subscription,
  updateSubscriptionValidation,
  handelRemoveInvitation,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const onRemoveMember = (member) => {
    updateSubscriptionValidation('members', {
      ...member,
      status: member.status === 'active' ? 'requested_to_remove' : 'active',
    });
  };

  const getKey = (member) => {
    return member.status === 'active' ? 'settings_remove' : 'settings_cancel';
  };

  return (
    <div style={{ marginTop: 24 }}>
      {subscription && subscription.subscription_members.length > 0 && (
        <div className={styles['subscription-members']}>
          <div className={styles['member-heading']}>
            <FormattedMessage id='active_members' />
          </div>
          <div className={styles['member-sub-heading']}>
            <FormattedMessage id='active_members_message' />
          </div>
          <div className={styles['member-card']}>
            {subscription.subscription_members.map((member, index) => {
              return (
                <React.Fragment>
                  <div className={styles['member-container']} key={index}>
                    <div className={styles['avatar-wrapper']}>
                      <Avatar
                        src={member?.user_avatar}
                        className={classes.large}
                      />
                      <div className={styles['user-name-container']}>
                        <div className={styles['user-name']}>
                          {member?.full_name}
                        </div>
                        {member.user_id !== subscription.user_id && (
                          <button
                            className={styles['remove-button']}
                            onClick={() => onRemoveMember(member)}
                          >
                            <FormattedMessage id={getKey(member)} />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
      {subscription && subscription.subscription_invitations.length > 0 && (
        <div className={styles['subscription-invitations']}>
          <div className={styles['member-heading']}>
            <FormattedMessage id='active_invitations' />
          </div>
          <div className={styles['member-sub-heading']}>
            <FormattedMessage id='active_invitations_message' />
          </div>
          <div className={styles['member-card']}>
            {subscription.subscription_invitations.map((invite, index) => {
              return (
                <div className={styles['member-container']} key={index}>
                  <div className={styles['avatar-wrapper']}>
                    <Avatar
                      src={invite?.user_avatar}
                      className={classes.large}
                    />
                    <div className={styles['user-name-container']}>
                      <div className={styles['user-name']}>
                        {invite?.full_name}
                      </div>
                      <button
                        className={styles['remove-button']}
                        onClick={() =>
                          handelRemoveInvitation(invite.invitation_id)
                        }
                      >
                        <FormattedMessage id='settings_remove' />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionMember;
