import './SecondStep.css';
import React, { useState } from 'react';
import { fetch_request } from '../../../../../../service/request';
import BirthIcon from '../../../../../../img/Birth.svg';
import DeathIcon from '../../../../../../img/Death.svg';
import HorizontalLine from '../../../../../../img/HorizontalLine.svg';
import OrangeDot from '../../../../../../img/OrangeDot.svg';
import EmptyOrangeDot from '../../../../../../img/EmptyOrangeDot.svg';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles, TextField } from '@material-ui/core';
import { request } from '../../../../../../service/request';
import { setLinkedGravesRedux } from '../../../../../../redux/actions';
import showUserNotification from '../../../../../../components/UserNotification/showUserNotification';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

const useStyle = makeStyles(() => ({
  input: {
    padding: '10px !important',
  },
}));

const SecondStep = (props) => {
  const dispatch = useDispatch();
  const sourceGrave = props.sourceGrave;
  const targetGrave = props.targetGrave;
  const grave = useSelector((state) => state.grave.grave);
  const [sourceGraveToTargetRelation, setSourceGraveToTargetRelation] =
    useState({});
  const [targetGraveToSourceRelation, setTargetGraveToSourceRelation] =
    useState({});
  const [relationList, setRelationList] = useState([]);
  const classes = useStyle();
  const [directionsList, setDirectionsList] = useState([]);

  React.useEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request('/api/relations', requestOptions)
      .then((response) => response.json())
      .then((relations) => setRelationList(relations))
      .catch((err) => console.log(err));
  }, []);

  React.useEffect(() => {
    request('/relation_directions')
      .then((res) => {
        setDirectionsList(res.data?.list || []);
      })
      .catch((error) => console.log(error));
  }, []);

  const convertDate = (data) => {
    if (data)
      return `${data.slice(-2)}.${data.slice(-5, -3)}.${data.slice(-10, -6)}`;
    else return 'no info';
  };

  const handleSourceRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setSourceGraveToTargetRelation({});
      setTargetGraveToSourceRelation({});
    } else if (reason === 'select-option') {
      setSourceGraveToTargetRelation(value);
    }
  };

  const handleTargetRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setTargetGraveToSourceRelation({});
    } else if (reason === 'select-option') {
      setTargetGraveToSourceRelation(value);
    }
  };

  const relationSet = () => {
    return (
      !isEmpty(sourceGraveToTargetRelation) &&
      !isEmpty(targetGraveToSourceRelation)
    );
  };

  const handleSave = async () => {
    await request('/link_graves', buildRequest(), 'post')
      .then((res) => {
        console.log(res.data);
        props.handleCloseGraveConnectionDialog();
        dispatch(setLinkedGravesRedux([res.data, ...grave.linked_graves]));
        showUserNotification('Gravlenke er opprettet', 'success');
      })
      .catch((error) => {
        showUserNotification(
          'Could not send connection request. Please try again later',
          'error'
        );
      });
  };

  const buildRequest = () => {
    const formdata = new FormData();
    formdata.append('source_grave_id', sourceGrave.id);
    formdata.append('target_grave_id', targetGrave.id);
    formdata.append('relation_id', targetGraveToSourceRelation.id);
    formdata.append('reciprocal_relation_id', sourceGraveToTargetRelation.id);
    return formdata;
  };

  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = [];

    directionsList.map((item) => {
      if (item.from_relation.id === selectedRelation.id) {
        filteredList.push(item.to_relation);
      }

      return false;
    });

    if (filteredList.length === 0) {
      return [selectedRelation];
    } else {
      return filteredList;
    }
  };

  return (
    <>
      <div className='step-2-content-wrapper'>
        <div className='grave-connections-item'>
          {/* <div className='set-relation-and-claim-modal_display_image_wrapper'> */}
          <div className='grave-avatar_listing '>
            {sourceGrave.display_picture && (
              <img
                loading='lazy'
                src={sourceGrave.display_picture}
                alt='grave_avatar'
                className='grave_avatar'
              />
            )}
          </div>
          <span className='grave-content-name_span'>
            {sourceGrave.name.toUpperCase()}
          </span>
          <div className='grave-info-dates'>
            <span>
              <img loading='lazy' src={BirthIcon} alt='birth' />
              {'   '}
              {convertDate(sourceGrave.date_of_birth)}
            </span>
            <span>
              <img loading='lazy' src={DeathIcon} alt='DeathIcon' />
              {'   '}
              {convertDate(sourceGrave.date_of_death)}
            </span>
          </div>
          <div className='choose-relation-item'>
            <Autocomplete
              id='relation-combo-box'
              options={relationList}
              classes={{ input: classes.input }}
              getOptionLabel={(option) => option.name}
              style={{ width: '99%' }}
              renderInput={(params) => (
                <TextField placeholder='Set Relation' {...params} />
              )}
              onChange={handleSourceRelation}
            />
          </div>
        </div>

        <div className='directions'>
          <div className='direction-span-wrapper'>
            {!isEmpty(sourceGraveToTargetRelation) && (
              <span>{sourceGraveToTargetRelation.name}</span>
            )}
          </div>
          <div className='left-to-right'>
            <img src={EmptyOrangeDot} alt='' />
            <img src={HorizontalLine} alt='' />
            <img src={OrangeDot} alt='' />
          </div>
          <div className='right-to-left'>
            <img src={OrangeDot} alt='' />
            <img src={HorizontalLine} alt='' />
            <img src={EmptyOrangeDot} alt='' />
          </div>
          <div className='direction-span-wrapper'>
            {!isEmpty(targetGraveToSourceRelation) && (
              <span>{targetGraveToSourceRelation.name}</span>
            )}
          </div>
        </div>

        <div className='grave-connections-item'>
          {/* <div className='set-relation-and-claim-modal_display_image_wrapper'> */}
          <div className='grave-avatar_listing '>
            {targetGrave.display_picture && (
              <img
                loading='lazy'
                src={targetGrave.display_picture}
                alt='grave_avatar'
                className='grave_avatar'
              />
            )}
          </div>
          <span className='grave-content-name_span'>
            {targetGrave.name.toUpperCase()}
          </span>
          <div className='grave-info-dates'>
            <span>
              <img loading='lazy' src={BirthIcon} alt='birth' />{' '}
              {convertDate(targetGrave.date_of_birth)}
            </span>
            <span>
              <img loading='lazy' src={DeathIcon} alt='DeathIcon' />{' '}
              {convertDate(targetGrave.date_of_death)}
            </span>
          </div>
          <div className='choose-relation-item'>
            <Autocomplete
              id='relation-combo-box'
              options={filterDirectionList(sourceGraveToTargetRelation)}
              classes={{ input: classes.input }}
              getOptionLabel={(option) => option.name}
              style={{ width: '99%' }}
              value={targetGraveToSourceRelation}
              disabled={isEmpty(sourceGraveToTargetRelation)}
              renderInput={(params) => (
                <TextField placeholder='Velg relasjon' {...params} />
              )}
              onChange={handleTargetRelation}
            />
          </div>
        </div>
      </div>

      <div className='helper-span-second-step'>
        <span>
          {relationSet() &&
            `${sourceGrave.name} er ${sourceGraveToTargetRelation.name} til ${targetGrave.name} og ${targetGrave.name} er ${targetGraveToSourceRelation.name} til ${sourceGrave.name}`}
        </span>
      </div>

      <div className='save-grave-connection'>
        <button disabled={!relationSet()} onClick={() => handleSave()}>
          Lagre
        </button>
      </div>
    </>
  );
};

export default SecondStep;
