import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import styles from './AccountConfirmation.module.css';
import Messages from './Messages';

const Success = ({ url, redirectKey }) => {
  return (
    <div className={styles['container']}>
      <CheckIcon className={styles['icon']} />
      <Messages
        headingKey='account_verification_heading'
        descriptionKey='account_verification_description'
        url={url}
        redirectKey={redirectKey}
      />
    </div>
  );
};

export default Success;
