import React from 'react';
import { useHistory } from 'react-router-dom';
import IframeComponent from '../../../components/Iframe/IframeComponent';
import styles from './Contact.module.css';
import { ProductKeys } from '../../../service/constants';
import { FormattedMessage } from 'react-intl';

const Contact = ({ currentSubscription }) => {
  const history = useHistory();
  return (
    <React.Fragment>
      {currentSubscription.key === ProductKeys.conscious_plus ? (
        <div className={styles['contact']}>
          <span className={styles['header']}>
            <FormattedMessage id='contact_person' />
          </span>
          <IframeComponent
            title='Contact'
            source='https://www.wix.nettgrav.no/contact-8'
          />
        </div>
      ) : (
        history.push('/settings')
      )}
    </React.Fragment>
  );
};

export default Contact;
