import React, { useState, useEffect } from 'react';
import styles from './CardPaymentMethod.module.css';
import PaymentMethods from '../PaymentMethods/PaymentMethods';
import FreePlanContainer from '../FreePlanContainer/FreePlanContainer';
import AddNewCard from '../AddNewCard/AddNewCard';
import CardPayment from '../CardPayment/CardPayment';
import BillingInformation from '../BillingInformation/BillingInformation';
import SelectedPlan from '../SelectedPlan/SelectedPlan';
import { useSelector, useDispatch } from 'react-redux';
import {
  setInformationAboutUser,
  setPaymentMethods,
  updateUserSubscription,
  setSelectedPlan,
  setReturnPage,
  setPaymentMethod,
  setShowCardMethod,
  setSelectedCardMethod,
  setBillingInformation,
  setVippsPaymentMethods,
  setSelectedVippsMethod,
  setShowVippsPaymentMethod,
  setVippsPhoneNumber,
  setSubscriptionPeriod,
  setSubscriptionCount,
} from '../../../../redux/actions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  CONSTANTS,
  ProductKeys,
  PaymentType,
} from '../../../../service/constants';
import { request } from '../../../../service/request';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import ProcessModal from '../ProcessModal';
import ErrorModal from '../ErrorModal/ErrorModal';
import { getMessageKey } from '../../../../service/constants';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { useIntl } from 'react-intl';
import { updateSubscription } from '../../../../service/updateSubscription';

const appearance = {
  theme: 'night',
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, appearance);

const CardPaymentMethod = ({
  discountsList,
  subscriptionPeriods,
  isFreePlan,
  defaultPlan,
  currentSubscriptionPlan,
  plans,
  currentSubscription,
  selectedPlan,
  selectedPaymentMethod,
  countries,
  isCardValue,
  handelCardInputs,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const [submit, setSubmit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [user, setUser] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [disable, setDisable] = useState(true);
  const subscriptionPeriod = useSelector(
    (state) => state.paymentMethods.subscriptionPeriod
  );

  const paymentMethods = useSelector(
    (state) => state.paymentMethods.paymentMethods || null
  );
  const openPaymentMethod = useSelector(
    (state) => state.paymentMethods.selectedMethod
  );
  const billingInformation = useSelector(
    (state) => state.paymentMethods.billingInformation
  );
  const subscriptionCount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalCount
  );
  const amount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalPrice
  );
  const showCard = useSelector((state) => state.paymentMethods.showCard);
  const returnPage = useSelector((state) => state.returnPage);
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  const handleShowPayment = (method) => {
    dispatch(setPaymentMethod(method));
  };
  const handleShowCard = (value) => {
    dispatch(setShowCardMethod(value));
  };

  const handelSelectPaymentMethod = (method) => {
    if (showCard && method.card_type === 'visa') {
      handleShowPayment({});
    } else {
      handleShowPayment(method);
    }
    if (method.payment_type === PaymentType.card) handleShowCard(false);
  };
  const handleShowModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleProductChosen = (plan) => {
    if (plan.key === ProductKeys.free) {
      history.push(`/pricing/payment`);
    } else {
      history.push(
        `/pricing/payment?payment_method_type=${selectedPaymentMethod}`
      );
    }
    dispatch(setSelectedPlan(plan));
  };

  useEffect(() => {
    if (
      !isEmpty(currentSubscriptionPlan) &&
      !isEmpty(selectedPlan) &&
      !isEmpty(openPaymentMethod)
    ) {
      setDisable(false);
    } else if (!isEmpty(selectedPlan) && !isEmpty(openPaymentMethod)) {
      setDisable(false);
    } else if (
      !isEmpty(currentSubscriptionPlan) &&
      !isEmpty(openPaymentMethod)
    ) {
      setDisable(false);
    } else if (selectedPlan.key !== ProductKeys.free) {
      setDisable(true);
    }
  }, [openPaymentMethod]);

  useEffect(() => {
    if (!isEmpty(selectedPlan)) {
      if (!isEmpty(currentSubscriptionPlan)) {
        const isFreePlan = selectedPlan.key === ProductKeys.free;
        if (isFreePlan) {
          setDisable(false);
        } else {
          const areAllValuesFilled = Object.values(billingInformation).every(
            (value) => value !== ''
          );
          const newSubscription = isCardValue && areAllValuesFilled;
          setDisable(
            newSubscription || !isEmpty(openPaymentMethod) ? false : true
          );
        }
      } else if (selectedPlan.key === ProductKeys.free) {
        setDisable(false);
      } else if (!isEmpty(openPaymentMethod)) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else if (!isEmpty(currentSubscriptionPlan)) {
      const isFreePlan = currentSubscriptionPlan.key === ProductKeys.free;
      setDisable(currentSubscriptionPlan.id ? true : !isFreePlan);
    } else {
      setDisable(false);
    }
  }, [selectedPlan, currentSubscriptionPlan, plans]);

  // -------Fetch user data -------- //

  useEffect(() => {
    if (selectedPaymentMethod === 'card' && countries.length > 0) {
      request(`/users/${userSlug}`)
        .then((res) => {
          const currentUser = res.data.user;
          setUser(currentUser);
          dispatch(setInformationAboutUser(res.data));
          const userPhoneNumber = `${currentUser.country_code}${currentUser.phone_number}`;
          dispatch(
            setBillingInformation({
              ...billingInformation,
              fullName: currentUser?.full_name ? currentUser.full_name : '',
              billingAddress: currentUser?.address ? currentUser.address : '',
              email: currentUser?.email ? currentUser.email : '',
              country_id: currentUser.country_id
                ? currentUser.country_id
                : countries[0].id,
              postalCode: '',
              phoneNumber: userPhoneNumber ? userPhoneNumber : '',
            })
          );
        })
        .catch((err) => console.log(err));
    }
  }, [selectedPaymentMethod, countries]);

  const handleServerResponse = (response) => {
    if (response.error) {
      handleCloseModal();
      setOpenErrorModal(true);
    } else {
      let url = returnPage.url || '/settings/subscriptions';
      if (subscriptionCount > 1) {
        url = returnPage.url || '/settings/subscription-invitations';
      }
      let messageKey = getMessageKey(currentSubscriptionPlan, selectedPlan);
      if (url) {
        dispatch(setShowCardMethod(false));
        dispatch(setShowVippsPaymentMethod(false));
        dispatch(setBillingInformation({}));
        dispatch(setPaymentMethod({}));
        dispatch(setSelectedVippsMethod({}));
        dispatch(setVippsPaymentMethods([]));
        dispatch(setPaymentMethods([]));
        dispatch(setSelectedCardMethod('vipps'));
        dispatch(setReturnPage(null));
        dispatch(setVippsPhoneNumber(''));
        dispatch(setSubscriptionPeriod({}));
        dispatch(setSubscriptionCount(1));
      }
      history.push(url, { show: 1, key: messageKey });
    }
  };

  const handleUpdateSubscription = async () => {
    const url = `${CONSTANTS.baseUrl}/api/subscriptions/${currentSubscription.id}.json`;
    const token = localStorage.getItem('token');
    const params = {
      payment_method_id: openPaymentMethod?.id,
      user_count: parseInt(subscriptionCount),
      product_id: selectedPlan.id,
      period: subscriptionPeriod.key,
    };

    const result = await updateSubscription(url, token, params);

    if (result.success) {
      dispatch(updateUserSubscription(true));
      handleServerResponse(result.data);
    } else {
      showUserNotification(result.error, 'error');
      handleCloseModal();
      setErrorMessage(result.error);
      setOpenErrorModal(true);
    }
  };

  const handleSubscribe = () => {
    if (isEmpty(selectedPlan)) {
      showUserNotification(intl.formatMessage({ id: 'choose_plan' }), 'error');
      return;
    }
    const areAllValuesFilled = Object.values(billingInformation).every(
      (value) => value !== ''
    );
    const allValuesFilled = areAllValuesFilled && isCardValue;
    if (allValuesFilled && showCard) {
      handleShowModal();
      setSubmit(true);
      return;
    }
    if (!isEmpty(openPaymentMethod)) {
      handleShowModal();
      handleUpdateSubscription();
      return;
    }
    if (!allValuesFilled && isEmpty(openPaymentMethod) && !showCard) {
      showUserNotification(
        intl.formatMessage({ id: 'continue_new_card:' }),
        'error'
      );
    } else if (!allValuesFilled && showCard) {
      showUserNotification(
        intl.formatMessage({ id: 'filled_out_info' }),
        'error'
      );
    }
  };

  return (
    <div className={styles['payment-page-container']}>
      {defaultPlan && (
        <div className={styles['payment-container']}>
          {isFreePlan && defaultPlan && <FreePlanContainer />}
          {paymentMethods &&
            !isFreePlan &&
            paymentMethods.map((method) => (
              <PaymentMethods
                method={method}
                showPayment={
                  showCard ? false : method.id === openPaymentMethod.id
                }
                handelSelectPaymentMethod={handelSelectPaymentMethod}
                key={method.id}
              />
            ))}
          {!showCard && !isFreePlan && paymentMethods.length > 0 && (
            <AddNewCard
              handleShowCard={handleShowCard}
              handleShowPayment={handleShowPayment}
              showCard={showCard}
            />
          )}
          {showCard && !isFreePlan && (
            <div className={styles['payment-details']}>
              <div className={styles['card-information']}>
                <Elements stripe={stripePromise}>
                  {defaultPlan ? (
                    <CardPayment
                      chosenPlan={defaultPlan}
                      submit={submit}
                      setSubmit={setSubmit}
                      billingInformation={billingInformation}
                      handleCloseModal={handleCloseModal}
                      handelCardInputs={handelCardInputs}
                      currentSubscriptionPlan={currentSubscriptionPlan}
                      selectedPlan={selectedPlan}
                    />
                  ) : null}
                </Elements>
              </div>
              {!isEmpty(billingInformation) && (
                <BillingInformation
                  handleDisable={setDisable}
                  billingInformation={billingInformation}
                  isCardValue={isCardValue}
                  countries={countries}
                />
              )}
            </div>
          )}
        </div>
      )}
      <div className={styles['selected-plan']}>
        <React.Fragment>
          <div className={styles['selected-plan-details']}>
            <SelectedPlan
              discountsList={discountsList}
              subscriptionPeriods={subscriptionPeriods}
              plans={plans}
              chosenPlan={defaultPlan}
              handleProductChosen={handleProductChosen}
              disable={disable}
              handleSubscribe={handleSubscribe}
              isFreePlan={isFreePlan}
              isCurrentPlan={selectedPlan.key === currentSubscriptionPlan.key}
              subscriptionPeriod={subscriptionPeriod}
            />
          </div>
        </React.Fragment>
      </div>
      {openModal && (
        <ProcessModal open={openModal} messageKey={'payment_progress'} />
      )}
      {openErrorModal && (
        <ErrorModal
          message={errorMessage}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
        />
      )}
    </div>
  );
};

export default CardPaymentMethod;
