import React, { useState } from 'react';
import styles from './GiftPlans.module.css';
import { Avatar } from '@material-ui/core';
import FamilyIcon from '../../../../img/FamilyIcon.svg';
import Member from './Member/Member';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const GiftPlans = ({
  family,
  open,
  handelMemberOpen,
  index,
  relationList,
  subscriptionMembers,
  updateSubscriptionMembers,
}) => {
  const membersLength = family.subscription_members.length;
  const history = useHistory();

  return (
    <div className={styles['family-container']}>
      <div className={styles['family-wrapper']}>
        <div className={styles['family-header']}>
          <img src={FamilyIcon} alt='Family' />
        </div>
        <div className={styles['owner-container']}>
          <div className={styles['family-heading-wrapper']}>
            <div className={styles['family-name']}>
              <FormattedMessage
                id='settings_memberName'
                values={{ name: family?.owner_name ? family.owner_name : '' }}
              />
            </div>
            <div className={styles['family-member']}>
              <FormattedMessage
                id='settings_memberLength'
                values={{ length: membersLength }}
              />
            </div>
            {/* <div className={styles['relation']}>Du er en barnebarn her</div> */}
          </div>
          <div className={styles['owner-details']}>
            <Avatar src={family.avatar} style={{ height: 53, width: 53 }} />
            <div className={styles['owner-wrapper']}>
              <div className={styles['owner-name']}>{family?.owner_name}</div>
              <div className={styles['grave']}>
                <FormattedMessage id='settings_administrator' />
              </div>
            </div>
          </div>
          {family.plan_name !== 'Fri' && membersLength > 0 && (
            <button
              className={styles['members-btn']}
              onClick={() => handelMemberOpen(index)}
            >
              <FormattedMessage id='settings_showMembers' />
            </button>
          )}
          {family.plan_name === 'Fri' && membersLength === 0 && (
            <button
              onClick={() => history.push('/pricing')}
              className={styles['upGrade-button']}
            >
              <FormattedMessage id='settings_upgrade' />
            </button>
          )}
        </div>
      </div>
      {family.subscription_members.length > 0 && open && (
        <>
          <div className={styles['family-member-heading']}>
            <FormattedMessage id='settings_familyMembers' />
          </div>
          <div className={styles['subscription-members']}>
            {family.subscription_members.map((member, index) => {
              return (
                <Member
                  member={member}
                  familyOwner={family}
                  key={index}
                  relationList={relationList}
                  subscriptionMembers={subscriptionMembers}
                  updateSubscriptionMembers={updateSubscriptionMembers}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default GiftPlans;
