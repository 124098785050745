import React from 'react';
import { Modal, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import closeIcon from '../../../../img/closeIcon.svg';
import './Modal.css';
import { FormattedMessage } from 'react-intl';
import { planKey } from '../../../../service/PlanKey';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    width: '700px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'maxContent',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '4px',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const PopupModal = ({ open, handleClose, plan, messageKey }) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <button className='close-btn' onClick={() => handleClose()}>
          <img src={closeIcon} alt='close' />
        </button>
        <div className='modal-heading'>
          <FormattedMessage id={planKey(plan)} />{' '}
          <FormattedMessage id='settings_enable' />
        </div>
        <div className='details'>
          <FormattedMessage id={messageKey} />{' '}
          <FormattedMessage id={planKey(plan)} />{' '}
          <FormattedMessage id='settings_membershipOn' />{' '}
          <FormattedMessage id='settings_your' />{' '}
          <Link to='/settings/plan-members' style={{ color: '#3CA0FF' }}>
            <FormattedMessage id='settings_memberPage' />
          </Link>
        </div>
        <div className='details'>
          <FormattedMessage id='settings_modalSubheading' />{' '}
          <Link to='#' style={{ color: '#3CA0FF' }}>
            <FormattedMessage id='settings_modalLink' />
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default PopupModal;
