import React, { useState } from 'react';
import styles from './VirtueModal.module.css';
import X from '../../../../img/X.svg';
import FamilySilhouette from '../../../../img/FamilySilhouette.svg';
import OnlyMe from '../../../../img/OnlyMe.svg';
import Everyone from '../../../../img/Everyone.svg';
import { Button, makeStyles, Select, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { request } from '../../../../service/request';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';

const useStyle = makeStyles(() => ({
  rootSuggestion: {
    position: 'relative',
    '& button': {
      borderRadius: '10px',
      backgroundColor: '#fff',
      border: 'none',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161)',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '19px',
      color: '#36434B',
      textTransform: 'none',
      minWidth: '119px',
      height: '39px',
      marginRight: '20px',
      marginBottom: '5px',
    },
    '&:last-child': {
      marginRight: '0px',
    },
  },
  rootSuggestionClicked: {
    position: 'relative',
    '& button': {
      borderRadius: '10px',
      backgroundColor: '#fff',
      border: '1px solid #FEA65A',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161)',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '19px',
      color: '#FEA65A',
      textTransform: 'none',
      minWidth: '119px',
      height: '39px',
      marginRight: '20px',
      marginBottom: '5px',
    },
    '&:last-child': {
      marginRight: '0px',
    },
  },
  rootSuggestionNew: {
    borderRadius: '10px',
    backgroundColor: '#f5f5f5',
    border: 'none',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161)',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#36434B',
    textTransform: 'none',
    minWidth: '119px',
    height: '39px',
    marginRight: '20px',
    marginBottom: '5px',
    '&:last-child': {
      marginRight: '0px',
    },
  },
  rootAddNewSuggestion: {
    border: 'none',
  },
  rootAddButton: {
    width: '180px',
    height: '55px',
    backgroundColor: '#FEA65A',
    borderRadius: '10px',
    color: '#fff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FEA65A',
    },
  },
  rootSaveNewSuggestion: {
    width: '99px',
    height: '41px',
    backgroundColor: '#FEA65A',
    borderRadius: '10px',
    color: '#fff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    textTransform: 'none',
    boxShadow: 'none',
    position: 'absolute',
    '&:hover': {
      backgroundColor: '#FEA65A',
    },
  },
}));

const VirtueModal = (props) => {
  const classes = useStyle();
  const [mainListOfSuggestions, setMainListOfSuggestions] = useState(
    props.suggestions?.length > 0 ? props.suggestions : []
  );
  const [newSuggestions, setNewSuggestions] = useState([]);
  const [chosenSuggestion, setChosenSuggestion] = useState(null);
  const [story, setStory] = useState('');
  const graveName = useSelector((state) => state.grave.grave.name);
  const graveId = useSelector((state) => state.grave.grave.id);

  const [privacy, setPrivacy] = useState('relatives');
  const [disabled, setDisabled] = useState(false);

  // get new suggestions
  React.useEffect(() => {
    const refreshNewSuggestions = async () => {
      try {
        const response = await request('/personalities');
        const suggestionsId = props.suggestions.map(
          (suggest) => suggest.personality_id
        );
        const newArrayOfNewSuggestions = response.data.personalities
          .map((suggest) => {
            if (!suggestionsId.includes(suggest.id)) {
              return suggest;
            } else return null;
          })
          .filter((suggest) => suggest);
        setNewSuggestions(newArrayOfNewSuggestions);
      } catch (err) {
        console.log(err);
      }
    };
    refreshNewSuggestions();
  }, []);

  const choseOrDeleteSuggestion = (suggestion) => {
    setChosenSuggestion(suggestion);
    setStory(suggestion.text);
  };

  const AddSuggestionToMainList = (suggestion) => {
    setMainListOfSuggestions((prev) => [
      ...prev,
      { ...suggestion, isNew: true },
    ]);
    const newArray = newSuggestions.filter(
      (suggest) => suggest.id !== suggestion.id
    );
    setChosenSuggestion(suggestion);
    setNewSuggestions(newArray);
  };

  const addSuggestionToDeceasedPage = async () => {
    chosenSuggestion === null &&
      showUserNotification('Velg ett personlighetstrekk', 'warning');
    story === '' &&
      showUserNotification(
        'Husk å legge til en kort historie som viser til hvorfor',
        'warning'
      );
    try {
      if (chosenSuggestion && story !== '') {
        const formdata = new FormData();
        formdata.append('grave_personality[grave_id]', graveId);
        formdata.append('grave_personality[comment]', story);
        formdata.append(
          'grave_personality[personality_id]',
          chosenSuggestion.personality_id || chosenSuggestion.id
        );
        formdata.append(
          'grave_personality[privacy_attributes][setting]',
          privacy
        );
        await request('/grave_personalities', formdata, 'post');
        await props.refreshVirtueInformation();
        props.closeModal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteSelectedSugg = () => {
    const recovered = mainListOfSuggestions[mainListOfSuggestions.length - 1];
    setNewSuggestions((prev) => [...prev, { ...recovered, isNew: false }]);
    setMainListOfSuggestions(mainListOfSuggestions.slice(0, -1));
    setDisabled(false);
  };

  const deleteDuplicate = (array, key) => {
    return JSON.parse(
      JSON.stringify([...new Map(array.map((x) => [key(x), x])).values()])
    );
  };

  return (
    <div className={styles['virtue-modal']}>
      <div className={styles['virtue-modal_content']}>
        <div className={styles['virtue-modal_content-header']}>
          <span>Verdiarkivet</span>
          <img loading='lazy' src={X} alt='X' onClick={props.closeModal} />
        </div>
        {mainListOfSuggestions.length > 0 && (
          <div className={styles['virtue-modal_content-subheader']}>
            <span>Verdiarkivet</span>
          </div>
        )}
        <div className={styles['virtue-modal_content-suggestions-list']}>
          {deleteDuplicate(mainListOfSuggestions, (it) => it.personality).map(
            (suggestion) => (
              <div
                key={suggestion.id}
                className={
                  chosenSuggestion
                    ? chosenSuggestion.id === suggestion.id
                      ? classes.rootSuggestionClicked
                      : classes.rootSuggestion
                    : classes.rootSuggestion
                }
              >
                {suggestion.isNew && (
                  <img
                    src={X}
                    alt='close icon'
                    onClick={handleDeleteSelectedSugg}
                  />
                )}
                {/* {suggestion.isNew && setDisabled(prev => !prev)} */}
                <Button
                  key={suggestion.id}
                  variant='outlined'
                  onClick={() => choseOrDeleteSuggestion(suggestion)}
                >
                  {suggestion.personality
                    ? suggestion.personality
                    : suggestion.title}
                </Button>
              </div>
            )
          )}
        </div>
        <div className={styles['virtue-modal_content-text']}>
          <span>Velg en verdi og legg til historie</span>
        </div>
        <div className={styles['virtue-modal_content-add-suggestions-list']}>
          {newSuggestions.map((suggestion) => (
            <Button
              disabled={disabled}
              key={suggestion.id}
              variant='outlined'
              className={classes.rootSuggestionNew}
              onClick={() => {
                props.suggestions?.length + 1 > mainListOfSuggestions.length &&
                  AddSuggestionToMainList(suggestion);
                setDisabled(true);
              }}
            >
              {suggestion.title}
            </Button>
          ))}
        </div>

        {chosenSuggestion && (
          <div
            className={styles['virtue-modal_content-suggestion-description']}
          >
            <span>
              Du husker {graveName} som{' '}
              <span>
                en{' '}
                {chosenSuggestion.personality
                  ? chosenSuggestion.personality.toLowerCase()
                  : chosenSuggestion.title.toLowerCase()}
              </span>{' '}
              person. Skriv en liten historie som viser til hvorfor.
            </span>
          </div>
        )}
        <div className={styles['virtue-modal_content-add-story']}>
          <textarea
            placeholder='Skriv en kort historie her'
            rows={5}
            value={story}
            onChange={(e) => setStory(e.target.value)}
          />
        </div>
        <Select
          value={privacy}
          onChange={(e) => setPrivacy(e.target.value)}
          style={{ margin: '0 auto 0 0' }}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
        >
          <MenuItem value=' ' disabled={true}>
            {' '}
            Del med:
          </MenuItem>
          <MenuItem value='public'>
            <img
              loading='lazy'
              src={Everyone}
              alt='Family Silhouette'
              className={styles['memories-header_right-icon']}
            />
            Alle
          </MenuItem>
          <MenuItem value='relatives'>
            <img
              loading='lazy'
              src={FamilySilhouette}
              alt='Family Silhouette'
              className={styles['memories-header_right-icon']}
            />
            Familien
          </MenuItem>
          <MenuItem value='private'>
            <img
              loading='lazy'
              src={OnlyMe}
              alt='Family Silhouette'
              className={styles['memories-header_right-icon']}
              style={{ marginLeft: '3px' }}
            />
            Privat
          </MenuItem>
        </Select>
        <div className={styles['virtue-modal_content-add-button']}>
          <Button
            variant='contained'
            className={classes.rootAddButton}
            onClick={addSuggestionToDeceasedPage}
          >
            Del
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VirtueModal;
