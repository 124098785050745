import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CONSTANTS, ProductKeys } from '../../service/constants';
import SubscriptionPlanChosen from './atoms/SubscriptionPlanChosen';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentSubscriptionPlan,
  setSubscriptionCount,
  setSubscriptionTotalPrice,
  setCurrentSubscriptionData,
} from '../../redux/actions';
import './PlansPageRouting.css';
import { useHistory } from 'react-router-dom';

const PlansPageRouting = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentSubscription, setCurrentSubscription] = useState({});
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
  const isAuth = useSelector((state) => state.auth.isAuthorized);

  useEffect(() => {
    dispatch(setSubscriptionCount(1));
    dispatch(setSubscriptionTotalPrice(0));
  }, []);

  useEffect(() => {
    if (!isAuth) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/users/${userSlug}/subscription`, config)
      .then((res) => {
        if (res.data?.product) {
          dispatch(setCurrentSubscriptionPlan(res.data.product));
          dispatch(setCurrentSubscriptionData(res.data));
          setCurrentSubscription(res.data);
          if (res.data.product?.key !== ProductKeys.free) {
            history.push('/settings/subscriptions');
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch]);

  return (
    <div className='plans-page_routing'>
      <SubscriptionPlanChosen currentSubscription={currentSubscription} />
    </div>
  );
};

export default PlansPageRouting;
