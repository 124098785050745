import React from 'react';
import styles from './EditButton.module.css';
import { FormattedMessage } from 'react-intl';

const EditButton = ({ title, onClick, style, icon }) => {
  return (
    <div
      className={styles['action-btn']}
      onClick={() => onClick()}
      style={style}
    >
      <img src={icon} alt='Edit' />
      <button type='button'>
        <FormattedMessage id={title} />
      </button>
    </div>
  );
};

export default EditButton;
