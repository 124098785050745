import React, { useState } from 'react';
import { Dialog, makeStyles, DialogContent } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import styles from './ClaimMessage.module.css';
import SetRelationOrClaimConfirmationDialog from '../RelationOrClaimModals/SetRelationOrClaimConfirmationDialog';
import SetRelationOrClaimDialog from '../RelationOrClaimModals/SetRelationOrClaimDialog';
import { useSelector } from 'react-redux';

const useStyle = makeStyles(() => ({
  confirmationPaper: {
    maxWidth: 'unset !important',
  },
  rootRelationOrClaimConfirmation: {
    '& .MuiPaper-root': {
      background: 'transparent',
    },
    '& .MuiPaper-elevation24': {
      boxShadow: 'none',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'unset !important',
    },
  },
  rootConfirmationDialog: {
    '& .MuiPaper-root': {
      background: 'transparent',
    },
    '& .MuiPaper-elevation24': {
      boxShadow: 'none',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'unset !important',
    },
  },
}));

const ClaimMessage = (props) => {
  const history = useHistory();
  const classes = useStyle();
  const [isClaiming, setIsClaiming] = useState(false);
  const [openRelationDialog, setOpenRelationDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfimationDialog] = useState(false);
  const user = useSelector((state) => state.user) || {};

  const [isUserSubscribed, setIsUserSubscribed] = useState(
    user.user.is_subscribed || false
  );

  const routeChange = () => {
    let path = '/pricing';
    history.push(path);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfimationDialog(false);
  };

  const handleOpenClaimDialog = () => {
    history.push(`/grave/${props.grave.slug}/claim`);
    // setIsClaiming(true);
    // setOpenRelationDialog(true);
  };

  const handleCloseRelationDialog = () => {
    setOpenRelationDialog(false);
  };

  const handleGraveUpdate = () => {
    setOpenConfimationDialog(false);
  };

  return (
    <div className={styles['claim-message-container']}>
      <Dialog
        open={openRelationDialog}
        onClose={handleCloseRelationDialog}
        className='cropper-modal_modal'
        scroll='body'
        classes={{ paper: classes.confirmationPaper }}
      >
        <SetRelationOrClaimDialog
          grave={props.grave}
          canClaim={props.grave.can_claim}
          openConfirmationDialog={setOpenConfimationDialog}
          isClaiming={isClaiming}
          handleCloseRelationDialog={handleCloseRelationDialog}
          classes={{ paper: classes.confirmationPaper }}
        />
      </Dialog>

      <Dialog
        open={openConfirmationDialog}
        disableBackdropClick={true}
        className={classes.rootRelationOrClaimConfirmation}
        onClose={handleCloseConfirmationDialog}
      >
        <DialogContent style={{ overflow: 'hidden' }}>
          <SetRelationOrClaimConfirmationDialog
            isClaiming={isClaiming}
            handleGraveUpdate={handleGraveUpdate}
          />
        </DialogContent>
      </Dialog>
      <div className={styles['claim-message']}>
        <span className={styles['message']}>
          Graven er uten 'Vokter av livet'. Ta styring for å bekrefte eierskap
          og administrere graven.
          <span
            className={styles['claim-link']}
            onClick={handleOpenClaimDialog}
          >
            {' '}
            Ta styring her
          </span>
        </span>
      </div>
    </div>
  );
};

export default ClaimMessage;
