import React, { useState } from 'react';
import styles from './PlanPaymentDetails.module.css';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MenuItem, makeStyles } from '@material-ui/core';
import Popover from '@mui/material/Popover';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash.isempty';

const useStyles = makeStyles((theme) => ({
  children: {
    whiteSpace: 'normal',
    backgroundColor: '#fff!important',
    color: '#36434B',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '400',
    padding: '10px',
    minWidth: '200px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)!important',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
    marginRight: '10px',
  },
  listItemText: {
    fontSize: '1em !important',
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const PlanLength = ({ subscriptionPeriods, onSelect, selectedPeriod }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (discount) => {
    onSelect(discount);
    handleClose();
  };

  return (
    <div className={styles['discount-section']}>
      <div className={styles['tax-heading']}>
        <FormattedMessage id='discount_title' />
      </div>
      <div className={styles['discount-container']}>
        <button
          className={styles['list-button']}
          onClick={(event) => handleClick(event)}
        >
          <span className={styles['discount-month']}>
            <FormattedMessage
              id={!isEmpty(selectedPeriod) ? selectedPeriod.key : 'annually'}
            />
          </span>
          {open ? (
            <ArrowDropUpIcon style={{ color: '#404d56' }} />
          ) : (
            <ArrowDropDownIcon style={{ color: '#404d56' }} />
          )}
        </button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {subscriptionPeriods.length > 0 &&
            subscriptionPeriods.map((period, index) => {
              return (
                <MenuItem
                  key={index}
                  className={classes.children}
                  onClick={() => handleMenuItemClick(period)}
                >
                  <FormattedMessage id={period.key} />
                </MenuItem>
              );
            })}
        </Popover>
      </div>
    </div>
  );
};

export default PlanLength;
