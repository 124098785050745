import React, { useEffect, useState } from 'react';
import BoyAvatar from '../../../../img/BoyAvatar.svg';
import GirlAvatar from '../../../../img/GirlsAvatar.svg';
import styles from './ConnectedGraves.module.css';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Header from './components/Header';
import ActionButton from './components/ActionButton';
import BlackPulsIcon from '../../../../img/BlackPulsIcon.svg';
import itemStyles from '../About/components/AboutItem.module.css';
import PrimaryButton from '../../../../components/PrimaryButton';

const ConnectedGraves = ({ item, onEdit, title, emptyMessage, canEdit }) => {
  const history = useHistory();
  const [avatar, setAvatar] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [hideEdit, setHideEdit] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1020) {
        setIsExpanded(true);
        setHideEdit(false);
      } else {
        setHideEdit(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isExpanded, hideEdit]);

  useEffect(() => {
    if (item.gender === 'mann') {
      setAvatar(BoyAvatar);
    } else if (item.gender === 'Kvinne') {
      setAvatar(GirlAvatar);
    } else {
      setAvatar(BoyAvatar);
    }
  }, [item]);

  return (
    <div div className={itemStyles['container-wrapper']}>
      <Header
        title={title}
        hideEdit={hideEdit}
        setIsExpanded={setIsExpanded}
        isExpanded={isExpanded}
        children={
          canEdit && (
            <ActionButton
              onEdit={onEdit}
              title='Knytt gravlagt'
              icon={BlackPulsIcon}
            />
          )
        }
      />
      {isExpanded && (
        <React.Fragment>
          {hideEdit && canEdit && (
            <div className={itemStyles['edit-btn']}>
              <ActionButton
                onEdit={onEdit}
                title='Knytt gravlagt'
                icon={BlackPulsIcon}
              />
            </div>
          )}
          {!isEmpty(item) && item.length > 0 ? (
            <div className={styles['connect-grave-container']}>
              {item.map((item, index) => {
                return (
                  <div
                    className={styles['connected-grave']}
                    key={index}
                    onClick={() => history.push(`/deceased/${item.slug}`)}
                  >
                    <LazyLoadImage
                      src={item.avatar ? item.avatar : avatar}
                      alt='grave_Avatar'
                      className={styles['grave-image']}
                    />
                    <div className={styles['grave-name']}>{item.name}</div>
                    {item.date_of_birth && item.date_of_death && (
                      <div className={styles['age-years']}>
                        {`${item.date_of_birth.substring(
                          0,
                          4
                        )} - ${item.date_of_death.substring(0, 4)}`}
                      </div>
                    )}
                    <div className={styles['relation']}>{item.relation}</div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={styles['empty-grave-info']}>
              <span className={styles['empty-message']}>{emptyMessage}</span>
              {canEdit && (
                <button className={styles['add-btn']} onClick={onEdit}>
                  Add {title}
                </button>
              )}
            </div>
          )}
          <div className={itemStyles['set-relation-btn']}>
            <PrimaryButton onClick={onEdit} children={'Sett relasjon'} />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ConnectedGraves;
