import React from 'react';
import styles from './DeleteVirtue.module.css';
import DeleteImage from '../../../../img/DeleteImage.svg';
import { Button, makeStyles } from '@material-ui/core';
import { request } from '../../../../service/request';

const useStyle = makeStyles(() => ({
  rootRemove: {
    width: '50%',
    border: 'none',
    backgroundColor: '#fff',
    borderRadius: '0px',
    borderBottomLeftRadius: '10px',
    height: '50px',
    color: '#eb5757',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '@media (max-width: 750px)': {
      width: '170px',
      borderRadius: '10px',
    },
  },
  rootCancel: {
    width: '50%',
    border: 'none',
    backgroundColor: '#FEA65A',
    borderRadius: '0px',
    borderBottomRightRadius: '10px',
    height: '50px',
    color: '#fff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#FEA65A',
    },
    '@media (max-width: 750px)': {
      width: '170px',
      borderRadius: '10px',
    },
  },
}));

const DeleteVirtue = (props) => {
  const classes = useStyle();
  const deleteSuggestion = () => {
    const id = props.chosenSuggestion?.id;
    request(`/grave_personalities/${id}`, null, 'delete')
      .then(() => {
        props.delete(id);
        props.closeModal();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={styles['delete-virtue']}>
      <div className={styles['delete-virtue_content']}>
        <div className={styles['delete-virtue_content-header']}>
          <span>Slett fra verdiarkivet</span>
        </div>
        <div className={styles['delete-virtue_content-main']}>
          <img loading='lazy' src={DeleteImage} alt='delete' />
          <span>
            Fjern{' '}
            <span style={{ textTransform: 'capitalize', color: '#36434B' }}>
              {props.graveName}
            </span>{' '}
            verdi <span> {props.chosenSuggestion?.personality}</span> fra
            graven?
          </span>
        </div>
      </div>
      <div className={styles['delete-virtue_buttons']}>
        <Button
          variant='outlined'
          className={classes.rootRemove}
          onClick={deleteSuggestion}
        >
          Fjern
        </Button>
        <Button
          variant='contained'
          className={classes.rootCancel}
          onClick={props.closeModal}
        >
          Avbryt
        </Button>
      </div>
    </div>
  );
};

export default DeleteVirtue;
