const define_env = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'staging':
      return 'https://stage-api.nettgrav.no';
    case 'production':
      return 'https://api.nettgrav.com';
    case 'local':
      return 'http://localhost:3001';
    default:
      return 'https://stage-api.nettgrav.no';
  }
};

const setActionCableEndpoints = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'staging':
      return 'wss://stage-api.nettgrav.no/cable';
    case 'production':
      return 'wss://api.nettgrav.com/cable';
    case 'local':
      return 'ws://localhost:3001/cable';
    default:
      return 'ws://localhost:3001/cable';
  }
};
const CONSTANTS = {
  grave: {
    claimed: 'claimed',
    rejected: 'rejected',
  },
  actionCable: setActionCableEndpoints(),
  baseUrl: define_env(),
};

export { CONSTANTS };

export const SubscriptionStatuses = {
  PENDING_APPROVAL: 'pending_approval',
  PAYMENT_DUE: 'payment_due',
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  GRACE_PERIOD: 'grace_period',
};

export const ProductKeys = {
  free: 'free',
  conscious: 'conscious',
  conscious_plus: 'conscious-plus',
};

export const PaymentType = {
  vipps: 'vipps',
  card: 'card',
};

export const getMessageKey = (currentPlan, selectedPlan) => {
  switch (currentPlan.key) {
    case ProductKeys.free:
      return 'settings_subscribedPlan';
    case ProductKeys.conscious:
      if (selectedPlan.key === ProductKeys.conscious_plus) {
        return 'settings_updatePlan';
      }
      if (selectedPlan.key === currentPlan.key) {
        return 'settings_addedMember';
      }
      return 'settings_updatePlan';
    case ProductKeys.conscious_plus:
      if (selectedPlan.key === ProductKeys.conscious) {
        return 'settings_downgradePlan';
      }
      if (selectedPlan.key === currentPlan.key) {
        return 'settings_addedMember';
      }
      return 'settings_updatePlan';
    default:
      return 'settings_subscribedPlan';
  }
};

export const AboutMenuItems = {
  1: {
    title: 'Gravlagte',
  },
  2: {
    title: 'Relasjoner',
  },
  3: {
    title: 'Arbeid',
  },
  4: {
    title: 'Interesse',
  },
  5: {
    title: 'Lidenskap',
  },
  6: {
    title: 'Gravlenke',
  },
};

export const MemoryMenuItems = {
  1: {
    title: 'Gravbesøk og minner',
  },
  2: {
    title: 'Merkedager',
  },
};

export const ChangePlanKey = {
  change_plan: 'change_plan',
  change_members: 'manage_members',
  change: 'change',
};

export const LanguageKeys = {
  English: 'ENG',
  Norwegian: 'NOR',
};

export const languages = [
  {
    id: 1,
    key: 'ENG',
  },
  {
    id: 2,
    key: 'NOR',
  },
];

export const SubscriptionAlertsKeys = {
  paymentDue: 'paymentDue',
  active: 'active',
  pending: 'pending',
  stopped: 'stopped',
  expired: 'expired',
  cancelled: 'cancelled',
  activeStopped: 'activeStopped',
  activeExpired: 'activeExpired',
  notValid: 'notValid',
};

export const SubscriptionChangesKey = {
  period: 'period',
  user_count: 'user_count',
  product_id: 'product_id',
};

export const SubscriptionPeriodKey = {
  monthly: 'monthly',
  semi_annual: 'semi_annual',
  annually: 'annually',
  biennial: 'biennial',
};
