import React, { useState, useEffect } from 'react';
import Visa from '../../../img/Visa.svg';
import MaterCard from '../../../img/MasterCard.svg';
import Popover from '@material-ui/core/Popover';
import MoreHorizontal from '../../../img/MoreHorizontal.svg';
import { setPaymentMethods } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { CONSTANTS, PaymentType } from '../../../service/constants';
import vippsLogo from '../../../img/VippsLogo.svg';
import styles from './PaymentMethods.module.css';
import ConformModal from './ConformDeleteModal';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import axios from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';
import ProcessModal from '../../Payment/atoms/ProcessModal';

const BillingMethod = ({ paymentMethod }) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const paymentMethods = useSelector(
    (state) => state.paymentMethods.paymentMethods || null
  );
  const dispatch = useDispatch();
  const [conformModal, setConformModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  const [cardType, setCardType] = useState(null);

  const closeConformModal = () => {
    setConformModal(false);
  };

  useEffect(() => {
    if (paymentMethod.card_type === 'visa') {
      setCardType(Visa);
    } else if (paymentMethod.card_type === 'mastercard') {
      setCardType(MaterCard);
    } else if (paymentMethod.payment_type === PaymentType.vipps) {
      setCardType(vippsLogo);
    }
  }, [paymentMethod]);

  const deletePaymentMethods = async () => {
    if (!userSlug) return;
    setOpenModal(true);
    setConformModal(false);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .delete(
        `${CONSTANTS.baseUrl}/api/payment_methods/${paymentMethod.id}`,
        config
      )
      .then((res) => {
        handleClose();
        const updatedPaymentMethods = [...paymentMethods];
        const index = paymentMethods.findIndex(
          (method) => method.id === paymentMethod.id
        );
        updatedPaymentMethods.splice(index, 1);
        dispatch(setPaymentMethods(updatedPaymentMethods));
        showUserNotification(
          intl.formatMessage({ id: 'payment_method_deleted' }),
          'success'
        );
        setOpenModal(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenModal(false);
        showUserNotification(err.response.data.error || err.message, 'error');
        handleClose();
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles['membership-page-details_border']}>
      <div className={styles['membership-page-billing_method']}>
        <span>
          <FormattedMessage id='settings_billingMethod' />
        </span>
        <div>
          <span className={styles['more-icon-container']} onClick={handleClick}>
            <img
              className={styles['more-icon']}
              src={MoreHorizontal}
              alt='moreIcon'
            />
          </span>
          <Popover
            id='long-menu'
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            PaperProps={{
              style: {
                minWidth: '100px',
                padding: '6px',
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div
              className={styles['remove-payment-method']}
              onClick={() => setConformModal(true)}
            >
              <FormattedMessage id='settings_remove' />
            </div>
          </Popover>
        </div>
      </div>
      <div className={styles['membership-page-billing-method-next_billing']}>
        <FormattedMessage id='settings_nextBilling' />
      </div>
      <div className={styles['membership-page-billing-method_account_info']}>
        <div className={styles['card-methods']}>
          {cardType && (
            <img
              src={cardType}
              alt='card'
              style={{ height: 25 }}
              className={
                styles['membership-page-billing-method_account_info_visa']
              }
            />
          )}
          {paymentMethod.payment_type === PaymentType.card && (
            <span>
              {paymentMethod.name} <FormattedMessage id='settings_cardEnd' />{' '}
              {paymentMethod.card_number}
            </span>
          )}
          {paymentMethod.payment_type === PaymentType.vipps && (
            <span>
              {paymentMethod.name} +47{paymentMethod.phone_number}
            </span>
          )}
        </div>
        {/* <div>
          <span
            className={
              styles['membership-page-billing-method_account_info_visa']
            }
          >
            NOK
          </span>
          <span>
            <FormattedMessage
              id='settings_amount'
              values={{
                amount: (
                  <FormattedNumber
                    value={amount}
                    style='currency'
                    currency='Kr'
                  />
                ),
              }}
              defaultMessage='N/A'
            />
          </span>
        </div> */}
      </div>
      <ConformModal
        open={conformModal}
        handleClose={closeConformModal}
        deletePayment={deletePaymentMethods}
        title='paymentMethod'
      />
      {openModal && (
        <ProcessModal open={openModal} messageKey='delete_payment_method' />
      )}
    </div>
  );
};

export default BillingMethod;
