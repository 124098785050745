import React, { useState, useEffect } from 'react';
import DiscardModal from '../DiscardPopupModal/DiscardModal';
import SavedPopupModal from '../SavedPopupModal/SavedPopupModal';
import ConformModal from '../../../NewSettings/atoms/ConformDeleteModal';
import { makeStyles, Select, MenuItem } from '@material-ui/core';
import styles from './GraveInterestsForm.module.css';
import { request } from '../../../../service/request';
import FamilySilhouette from '../../../../img/FamilySilhouette.svg';
import OnlyMe from '../../../../img/OnlyMe.svg';
import Everyone from '../../../../img/Everyone.svg';
import DeleteIcon from '../../../../img/DeleteRedIcon.svg';
import EditIcon from '../../../../img/EditSmallIcon.svg';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import isEmpty from 'lodash.isempty';

const useStyle = makeStyles(() => ({
  rootIconMenu: {
    color: '#9f9f9f !important',
  },
  rootIconMenuOpen: {
    display: 'none',
  },
  selector: {
    width: '100%',
    background: '#F2F5F8 !important',
    borderRadius: '8px',
    padding: '10px 28px',
  },
}));

const GraveInterestsForm = ({
  interests,
  handelInterests,
  saveInterestsRedux,
  interest,
  interestsId,
  graveId,
  disable,
  handelCloseForm,
  handelInterestsId,
  captivatedInterests,
  handleTabChange,
  onClose,
}) => {
  const classes = useStyle();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [editInterest, setEditInterest] = useState({
    id: '',
    name: '',
    captivated_interest: {
      id: 1,
      interest_type: 'Velg kategori',
    },
    description: '',
  });

  useEffect(() => {
    if (!isEmpty(interest)) {
      setEditInterest({ ...interest });
    }
  }, [interest]);

  const handelEditForm = (field, value) => {
    if (value === 'captivated_interest') {
      setEditInterest((prev) => ({
        ...prev,
        [field]: {
          id: value.id,
          interest_type: value.interest_type,
        },
      }));
    } else {
      setEditInterest((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
    setIsChanged(true);
  };

  const closeDiscardModal = () => {
    setOpenDiscardModal(false);
    setIsChanged(false);
    if (Object.keys(interest).length > 0) {
      setEditInterest({ ...interest });
      handelInterestsId(null);
    } else {
      clearWorkspace();
      handelCloseForm();
      onClose();
      handelInterestsId(null);
    }
  };

  const closeSaveModal = () => {
    setOpenSaveModal(false);
    handelCloseForm();
    handelInterestsId(null);
    handleTabChange();
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const clearWorkspace = () => {
    setEditInterest({
      id: '',
      name: '',
      captivated_interest: {
        id: 1,
        interest_type: 'Velg kategori',
      },
      description: '',
    });
  };

  const createNewInterests = async () => {
    var formdata = new FormData();
    if (interestsId) {
      formdata.append('grave_interest[name]', editInterest.name);
      formdata.append(
        'grave_interest[captivated_interest_id]',
        editInterest.captivated_interest.id
      );
      formdata.append('grave_interest[description]', editInterest.description);
      request(`/grave_interests/${interestsId}`, formdata, 'put')
        .then((res) => {
          setOpenSaveModal(true);
          handelInterests(
            interests.map((w) =>
              w.id === interestsId
                ? {
                    ...editInterest,
                  }
                : w
            )
          );
          saveInterestsRedux(
            interests.map((w) =>
              w.id === interestsId
                ? {
                    ...editInterest,
                  }
                : w
            )
          );
        })
        .catch((err) => {
          showUserNotification('noe gikk galt, prøv igjen', 'warning');
        });
    } else {
      formdata.append('grave_interest[grave_id]', graveId);
      formdata.append('grave_interest[name]', editInterest.name);
      formdata.append(
        'grave_interest[captivated_interest_id]',
        editInterest.captivated_interest.id
      );
      formdata.append('grave_interest[description]', editInterest.description);
      request(`/grave_interests`, formdata, 'post')
        .then((res) => {
          setOpenSaveModal(true);
          handelInterests([{ ...editInterest, id: res.data.id }, ...interests]);
          saveInterestsRedux([
            { ...editInterest, id: res.data.id },
            ...interests,
          ]);
          clearWorkspace();
        })
        .catch((err) => {
          showUserNotification('noe gikk galt, prøv igjen', 'warning');
        });
    }
  };

  const deleteInterests = () => {
    if (interest.id) {
      request(`/grave_interests/${interest.id}`, null, 'delete')
        .then((res) => {
          const newArray = interests.filter((work) => work.id !== interest.id);
          saveInterestsRedux(newArray);
          handelInterests(newArray);
          setOpenDeleteModal(false);
          showUserNotification('Interessen er slettet', 'success');
        })
        .catch((err) => {
          showUserNotification(
            'Det oppstod en feil ved sletting av interessen',
            'warning'
          );
        });
      setOpenDeleteModal(false);
    }
  };

  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 32) {
      return true;
    } else if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
      return false;
    } else if (
      [33, 36, 37, 38, 39, 64].includes(keyCode) ||
      (keyCode >= 35 && keyCode <= 38) ||
      (keyCode >= 42 && keyCode <= 44) ||
      (keyCode >= 46 && keyCode <= 47)
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <>
      <div>
        {!disable && (
          <div className={styles['grave-actions-container']}>
            <div
              className={styles['grave-information-edit-delete-container']}
              style={{ marginBottom: '14px' }}
            >
              <div
                className={styles['action-btn']}
                onClick={() => {
                  handelInterestsId(editInterest.id);
                  handelCloseForm();
                }}
              >
                <img src={EditIcon} alt='Edit' />
                <button type='button'>Redigere</button>
              </div>
              <div
                className={styles['action-btn']}
                onClick={() => {
                  setOpenDeleteModal(true);
                }}
              >
                <img src={DeleteIcon} alt='Delete' />
                <button type='button'>Slett</button>
              </div>
            </div>
          </div>
        )}
        <div className={styles['company-name-container']}>
          <div className={styles['input-element']}>
            <div className={styles['title']}>Navn på interesser</div>
            <input
              disabled={!disable}
              className={styles['inputs']}
              type='text'
              required={true}
              placeholder='Skriv inn navnet på interessene'
              value={editInterest?.name ? editInterest.name : ''}
              onKeyPress={onKeyPress}
              onChange={(e) => {
                const inputValue = e.target.value;
                const trimmedValue = inputValue
                  .replace(/^\s+/, '')
                  .replace(/ {2,}$/, ' ');
                handelEditForm('name', trimmedValue);
              }}
            />
          </div>
          <div className={styles['input-element']}>
            <div className={styles['title']}>kategori</div>
            <select
              disabled={!disable}
              native
              id='demo-simple-select'
              value={
                editInterest?.captivated_interest.id
                  ? editInterest.captivated_interest.id
                  : ''
              }
              onChange={(e) =>
                handelEditForm('captivated_interest', {
                  id: e.target.value,
                  interest_type: e.target.value,
                })
              }
              className={styles['selector']}
            >
              <option value='' disabled>
                Velg kategori
              </option>
              {captivatedInterests.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.interest_type}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={styles['workspace-content-textarea']}>
          <div className={styles['input-element']}>
            <div className={styles['title']}>Beskrivelse</div>
            <textarea
              disabled={!disable}
              value={editInterest?.description ? editInterest.description : ''}
              onChange={(e) => handelEditForm('description', e.target.value)}
              placeholder='Beskrivelse av interessen'
            />
          </div>
        </div>
        {disable && (
          <div className={styles['grave-btn-container']}>
            <div className={styles['grave-information_content-verify']}>
              <button
                className={styles['rootDiscard']}
                onClick={() => {
                  if (isChanged) {
                    setOpenDiscardModal(true);
                  } else {
                    closeDiscardModal();
                  }
                }}
              >
                Avbryt
              </button>
              <button
                className={styles['rootVerify']}
                onClick={createNewInterests}
              >
                Lagre
              </button>
            </div>
          </div>
        )}
      </div>
      {openDiscardModal && (
        <DiscardModal
          open={openDiscardModal}
          handleClose={(value) => setOpenDiscardModal(value)}
          onConfirm={closeDiscardModal}
          title='interests'
        />
      )}
      {openSaveModal && (
        <SavedPopupModal
          open={openSaveModal}
          titleKey='interest'
          handleClose={closeSaveModal}
        />
      )}
      {openDeleteModal && (
        <ConformModal
          title='interests'
          open={openDeleteModal}
          handleClose={closeDeleteModal}
          deletePayment={deleteInterests}
        />
      )}
    </>
  );
};

export default GraveInterestsForm;
