import React, { useEffect } from 'react';
import Header from './Header';
import styles from './CreateMembership.module.css';
import InputForm from './InputForm';
import { fetch_request } from '../../../service/request';
import { useDispatch } from 'react-redux';
import {
  setInvitationTokenErrorMessage,
  setSubscriptionInvitationData,
  subscriptionInvitationToken,
} from '../../../redux/actions';
import { useHistory, useLocation } from 'react-router-dom';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import Invitation from '../LoginForm/Invitation';

const CreateMembership = ({ setUser, setHidePage }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const invitationToken = new URLSearchParams(location.search).get(
    'invitation_token'
  );

  if (invitationToken) {
    dispatch(subscriptionInvitationToken(invitationToken));
  }

  useEffect(() => {
    if (invitationToken) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      fetch_request(
        `/api/find_subscription_invitations/${invitationToken}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            showUserNotification(data.error, 'error');
            dispatch(setInvitationTokenErrorMessage(data.error));
            history.push('/registration');
          } else {
            dispatch(setSubscriptionInvitationData(data));
            dispatch(setInvitationTokenErrorMessage(''));
            if (data.user_slug) {
              history.push('/auth/sign-in');
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [invitationToken]);

  return (
    <div className={styles['container']}>
      <div className={styles['create-membership']}>
        <Invitation />
        <Header messageKey='create_new_account' headingKey='auth_welcome' />
        <InputForm setHidePage={setHidePage} setUser={setUser} />
      </div>
    </div>
  );
};

export default CreateMembership;
