import React from 'react';
import styles from './FreePlanContainer.module.css';
import { FormattedMessage } from 'react-intl';

const FreePlanContainer = () => {
  return (
    <div className={styles['payment-details']}>
      <div className={styles['free-heading']}>
        <span>
          <FormattedMessage id='change_free_plan' />
        </span>
      </div>
      <div className={styles['no-payment-message']}>
        <FormattedMessage id='free_plan_message' />
      </div>
    </div>
  );
};

export default FreePlanContainer;
