import React from 'react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import styles from './AccountConfirmation.module.css';
import Messages from './Messages';

const Error = ({ headingKey, descriptionKey, url, redirectKey }) => {
  return (
    <div className={styles['container']}>
      <WarningAmberRoundedIcon className={styles['error']} />
      <Messages
        headingKey={headingKey}
        descriptionKey={descriptionKey}
        url={url}
        redirectKey={redirectKey}
      />
    </div>
  );
};

export default Error;
