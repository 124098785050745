import React, { useState } from 'react';
import styles from './Card.module.css';
import Location from '../../../../../img/Location.svg';
import { useHistory } from 'react-router-dom';
import PrimaryButton from '../../../../../components/PrimaryButton';
import AuthModal from '../../../../../components/Modals/AuthModal';
import { useSelector } from 'react-redux';

const Card = (props) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuthorized);

  const goto = () => {
    if (!isAuth) {
      setOpenModal(true);
    } else {
      history.push(props.url);
    }
  };

  return (
    <div className={styles['card']}>
      <div className={styles['image-container']}>
        <img src={props.logo} alt='img' />
      </div>
      <div className={styles['name']}>{props.name}</div>

      <div className={styles['country']}>
        <img src={Location} alt='location' />
        {props.countryName}
      </div>
      <PrimaryButton
        onClick={goto}
        children={'besøk'}
        style={{ padding: '8px 42px' }}
      />
      {openModal && (
        <AuthModal isOpen={openModal} handleClose={() => history.push('/')} />
      )}
    </div>
  );
};

export default Card;
