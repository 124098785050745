import React, { useState } from 'react';
import Input from '../../../components/Input/Input';
import Header from '../CreateMembership/Header';
import PrimaryButton from '../../../components/PrimaryButton';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import styles from '../CreateMembership/CreateMembership.module.css';
import loginStyles from '../LoginForm/LoginForm.module.css';
import { useIntl, FormattedMessage } from 'react-intl';
import axios from 'axios';
import { CONSTANTS } from '../../../service/constants';
import { useHistory } from 'react-router-dom';
import ProcessModal from '../../Payment/atoms/ProcessModal';

const Form = ({ token }) => {
  const intl = useIntl();
  const history = useHistory();
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [openProcessModal, setOpenProcessModal] = useState(false);

  const isPasswordValid = () => {
    // Minimum password length: 7-8 characters
    if (formData.password.length < 7) {
      return false;
    }

    // Password complexity: at least 3 out of 4 categories
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /[0-9]/;
    const nonAlphanumericRegex = /[^A-Za-z0-9]/;
    let categoriesCount = 0;

    if (uppercaseRegex.test(formData.password)) {
      categoriesCount++;
    }
    if (lowercaseRegex.test(formData.password)) {
      categoriesCount++;
    }
    if (digitRegex.test(formData.password)) {
      categoriesCount++;
    }
    if (nonAlphanumericRegex.test(formData.password)) {
      categoriesCount++;
    }

    return categoriesCount >= 3;
  };

  const passwordsMatch = () => {
    return formData.password === formData.confirmPassword;
  };

  const inputItems = [
    {
      label: 'auth_newPassword',
      type: 'password',
      placeholder: 'settings_newPassword',
      action: (value) => {
        setFormData({ ...formData, password: value });
      },
      value: formData.password,
      autoComplete: 'new-password',
      error: formData.password.length > 0 && !isPasswordValid(),
      errorKey: 'password_length',
      disabled: false,
    },
    {
      label: 'auth_confirmPassword',
      type: 'password',
      placeholder: 'confirm_your_password',
      action: (value) => {
        setFormData({ ...formData, confirmPassword: value });
      },
      value: formData.confirmPassword,
      autoComplete: 'new-password',
      error: formData.confirmPassword.length > 7 && !passwordsMatch(),
      errorKey: 'auth_notSame',
      disabled: !isPasswordValid(),
    },
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (
      formData.password.length === 0 ||
      formData.confirmPassword.length === 0
    ) {
      showUserNotification(
        intl.formatMessage({ id: 'set_new_password_error' }),
        'warning'
      );
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      showUserNotification(
        intl.formatMessage({ id: 'auth_notSame' }),
        'warning'
      );
      return;
    }

    setOpenProcessModal(true);

    const data = {
      new_password: formData.password,
      new_password_confirmation: formData.confirmPassword,
      auth_token: token,
    };

    const config = {
      headers: {
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .post(`${CONSTANTS.baseUrl}/api/forgot_passwords`, data, config)
      .then((response) => {
        setOpenProcessModal(false);
        if (response.data.success) {
          showUserNotification(
            intl.formatMessage({ id: 'set_new_password_success' }),
            'success'
          );
          setTimeout(3000);
          history.push('/auth/sign-in');
        } else {
          showUserNotification(response.data.message, 'error');
        }
      })
      .catch((err) => {
        console.log(err);
        showUserNotification(err.response.data.error || err.message, 'error');
        setOpenProcessModal(false);
      });
  };

  return (
    <div className={loginStyles['login-form']}>
      <Header
        messageKey='set_new_password_description'
        headingKey='set_new_password'
      />
      <form autoComplete='off' onSubmit={handleFormSubmit}>
        {inputItems.map((item, index) => (
          <Input item={item} key={index} />
        ))}
        <div className={styles['btn-container']}>
          <PrimaryButton
            type='submit'
            style={{ background: '#FEA65A', padding: '8px 24px' }}
            children={<FormattedMessage id='auth_save' />}
            onClick={handleFormSubmit}
          />
        </div>
      </form>
      {openProcessModal && (
        <ProcessModal
          open={openProcessModal}
          messageKey='set_new_password_waiting'
        />
      )}
    </div>
  );
};

export default Form;
