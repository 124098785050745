import React, { useState } from 'react';
import './Header.css';
import SearchField from './atoms/SearchField';
import { connect, useDispatch } from 'react-redux';
import { setSearchInputType, setTranslation } from '../../redux/actions';
import LoggedIn from './atoms/LoggedIn';
import HeaderTabs from './atoms/HeaderTabs';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import LanguageSelector from '../LanguageSelector';
import { languages } from '../../service/constants';

const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const translationKey = useSelector((state) => state.auth.translationKey);

  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocused(false);
  };

  const handleChangeLanguage = (lng) => {
    dispatch(setTranslation(lng.key));
  };

  return (
    <div className='header-section'>
      <div className='header-section_first-row_mobile'>
        <SearchField
          history={props.history}
          setSearchInput={props.setSearchInput}
          searchInput={props.searchInput}
          handleSearchFocus={handleSearchFocus}
          handleSearchBlur={handleSearchBlur}
          isSearchFocused={isSearchFocused}
        />

        <div className='header-section_menu'>
          <HeaderTabs />
        </div>

        {!props.isAuthorized ? (
          <div className='login-btn-container'>
            <LanguageSelector
              languages={languages}
              onChanged={handleChangeLanguage}
              preferredLanguage={translationKey}
            />
            <div className='unauthorized-full'>
              <button
                onClick={() => history.push('/auth/sign-in')}
                className='sign-in-options login_btn'
              >
                <FormattedMessage id='auth_login' />
              </button>
              <button
                onClick={() => history.push('/registration')}
                className='sign-in-options register_btn'
              >
                <FormattedMessage id='auth_register' />
              </button>
            </div>
          </div>
        ) : (
          <div className={isSearchFocused ? 'unauthorized-full' : ''}>
            <LoggedIn history={props.history} />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchInput: state.search.searchInput,
    isAuthorized: state.auth.isAuthorized,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchInput: (searchInput) => {
      dispatch({ type: setSearchInputType, searchInput: searchInput });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
