import React from 'react';
import './SignInPage.css';
import LoginForm from '../../containers/NewAuth/LoginForm/LoginForm';

const SignInPage = (props) => {
  return (
    <div className='sign-in-page'>
      <LoginForm />
    </div>
  );
};

export default SignInPage;
