import React, { useState } from 'react';
import styles from './VideoPost.module.css';
import NextPhotoPointer from '../../../../img/OrangePointerNext.svg';
import PrevPhotoPointer from '../../../../img/OrangePointerPrev.svg';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PhotoPost = (props) => {
  const history = useHistory();
  const grave = useSelector((state) => state.grave.grave);
  const [currentPhoto, setCurrentPhoto] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const prevPhoto = (event) => {
    setIsLoading(true);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const prevIndex =
      (currentPhoto - 1 + props.photos.length) % props.photos.length;
    setCurrentPhoto(prevIndex);
  };

  const nextPhoto = (event) => {
    setIsLoading(true);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const nextIndex = (currentPhoto + 1) % props.photos.length;
    setCurrentPhoto(nextIndex);
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className={styles['music-post_content-photo']}>
      <img
        loading='lazy'
        src={props.photos[currentPhoto].file_url}
        onLoad={handleImageLoad}
        className={styles['photo-post_content-photo_photo']}
        onClick={() =>
          history.push(
            `/deceased/${grave.slug}/memory/${props.post.id}?photo=${props.photos[currentPhoto].id}`
          )
        }
      />
      {isLoading && (
        <div className={styles['loading-image']}>
          <span>Laster...</span>
        </div>
      )}
      {props.photos[currentPhoto]?.title && !isLoading && (
        <span className={styles['photo-title']}>
          {props.photos[currentPhoto].title}
        </span>
      )}
      {props.photos.length > 1 && (
        <React.Fragment>
          <button
            className={styles['buttons-prev']}
            onClick={(event) => prevPhoto(event)}
          >
            <img loading='lazy' src={PrevPhotoPointer} alt='PrevPhotoPointer' />
          </button>
          <button
            className={styles['buttons-next']}
            onClick={(event) => nextPhoto(event)}
          >
            <img loading='lazy' src={NextPhotoPointer} alt='PrevPhotoPointer' />
          </button>
        </React.Fragment>
      )}
    </div>
  );
};

export default PhotoPost;
