import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { SignInPage, NotFoundPage } from '../index';
import Registration from './Registration';
import { connect, useDispatch } from 'react-redux';
import animClasses from '../Router.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { subscriptionInvitationToken } from '../../redux/actions';
import isEmpty from 'lodash.isempty';

const AuthRouter = ({ component: Component, ...props }) => {
  const dispatch = useDispatch();
  const action = props.location.pathname.match(/([^*/]+)/g)[1];
  const screen = useSelector((state) => state.auth.currentScreen);
  const userSlug = localStorage.getItem('user-slug');
  const invitationToken = useSelector(
    (state) => state.invitationToken.subscriptionInvitationToken
  );

  const slugRedirect = localStorage.getItem('slug_for_redirect');

  useEffect(() => {
    sessionStorage.setItem('previousPath', props.location.pathname);
  }, [props.location.pathname]);

  const redirectToLastPage = () => {
    const { location, history } = props;
    const trimmedToken = invitationToken.trim();
    const previousPath = sessionStorage.getItem('previousPath');

    if (
      slugRedirect !== 'null' &&
      slugRedirect !== null &&
      slugRedirect !== undefined
    ) {
      localStorage.setItem('slug_for_redirect', 'null');
      setTimeout(() => history.push(`/deceased/${slugRedirect}`), 1000);
    } else if (location.key) {
      if (trimmedToken === '') {
        if (
          previousPath &&
          (previousPath === '/registration' ||
            previousPath === '/auth/sign-in' ||
            previousPath === '/account-confirmation' ||
            previousPath === '/reset-password')
        ) {
          sessionStorage.removeItem('previousPath');
          history.push(`/${screen ? screen : ''}`);
        } else {
          history.goBack();
        }
      } else {
        dispatch(subscriptionInvitationToken(''));
        history.push(`/member/${userSlug}`);
      }
    } else {
      history.push(`/${screen ? screen : ''}`);
    }
  };

  return (
    <TransitionGroup>
      <CSSTransition
        key={action}
        appear
        unmountOnExit
        classNames={{ ...animClasses }}
      >
        <Switch location={props.location}>
          {props.isAuthorized ? (
            redirectToLastPage()
          ) : (
            <Redirect exact from='/auth/' to='/auth/sign-in' />
          )}

          <Route exact path='/auth/sign-in' component={SignInPage} />
          <Route exact path='/registration' component={Registration} />
          <Route component={NotFoundPage} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

const mapStateToProps = (state) => ({
  isAuthorized: state.auth.isAuthorized,
});

export default connect(mapStateToProps)(AuthRouter);
