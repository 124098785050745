import React, { useState, useEffect, useCallback } from 'react';
import styles from './PlansDescription.module.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const PlansDescription = ({
  isFreePlan,
  amount,
  subscriptionPeriod,
  chosenPlan,
}) => {
  const [total, setTotal] = useState(0);
  const subscriptionCount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalCount
  );

  const calculateTotal = useCallback(() => {
    return amount * subscriptionPeriod.length;
  }, [amount, subscriptionCount, subscriptionPeriod.length]);

  useEffect(() => {
    setTotal(calculateTotal());
  }, [calculateTotal]);

  return (
    <div>
      <div className={styles['automatic-renew']}>
        {!isFreePlan ? (
          <>
            <span className={styles['automatic-renew-heading']}>
              <FormattedMessage id='renewal' />
            </span>

            <span className={styles['automatic-renew-desc']}>
              <FormattedMessage
                id='renewal_details'
                values={{
                  planPrice: chosenPlan.price,
                  subscriptionCount,
                  total,
                  periodName: <FormattedMessage id={subscriptionPeriod.key} />,
                }}
              />
            </span>
          </>
        ) : null}
      </div>
      <div className={styles['terms-condition']}>
        <span>
          <FormattedMessage id='payment_page_terms' />
          <Link
            to='/terms_conditions'
            className={styles['terms-condition-link']}
          >
            <FormattedMessage id='terms_conditions' />
          </Link>
        </span>
      </div>
    </div>
  );
};

export default PlansDescription;
