import React from 'react';
import styles from './Loader.module.css';
import { CircularProgress } from '@material-ui/core';

const Loader = () => {
  return (
    <div className={styles['loadingSpinner']}>
      <CircularProgress
        color='secondary'
        size={80}
        style={{ color: '#fea65a' }}
      />
    </div>
  );
};

export default Loader;
