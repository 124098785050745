import React from 'react';
import Alert from '../../../../components/Alert/Alert';
import styles from './SubscriptionAlert.module.css';
import { Button } from '@material-ui/core';

const SubscriptionAlert = ({ alert }) => {
  return (
    <Alert>
      <div className={styles['container']}>
        <div className={styles['alert-title']}>{alert.title}</div>
        <div className={styles['alert-message']}>{alert.message}</div>
        {alert.action && (
          <div className={styles['alert-cta']}>
            <Button size='small' onClick={() => alert.handelAction()}>
              {alert.action}
            </Button>
          </div>
        )}
      </div>
    </Alert>
  );
};

export default SubscriptionAlert;
