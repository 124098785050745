import React, { useState } from 'react';
import styles from './PlanPaymentDetails.module.css';
import Popover from '@mui/material/Popover';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MenuItem, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setSubscriptionTotalPrice } from '../../../../redux/actions';
import { FormattedMessage } from 'react-intl';
import { planKey } from '../../../../service/PlanKey';

const useStyles = makeStyles((theme) => ({
  children: {
    whiteSpace: 'normal',
    backgroundColor: '#fff!important',
    color: '#36434B',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '400',
    padding: '10px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)!important',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
    marginRight: '10px',
  },
  listItemText: {
    fontSize: '1em !important',
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const PlansList = ({ subscriptionCount, plans, handleProductChosen, tax }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleMenuItemClick = (selectedProductId) => {
    const selectedProduct = plans.find(
      (product) => product.id === selectedProductId
    );
    handleProductChosen(selectedProduct);
    if (selectedProduct && subscriptionCount > 0) {
      const total = selectedProduct.price * subscriptionCount + tax;
      dispatch(setSubscriptionTotalPrice(total));
    } else {
      const total = selectedProduct.price + tax;
      dispatch(setSubscriptionTotalPrice(total));
    }
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={styles['order-details']}>
      <span className={styles['order-heading']}>
        <FormattedMessage id='order_details' />
      </span>
      <div className={styles['plans-list']}>
        <button onClick={(event) => handleClick(event)}>
          <FormattedMessage id='settings_changePlan' />
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {plans &&
            plans.map((product, index) => {
              return (
                <MenuItem
                  key={index}
                  className={classes.children}
                  onClick={() => handleMenuItemClick(product.id)}
                >
                  <FormattedMessage id={planKey(product.key)} />
                </MenuItem>
              );
            })}
        </Popover>
      </div>
    </div>
  );
};

export default PlansList;
