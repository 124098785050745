import React, { useEffect, useState } from 'react';
import styles from './GraveUserForm.module.css';
import DiscardModal from '../DiscardPopupModal/DiscardModal';
import SavedPopupModal from '../SavedPopupModal/SavedPopupModal';
import { request } from '../../../../service/request';
import ConformModal from '../../../NewSettings/atoms/ConformDeleteModal';
import InvalidNumberModal from './InvalidNumberModal/InvalidNumberModal';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import isEmpty from 'lodash.isempty';
import PopoverModal from './PopoverModal';
import UserInfo from './UserInfo';
import InputGroup from '../../../../components/InputGroup/InputGroup';

const GraveUserForm = ({
  relation,
  relationId,
  disable,
  grave,
  handelRelations,
  saveRelationsRedux,
  relations,
  handelRelationId,
  relationList,
  directionsList,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openInValidModal, setOpenInValidModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [editRelation, setEditRelation] = useState({
    id: '',
    user_id: '',
    user_name: '',
    slug: '',
    grave_id: '',
    relation: {},
    reciprocal_relation: {},
    status: '',
    admin: false,
    user_avatar: '',
    gender: '',
  });

  const [formErrors, setFormErrors] = useState({
    reciprocal_relation: false,
    relation: false,
    admin: false,
  });

  const adminOptions = [
    {
      id: 1,
      key: 'administrator',
      name: 'Administrator',
    },
    { id: 2, key: 'normal', name: 'Normal' },
  ];

  useEffect(() => {
    if (!isEmpty(relation)) {
      if (relation.admin) {
        setEditRelation({ ...relation, admin: adminOptions[0] });
      } else {
        setEditRelation({ ...relation, admin: adminOptions[1] });
      }
    }
  }, [relation]);

  const handleOnSelectRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setEditRelation((prev) => ({
        ...prev,
        relation: {},
      }));
      setIsChanged(true);
    } else if (reason === 'selectOption') {
      setEditRelation((prev) => ({
        ...prev,
        relation: value,
      }));
      setIsChanged(true);
      setFormErrors((prev) => ({
        ...prev,
        relation: false,
      }));
    }

    setEditRelation((prev) => ({
      ...prev,
      reciprocal_relation: {},
    }));
  };

  const handleOnSelectReciprocalRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setEditRelation((prev) => ({
        ...prev,
        reciprocal_relation: {},
      }));
      setIsChanged(true);
    } else if (reason === 'selectOption') {
      setEditRelation((prev) => ({
        ...prev,
        reciprocal_relation: value,
      }));
      setFormErrors((prev) => ({
        ...prev,
        reciprocal_relation: false,
      }));
      setIsChanged(true);
    }
  };

  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = [];

    directionsList.map((item) => {
      if (item.from_relation.id === selectedRelation.id) {
        filteredList.push(item.to_relation);
      }

      return false;
    });

    if (filteredList.length === 0) {
      return [selectedRelation];
    } else {
      return filteredList;
    }
  };

  const handleSelectRole = (event, value, reason) => {
    if (reason === 'clear') {
      setEditRelation((prev) => ({
        ...prev,
        admin: {},
      }));
      setIsChanged(true);
    } else if (reason === 'selectOption') {
      setEditRelation((prev) => ({
        ...prev,
        admin: value,
      }));
      setFormErrors((prev) => ({
        ...prev,
        admin: false,
      }));
      setIsChanged(true);
    }
  };

  const deleteRelation = () => {
    if (editRelation.id) {
      request(`/grave_users/${editRelation.id}`, null, 'delete')
        .then((res) => {
          const newArray = relations.filter(
            (relation) => relation.id !== editRelation.id
          );
          saveRelationsRedux(newArray);
          handelRelations(newArray);
          setOpenDeleteModal(false);
          showUserNotification('Personen er avlinket fra graven', 'success');
        })
        .catch((err) => {
          showUserNotification(
            err.response.data.error || err.message,
            'warning'
          );
        });
      setOpenDeleteModal(false);
    }
  };

  const closeDiscardModal = () => {
    setOpenDiscardModal(false);
    setIsChanged(false);
    if (Object.keys(relation).length > 0) {
      setEditRelation({ ...relation });
      handelRelationId(null);
    } else {
      clearRelation();
      handelRelationId(null);
    }
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeSaveModal = () => {
    setOpenSaveModal(false);
    handelRelationId(null);
  };

  const validateForm = () => {
    const { relation, reciprocal_relation, admin } = editRelation;
    const errors = {
      relation: isEmpty(relation),
      reciprocal_relation: isEmpty(reciprocal_relation),
      admin: isEmpty(admin),
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error);
  };

  const updateUser = async () => {
    if (relationId && validateForm()) {
      const inviteRelation = {
        grave_user: {
          admin: editRelation.admin.key === 'administrator' ? true : false,
          relation_id: editRelation.relation.id,
          reciprocal_relation_id: editRelation.reciprocal_relation.id,
          grave_id: grave.id,
        },
      };
      request(`/grave_users/${relation.id}`, inviteRelation, 'put')
        .then((res) => {
          handelRelations(
            relations.map((w) =>
              w.id === relationId
                ? {
                    ...res.data,
                  }
                : w
            )
          );
          saveRelationsRedux(
            relations.map((w) =>
              w.id === relationId
                ? {
                    ...res.data,
                  }
                : w
            )
          );
          setOpenSaveModal(true);
          // handleClose();
        })
        .catch((err) => {
          showUserNotification(
            err.response.data.error || err.message,
            'warning'
          );
        });
    }
  };

  const clearRelation = () => {
    setEditRelation({
      id: '',
      user_id: '',
      user_name: '',
      slug: '',
      grave_id: '',
      relation: {},
      reciprocal_relation: {},
      status: '',
      admin: false,
      user_avatar: '',
      gender: '',
    });
  };

  let formInputs = [
    {
      label: 'user_name',
      type: 'text',
      placeholder: 'user_name',
      value: editRelation.user_name,
      autoComplete: 'off',
      error: false,
      errorKey: 'enter_name_error',
      disabled: true,
      action: (value) => {
        if (value[0] !== ' ') {
          setEditRelation((prev) => ({
            ...prev,
            user_name: value
              .replace(/[0-9]/g, '')
              .replace(/[\"'@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/g, ''),
          }));
        }
      },
    },
    {
      label: 'administrator_access',
      type: 'autocomplete',
      placeholder: 'select_role',
      value: editRelation.admin,
      options: adminOptions,
      autoComplete: 'off',
      error: formErrors.admin,
      errorKey: 'select_role_error',
      disabled: editRelation.grave_owner,
      action: handleSelectRole,
    },
    {
      label: 'relation_to_grave',
      type: 'autocomplete',
      placeholder: 'select_relationship',
      value: editRelation.relation,
      options: relationList,
      autoComplete: 'off',
      error: formErrors.relation,
      errorKey: 'select_relation_error',
      disabled: !disable,
      action: handleOnSelectRelation,
    },
    {
      label: 'relation_to_person',
      type: 'autocomplete',
      placeholder: 'select_relationship',
      value: editRelation.reciprocal_relation,
      options: filterDirectionList(editRelation.relation),
      autoComplete: 'off',
      error: formErrors.reciprocal_relation,
      errorKey: 'select_relation_error',
      disabled: !disable,
      action: handleOnSelectReciprocalRelation,
    },
  ];

  return (
    <>
      <div>
        {!disable ? (
          <div className={styles['relation-list']}>
            <UserInfo editRelation={editRelation} />
            <PopoverModal
              handelRelationId={handelRelationId}
              setOpenDeleteModal={setOpenDeleteModal}
              editRelation={editRelation}
              relations={relations}
            />
          </div>
        ) : (
          <React.Fragment>
            <InputGroup inputs={[formInputs[0], formInputs[1]]} />
            <InputGroup inputs={[formInputs[2], formInputs[3]]} />
          </React.Fragment>
        )}
        {disable && (
          <div className={styles['grave-btn-container']}>
            <div className={styles['grave-information_content-verify']}>
              <button
                type='button'
                className={styles['rootDiscard']}
                onClick={() => {
                  if (isChanged) {
                    setOpenDiscardModal(true);
                  } else {
                    closeDiscardModal();
                  }
                }}
              >
                Avbryt
              </button>
              <button
                className={styles['rootVerify']}
                type='submit'
                onClick={updateUser}
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>
      {openDiscardModal && (
        <DiscardModal
          open={openDiscardModal}
          handleClose={(value) => setOpenDiscardModal(value)}
          onConfirm={closeDiscardModal}
          title='relative'
        />
      )}
      {openSaveModal && (
        <SavedPopupModal
          open={openSaveModal}
          titleKey='relation'
          handleClose={closeSaveModal}
        />
      )}
      {openDeleteModal && (
        <ConformModal
          title='relative'
          open={openDeleteModal}
          handleClose={closeDeleteModal}
          deletePayment={deleteRelation}
        />
      )}
      {openInValidModal && (
        <InvalidNumberModal
          open={openInValidModal}
          handleClose={() => setOpenInValidModal(false)}
        />
      )}
    </>
  );
};

export default GraveUserForm;
