import React from 'react';
import X from '../../img/X.svg';
import styles from './SubscriptionInviteModal.module.css';
import { Modal, makeStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { planKey } from '../../service/PlanKey';
import { request } from '../../service/request';
import showUserNotification from '../UserNotification/showUserNotification';
import { useDispatch } from 'react-redux';
import { setInformationAboutUser } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '32px',
    width: '700px',
    borderRadius: '10px',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const SubscriptionInviteModal = ({ onOpen, onClose, currentUser }) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const handelAction = (value) => {
    if (!currentUser) {
      return;
    }

    request(
      `/subscription_invitations/${currentUser.subscription_invitation.id}/${value}?user_id=${currentUser.id}`,
      {},
      'post'
    )
      .then((response) => {
        const { subscription_invitation, ...updatedUser } = currentUser;
        if (response.data.message) {
          showUserNotification(response.data.message, 'warning');
        } else {
          showUserNotification(
            intl.formatMessage({ id: 'accepted_message' }),
            'success'
          );
        }
        dispatch(setInformationAboutUser({ user: { ...updatedUser } }));
      })
      .catch((error) => {
        showUserNotification(
          error.response.data.errors || error.message,
          'error'
        );
      });
    onClose();
  };

  return (
    <Modal
      open={onOpen}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className={styles['content_header']}>
          <span>
            <FormattedMessage
              id='hi'
              values={{ name: currentUser.full_name }}
            />
          </span>
          <img loading='lazy' src={X} alt='x' onClick={onClose} />
        </div>
        <div className={styles['message-container']}>
          <span className={styles['message']}>
            <FormattedMessage
              id='subscription_invitation_message'
              values={{
                subscription: planKey(
                  currentUser.subscription_invitation?.subscription_key
                ),
                name: currentUser.subscription_invitation?.invited_by,
              }}
            />
          </span>
        </div>
        <div className={styles['grave-information_content-verify']}>
          <button
            className={styles['rootDiscard']}
            onClick={() => handelAction('decline')}
          >
            <FormattedMessage id='decline' />
          </button>
          <button
            className={styles['rootVerify']}
            onClick={() => handelAction('accept')}
          >
            <FormattedMessage id='accept' />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SubscriptionInviteModal;
