import React, { useState } from 'react';
import styles from './RemoveAdmin.module.css';
import { Avatar, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DeleteIcon from '../../../../../img/DeleteIcon.svg';
import ConformModal from '../../../../NewSettings/atoms/ConformDeleteModal';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    marginBottom: '8px',
  },
}));

const RemoveAdmin = ({ user, handelAdmin }) => {
  const history = useHistory();
  const classes = useStyles();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  return (
    <React.Fragment>
      <div
        className={styles['container']}
        onClick={() => history.push(`/member/${user.slug}`)}
      >
        <Avatar src={user.user_avatar} className={classes.large} />
        <div className={styles['user-name']}>{user.user_name}</div>
        <div className={styles['user-relation']}>{user.relation_name}</div>
        {user.grave_owner ? (
          <div className={styles['user-role']}>graveier</div>
        ) : user.admin ? (
          <div className={styles['user-role']}>Admin</div>
        ) : null}
        {!user.grave_owner && (
          <button
            className={styles['remove-btn']}
            onClick={(e) => {
              if (e) {
                e.preventDefault();
                e.stopPropagation();
              }
              setOpenConfirmationModal(true);
            }}
          >
            <img src={DeleteIcon} alt='Delete' />
          </button>
        )}
      </div>
      {openConfirmationModal && (
        <ConformModal
          title='Admin'
          open={openConfirmationModal}
          handleClose={() => setOpenConfirmationModal(false)}
          deletePayment={() => handelAdmin(false, user.id)}
        />
      )}
    </React.Fragment>
  );
};

export default RemoveAdmin;
