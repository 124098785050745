import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './SettingsPageHeader.module.css';
import 'cropperjs/dist/cropper.css';
import UserProfileImage from '../../../components/UserProfileImage';
import isEmpty from 'lodash.isempty';
import BackButton from '../../../components/BackButton/BackButton';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProductKeys } from '../../../service/constants';

const familySubscriptionMenu = [
  {
    name: <FormattedMessage id='settings_personalInformation' />,
    url: `/settings`,
  },
  {
    name: <FormattedMessage id='settings_password' />,
    url: `/settings/password`,
  },
  {
    name: <FormattedMessage id='settings_paymentMethod' />,
    url: `/settings/payment-methods`,
  },
  {
    name: <FormattedMessage id='settings_payments' />,
    url: `/settings/payments`,
  },
  {
    name: <FormattedMessage id='settings_subscription' />,
    url: `/settings/subscriptions`,
  },
  {
    name: <FormattedMessage id='settings_members' />,
    url: `/settings/plan-members`,
  },
  {
    name: <FormattedMessage id='settings_giftInvitations' />,
    url: `/settings/subscription-invitations`,
  },
  {
    name: <FormattedMessage id='settings_notifications' />,
    url: `/settings/notifications`,
  },
];

const familyPlusSubscriptionMenu = [
  ...familySubscriptionMenu,
  {
    name: <FormattedMessage id='settings_contact' />,
    url: `/settings/contact`,
  },
];

const SettingsPageHeader = ({ currentSubscription }) => {
  const intl = useIntl();
  const [menuItems, setMenuItems] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (!isEmpty(currentSubscription)) {
      currentSubscription.key === ProductKeys.conscious_plus
        ? setMenuItems(familyPlusSubscriptionMenu)
        : setMenuItems(familySubscriptionMenu);
    } else {
      setMenuItems(familySubscriptionMenu);
    }
  }, [currentSubscription]);

  const handleClick = (url) => {
    history.push(url);
  };

  return (
    <div className={styles['settings-header']}>
      <BackButton fallBackUrl={'/'} />
      <div className={styles['header-title']}>
        <FormattedMessage id='settings_profile' />
      </div>
      <div className={styles['settings-page-nav']}>
        <div className={styles['settings-page_header-info']}>
          <div className={styles['settings-page_header-info-userImage']}>
            <UserProfileImage user={user} />
          </div>
          <span className={styles['settings-page_header-info_name']}>
            {!isEmpty(user) ? user.full_name : ''}
          </span>
          <span className={styles['settings-page_header-info_date-of-birth']}>
            {!isEmpty(user) && user.date_of_birth
              ? `${new Date(user.date_of_birth).getDate()} ${intl.formatMessage(
                  { id: `month_${new Date(user.date_of_birth).getMonth()}` }
                )}, ${new Date(user.date_of_birth).getFullYear()}`
              : ''}
          </span>
        </div>
        <div className={styles['settings-page_header-navigation']}>
          <div className={styles['settings-page_header-navigation_first']}>
            {menuItems.map((item, index) => (
              <button
                key={index}
                onClick={() => {
                  handleClick(item.url);
                }}
                className={
                  item.url === location.pathname
                    ? `${styles['listItem']} ${styles['listItemSelected']}`
                    : styles['listItem']
                }
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPageHeader;
