import React, { useState } from 'react';
import './Grave.css';
import { useHistory } from 'react-router-dom';
import PrimaryButton from '../../../components/PrimaryButton';
import { FormattedMessage } from 'react-intl';
import AuthModal from '../../../components/Modals/AuthModal';
import { useSelector } from 'react-redux';

const GraveButton = ({ grave }) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuthorized);

  const goto = () => {
    if (!isAuth) {
      setOpenModal(true);
    } else {
      history.push(`/deceased/${grave.slug}`);
    }
  };
  return (
    <React.Fragment>
      <PrimaryButton
        onClick={goto}
        children={<FormattedMessage id='goto_grave' />}
      />
      {openModal && (
        <AuthModal isOpen={openModal} handleClose={() => history.push('/')} />
      )}
    </React.Fragment>
  );
};

export default GraveButton;
