import React from 'react';
import { Modal, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import styles from './ErrorModal.module.css';
import X from '../../../../img/X.svg';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    height: 'maxContent',
    width: 500,
    backgroundColor: '#fff',
    borderRadius: '10px',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const ErrorModal = ({ open, handleClose, message }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      title='Betalingsfeil!'
    >
      <div className={classes.modalContent}>
        <img
          loading='lazy'
          src={X}
          className={styles['image']}
          alt='x'
          onClick={handleClose}
        />
        <div className={styles['message-container']}>
          <div className={styles['oops']}>
            <FormattedMessage id='payment_error' />
          </div>
          <div className={styles['message-wrapper']}>
            {message ? (
              <div className={styles['message']}>{message}</div>
            ) : (
              <div className={styles['message']}>
                <FormattedMessage id='something_wrong' />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
