import React from 'react';
import X from '../../img/X.svg';
import styles from './GraveInviteModal.module.css';
import { Modal, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '32px',
    width: '700px',
    borderRadius: '10px',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const GraveInviteModal = ({
  onOpen,
  onClose,
  handelAction,
  graveInvitation,
  currentUser,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={onOpen}
      // onClose={onClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className={styles['content_header']}>
          <span>Velkommen, {currentUser.full_name}</span>
          <img loading='lazy' src={X} alt='x' onClick={onClose} />
        </div>
        <div className={styles['message-container']}>
          <span className={styles['message']}>
            Du har blitt invitert til å bidra til grav{' '}
            <Link
              className={styles['link']}
              to={`/deceased/${graveInvitation.grave.slug}`}
            >
              {graveInvitation.grave.name}
            </Link>{' '}
            av {graveInvitation.invited_by.name}.
          </span>
          <span className={styles['message']}>
            Med invitasjonen kan du legge til 10 minner på graven. For å legge
            til flere minner og samarbeide, må du være en del av en
            Familieabonnement eller ha et individuelt betalt abonnement.
          </span>
        </div>
        <div className={styles['grave-information_content-verify']}>
          <button
            className={styles['rootDiscard']}
            onClick={() => handelAction('decline')}
          >
            Avslå
          </button>
          <button
            className={styles['rootVerify']}
            onClick={() => handelAction('accept')}
          >
            Godta
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default GraveInviteModal;
