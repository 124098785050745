import React, { useState } from 'react';
import './HeaderTabs.css';
import {
  Tab,
  Tabs,
  MenuItem,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Popover,
  Collapse,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setScreenRedux, setTranslation } from '../../../redux/actions';
import MenuBlue from '../../../img/MenuBlue.svg';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FormattedMessage } from 'react-intl';
import LanguageSelector from '../../LanguageSelector';
import { languages } from '../../../service/constants';

const useStyles = makeStyles((theme) => ({
  children: {
    whiteSpace: 'normal',
    backgroundColor: '#fff!important',
    color: '#36434B',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '400',
    padding: '10px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)!important',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
    marginRight: '10px',
  },
  listItemText: {
    fontSize: '1em !important',
  },
  root: {
    width: '100%',
    minWidth: '170px',
    backgroundColor: theme.palette.background.paper,
  },
}));

const UnAuthorizedHeader = () => {
  const dispatch = useDispatch();
  const translationKey = useSelector((state) => state.auth.translationKey);
  const [value, setValue] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const mobilePopoverOpen = Boolean(mobileAnchorEl);
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const handleSubMenuExpandClick = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(
      setScreenRedux(newValue === 0 ? 'search' : newValue === 1 ? 'graver' : '')
    );
  };

  const classes = useStyles();
  const history = useHistory();

  const handleAvatarMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClick = (event) => {
    setMobileAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    setAnchorEl(null);
    setMobileAnchorEl(null);
    history.push(path);
  };

  const menuItems = [
    { title: 'home', action: () => handleMenuItemClick('/') },
    {
      title: 'about',
      action: () => handleMenuItemClick('/about-product'),
    },
    {
      title: 'features',
      action: () => handleMenuItemClick('/funksjoner'),
    },
    {
      title: 'howTo',
      action: (event) => handleAvatarMenuClick(event),
      subItems: [
        {
          title: 'find_a_grave',
          action: () => handleMenuItemClick('/find'),
        },
        {
          title: 'create_a_grave',
          action: () => handleMenuItemClick('/create'),
        },
        {
          title: 'take_control',
          action: () => handleMenuItemClick('/ownership'),
        },
      ],
    },
    {
      title: 'plans',
      action: () => handleMenuItemClick('/abonnement'),
    },
    {
      title: 'pricing',
      action: () => handleMenuItemClick('/pricing'),
    },
  ];

  const handleChangeLanguage = (lng) => {
    dispatch(setTranslation(lng.key));
  };

  return (
    <>
      <div className='unauthorized-full'>
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          id='id223223'
          indicatorColor='primary'
          textColor='primary'
          aria-label='scrollable force tabs example'
        >
          {menuItems.map((item, index) => (
            <Tab
              key={index}
              label={<FormattedMessage id={item.title} />}
              onClick={item.action}
              component={item.action ? undefined : Link}
              to={item.action ? undefined : item.to}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {menuItems
            .find((item) => item.title === 'howTo')
            ?.subItems.map((subItem, index) => (
              <MenuItem
                key={index}
                className={classes.children}
                onClick={subItem.action}
              >
                <FormattedMessage id={subItem.title} />
              </MenuItem>
            ))}
        </Popover>
      </div>

      <div className='unauthorized-mobile'>
        <div>
          <LanguageSelector
            languages={languages}
            onChanged={handleChangeLanguage}
            preferredLanguage={translationKey}
          />
        </div>
        <div className='menu' onClick={handleMobileMenuClick}>
          <img alt='menu' src={MenuBlue} />
        </div>
        <Popover
          open={mobilePopoverOpen}
          anchorEl={mobileAnchorEl}
          onClose={handleMobileMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List component='nav' className={classes.root}>
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem button onClick={item.action}>
                  <ListItemText
                    primary={<FormattedMessage id={item.title} />}
                    classes={{ primary: classes.listItemText }}
                  />
                  {item.subItems &&
                    (subMenuOpen ? <ExpandLess /> : <ExpandMore />)}
                </ListItem>
                {item.subItems && (
                  <Collapse in={subMenuOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      {item.subItems.map((subItem, subIndex) => (
                        <ListItem
                          key={subIndex}
                          button
                          className={classes.nested}
                          onClick={subItem.action}
                        >
                          <ListItemText
                            primary={<FormattedMessage id={subItem.title} />}
                            classes={{ primary: classes.listItemText }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
            <div className='unauthorized-mobile-login'>
              <button
                onClick={() => handleMenuItemClick('/auth/sign-in')}
                className='sign-in-options login_btn unauth-mobile'
              >
                <FormattedMessage id='auth_login' />
              </button>
              <button
                onClick={() => handleMenuItemClick('/registration')}
                className='sign-in-options register_btn unauth-mobile'
              >
                <FormattedMessage id='auth_register' />
              </button>
            </div>
          </List>
        </Popover>
      </div>
    </>
  );
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default UnAuthorizedHeader;
