import React, { useState } from 'react';
import styles from './PhoneNumber.module.css';
import { makeStyles, Button, Popover } from '@material-ui/core';
import CountriesPopover from '../../containers/Auth/atoms/CountriesPopover';
import CountriesArrowClosed from '../../img/CountriesPopoverClosed.svg';
import CountriesArrowOpen from '../../img/CountriesPopoverOpen.svg';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: 'none',
    minWidth: '350px',
    maxWidth: '350px',
    width: '100%',
    marginTop: '6px',
    borderRadius: '10px',
    maxHeight: '271px',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161)',
  },
}));

const PhoneNumber = ({
  disable = false,
  onNumberChange,
  setCountry,
  phoneNumber,
  country,
  item,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChoseCountryFromList = (country) => {
    setCountry(country);
    setAnchorEl(null);
  };

  const handleOpenPopover = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePhoneNumberChange = (event) => {
    const str = event.target.value.replace(/[^\d]/g, '');
    if (str.length > 0) {
      onNumberChange(str);
    } else {
      onNumberChange('');
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.paper}>
          <CountriesPopover
            handleChoseCountryFromList={handleChoseCountryFromList}
            country={country.id}
          />
        </div>
      </Popover>
      <div className={styles['auth-welcome_input']}>
        <div className={styles['create-membership_first-input_block-inputs']}>
          <div className={styles['phone-number-container']}>
            <Button
              disabled={disable || item.isVerified}
              onClick={handleOpenPopover}
              className={
                styles['create-membership_first-input_block-inputs_button']
              }
            >
              {country.dial_code}
              <img
                loading='lazy'
                src={anchorEl ? CountriesArrowOpen : CountriesArrowClosed}
                className={styles['countries-arrow_popover']}
                alt=''
              />
            </Button>
            <input
              disabled={disable || item.isVerified}
              type='tel'
              placeholder='00 00 00 00'
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
          </div>
          {item.isVerified !== undefined &&
            item.value.length > 0 &&
            !item.error &&
            item.isVerified && (
              <VerifiedUserIcon
                style={{ color: '#04C600', marginRight: '15px' }}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;
