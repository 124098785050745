import { Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styles from './Notifications.module.css';
import { fetch_request } from '../../../service/request';
import axios from 'axios';
import { CONSTANTS } from '../../../service/constants';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import { FormattedMessage } from 'react-intl';

const Notifications = () => {
  const user = useSelector((state) => state.user.user || {});
  const [loading, setLoading] = useState(true);
  const [check, setCheck] = useState({
    generalSms: false,
    generalEmail: false,
    messagesSms: false,
    messagesEmail: false,
  });
  const [messages, setMessages] = useState({
    id: '',
    category: 'messages',
    email: false,
    sms: false,
    user_id: '',
  });
  const [general, setGeneral] = useState({
    id: '',
    category: 'general',
    email: false,
    sms: false,
    user_id: '',
  });
  const [notificationPreferences, setNotificationPreferences] = useState([]);
  const userSlug = localStorage.getItem('user-slug');

  useEffect(() => {
    if (!isEmpty(user)) {
      setMessages({ ...messages, user_id: user.id });
      setGeneral({ ...general, user_id: user.id });
    }
  }, [user]);

  useEffect(() => {
    fetchNotificationPreferences();
  }, []);

  const findPreferenceByCategory = (category) => {
    let notificationObject = notificationPreferences.find(
      (notification) => notification.category === category
    );
    return notificationObject;
  };

  useEffect(() => {
    if (notificationPreferences) {
      let generalNotification = findPreferenceByCategory('general');
      if (generalNotification) {
        setGeneral(generalNotification);
      }

      let messagesNotification = findPreferenceByCategory('messages');
      if (messagesNotification) {
        setMessages(messagesNotification);
      }
    }
  }, [notificationPreferences]);

  const fetchNotificationPreferences = () => {
    if (!userSlug) return;
    setLoading(true);
    fetch_request(`/api/users/${userSlug}/user_notification_preferences`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then((res) => res.json())
      .then((data) => {
        let notificationPreferences = data.user_notification_preferences || [];
        setNotificationPreferences(notificationPreferences);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createNotificationPreference = (user_notification_preference) => {
    axios
      .post(
        `${CONSTANTS.baseUrl}/api/users/${userSlug}/user_notification_preferences.json`,
        { user_notification_preference },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )
      .then(({ data }) => {
        let notificationPreferences = data.user_notification_preferences || [];
        setNotificationPreferences(notificationPreferences);
        showUserNotification('angi varslingspreferanser vellykket', 'success');
      })
      .catch((error) => {
        console.log(error);
        showUserNotification('noe gikk galt, prøv igjen', 'error');
      });
  };

  const updateNotificationPreference = (id, user_notification_preference) => {
    axios
      .put(
        `${CONSTANTS.baseUrl}/api/users/${userSlug}/user_notification_preferences/${id}.json`,
        { user_notification_preference },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )
      .then(({ data }) => {
        let notificationPreferences = data.user_notification_preferences || [];
        setNotificationPreferences(notificationPreferences);
        showUserNotification('angi varslingspreferanser vellykket', 'success');
      })
      .catch((error) => {
        console.log(error);
        showUserNotification('noe gikk galt, prøv igjen', 'error');
      });
  };

  const createOrUpdatePreferenceCategory = (preference) => {
    if (preference.id) {
      updateNotificationPreference(preference.id, preference);
    } else {
      createNotificationPreference(preference);
    }
  };

  return (
    <div className={styles['notifications-page']}>
      <div className={styles['notifications-page_content']}>
        <div className={styles['notifications-page_content-header']}>
          <span className={styles['notifications-page_content-header_header']}>
            <FormattedMessage id='settings_notifications' />
          </span>
          {/* <div className={styles['notifications-page_orange-line']} /> */}
          <span
            className={styles['notifications-page_content-header_subheader']}
          >
            <FormattedMessage id='settings_notificationsSubheading' />
          </span>
        </div>
        <div className={styles['notifications-page_content-main']}>
          <span className={styles['notifications-page_content-main_header']}>
            <FormattedMessage id='settings_notifications' />
          </span>
          <span className={styles['notifications-page_content-main_subheader']}>
            <FormattedMessage id='settings_preferenceText' />
          </span>
          <div className={styles['notification-checkboxes']}>
            <div className={styles['notifications-page_checkbox']}>
              <Checkbox
                className={styles['notifications-page_checkbox-checkbox']}
                checked={messages.email}
                icon={<span className={styles['checkbox-icon']} />}
                checkedIcon={
                  <span className={styles['checkbox-checkedIcon']} />
                }
                onClick={() => {
                  const message = { ...messages, email: !messages.email };
                  createOrUpdatePreferenceCategory(message);
                  setMessages(message);
                }}
              />
              <span className={styles['notifications-page_checkbox-label']}>
                <FormattedMessage id='settings_billingEmail' />
              </span>
            </div>
            <div className={styles['notifications-page_checkbox']}>
              <Checkbox
                className={styles['notifications-page_checkbox-checkbox']}
                icon={<span className={styles['checkbox-icon']} />}
                checked={messages.sms}
                checkedIcon={
                  <span className={styles['checkbox-checkedIcon']} />
                }
                onClick={() => {
                  const message = { ...messages, sms: !messages.sms };
                  createOrUpdatePreferenceCategory(message);
                  setMessages(message);
                }}
              />
              <span className={styles['notifications-page_checkbox-label']}>
                <FormattedMessage id='settings_textMessage' />
              </span>
            </div>
          </div>
        </div>
        <div className={styles['notifications-page_content-main']}>
          <div className={styles['notifications-page_content-general']}>
            <span className={styles['notifications-page_content-main_header']}>
              <FormattedMessage id='settings_general' />
            </span>
            <span
              className={styles['notifications-page_content-main_subheader']}
            >
              <FormattedMessage id='settings_genealText' />
            </span>
            <div className={styles['notification-checkboxes']}>
              <div className={styles['notifications-page_checkbox']}>
                <Checkbox
                  className={styles['notifications-page_checkbox-checkbox']}
                  icon={<span className={styles['checkbox-icon']} />}
                  checked={general.email}
                  checkedIcon={
                    <span className={styles['checkbox-checkedIcon']} />
                  }
                  onClick={() => {
                    const message = { ...general, email: !general.email };
                    createOrUpdatePreferenceCategory(message);
                    setGeneral(message);
                  }}
                />
                <span className={styles['notifications-page_checkbox-label']}>
                  <FormattedMessage id='settings_billingEmail' />
                </span>
              </div>
              <div className={styles['notifications-page_checkbox']}>
                <Checkbox
                  className={styles['notifications-page_checkbox-checkbox']}
                  icon={<span className={styles['checkbox-icon']} />}
                  checked={general.sms}
                  checkedIcon={
                    <span className={styles['checkbox-checkedIcon']} />
                  }
                  onClick={() => {
                    const message = { ...general, sms: !general.sms };
                    createOrUpdatePreferenceCategory(message);
                    setGeneral(message);
                  }}
                />
                <span className={styles['notifications-page_checkbox-label']}>
                  <FormattedMessage id='settings_textMessage' />
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles['notification_content-button']}>
          <button
            className={styles['notification-button_cancel']}
            onClick={fetchNotificationPreferences}
          >
            Avbryt
          </button>
          <button
            className={styles['notification-button_save']}
            onClick={updateNotificationPreferences}
          >
            Lagre
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Notifications;
