import React from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import X from '../../img/X.svg';
import Warning from '../../img/orange_warning.svg';
import { Link, useLocation } from 'react-router-dom';
import './UpgradePlanModal.css';
import { useDispatch } from 'react-redux';
import {setReturnPage} from '../../redux/actions'

const useStyle = makeStyles(() => ({
  rootDialog: {
    borderRadius: '10px !important',
    '@media (max-width: 800px)': {
      width: '90%',
    },
  },
}));

const UpgradePlanModal = (props) => {
  const isOpen = props.isOpen;
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation();
  return (
    <Dialog
      open={isOpen}
      classes={{
        paper: classes.rootDialog,
      }}
    >
      <div className='upgrade-plan-prompt'>
        <img
          src={X}
          onClick={() => props.handleCloseUpgradePlansModal()}
          alt=''
          className='upgrade-plan-prompt_cancel'
        />
        <div className='upgrade-plan-prompt_body_wrapper'>
          <div className='upgrade-plan-prompt_body_subheading'>
            <img src={Warning} alt='' style={{ marginRight: '10px' }} />
            {props.title}
          </div>
          <div className='upgrade-plan-prompt_body_content'>
            <span>
              {props.body} Gå til{' '}
              <Link
                to='/pricing'
                onClick={() => dispatch(setReturnPage(`${location.pathname}`))}
              >
                våre planer
              </Link>{' '}
              og velg abonnementet som passer for deg.
            </span>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UpgradePlanModal;
