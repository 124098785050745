import React, { useEffect, useState } from 'react';
import VippsAgreement from '../../containers/NewSettings/atoms/VippsAgreements/VippsAgreements';
import Loader from '../../components/Loader/Loader';
import { request } from '../../service/request';
import styles from './VippsAgreements.module.css';
import BackIcon from '../../img/BackIcon.svg';
import BackButton from '../../components/BackButton/BackButton';

const VippsAgreements = () => {
  const [vippsAgreements, setVippsAgreements] = useState([]);
  const [showAgreements, setShowAgreements] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchVippsAgreements();
  }, []);

  const fetchVippsAgreements = async () => {
    try {
      await request('/vipps/agreements').then((response) => {
        const agreements = response.data.agreement;
        if (agreements.length > 0) {
          const activeAgreements = agreements.filter(
            (agreement) => agreement.status === 'active'
          );
          setVippsAgreements(activeAgreements);
        }
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <BackButton fallBackUrl={'/settings/payment-methods'} />
      <div className={styles['heading-container']}>
        <div className={styles['agreement-header']}>Vipps Agreements</div>
        <div className={styles['sub-header']}>
          Se og administrer avtalene dine
        </div>
      </div>
      <dir></dir>
      {vippsAgreements.length > 0 ? (
        vippsAgreements.map((agreement, index) => {
          return <VippsAgreement agreement={agreement} key={index} />;
        })
      ) : (
        <div className={styles['empty-agreement']}>
          Ingen Vipps-avtaler ble funnet
        </div>
      )}
    </div>
  );
};

export default VippsAgreements;
