import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Consciousness = () => {
  const location = useLocation();
  const items = [
    {
      title: <FormattedMessage id='about_us' />,
      url: '/about',
    },
    {
      title: <FormattedMessage id='team' />,
      url: '#',
    },
    {
      title: <FormattedMessage id='careers' />,
      url: '#',
    },
  ];
  return (
    <div className='item-container'>
      <div className='title'>
        <FormattedMessage id='consciousness' />
      </div>
      <div className='link-container'>
        {items.map((item, index) => (
          <Link
            className={location.pathname === item.url ? 'active-link' : 'link'}
            key={index}
            to={item.url}
          >
            {item.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Consciousness;
