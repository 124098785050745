import React, { useEffect, useState } from 'react';
import styles from './WorkspaceForm.module.css';
import DeleteIcon from '../../../../img/DeleteRedIcon.svg';
import EditIcon from '../../../../img/EditSmallIcon.svg';
import { MenuItem, Select, makeStyles } from '@material-ui/core';
import FamilySilhouette from '../../../../img/FamilySilhouette.svg';
import OnlyMe from '../../../../img/OnlyMe.svg';
import Everyone from '../../../../img/Everyone.svg';
import dayjs from 'dayjs';
import DatePickerValue from './DatePicker/DateYears';
import DiscardModal from '../DiscardPopupModal/DiscardModal';
import SavedPopupModal from '../SavedPopupModal/SavedPopupModal';
import { request } from '../../../../service/request';
import ConformModal from '../../../NewSettings/atoms/ConformDeleteModal';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import isEmpty from 'lodash.isempty';

const useStyle = makeStyles(() => ({
  rootIconMenu: {
    color: '#9f9f9f !important',
  },
  rootIconMenuOpen: {
    display: 'none',
  },
}));

const WorkspaceForm = ({
  workPlace,
  workplaceId,
  graveId,
  disable,
  handelWorkSpaces,
  saveWorkplaceRedux,
  workspaces,
  handelCloseForm,
  handelEditWorkPlaceId,
  handleTabChange,
  closeModal,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [workEditPlace, setEditWorkPlace] = useState({
    id: '',
    grave_id: '',
    company_name: '',
    city: '',
    position: '',
    start_year: '',
    end_year: '',
    description: '',
    publicPrivacy: 'relatives',
  });

  useEffect(() => {
    if (!isEmpty(workPlace)) {
      setEditWorkPlace({ ...workPlace });
    }
  }, [workPlace]);

  const handelEditForm = (field, value) => {
    if (field === 'start_year' || field === 'end_year') {
      const parsedDate = dayjs(value);
      let newDate = null;
      if (parsedDate.isValid()) {
        newDate = parsedDate.format('YYYY');
        setEditWorkPlace((prev) => ({
          ...prev,
          [field]: newDate,
        }));
      }
    } else {
      setEditWorkPlace((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
    setIsChanged(true);
  };

  const closeDiscardModal = () => {
    setOpenDiscardModal(false);
    setIsChanged(false);
    if (Object.keys(workPlace).length > 0) {
      setEditWorkPlace({ ...workPlace });
      handelEditWorkPlaceId(null);
    } else {
      clearWorkspace();
      handelCloseForm();
      closeModal();
      handelEditWorkPlaceId(null);
    }
  };

  const closeSaveModal = () => {
    setOpenSaveModal(false);
    handelCloseForm();
    handelEditWorkPlaceId(null);
    handleTabChange();
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const createNewWorkspace = async () => {
    var formdata = new FormData();
    if (workplaceId) {
      formdata.append(
        'grave_workplace[company_name]',
        workEditPlace.company_name
      );
      formdata.append('grave_workplace[city]', workEditPlace.city);
      formdata.append('grave_workplace[position]', workEditPlace.position);
      formdata.append('grave_workplace[start_year]', workEditPlace.start_year);
      formdata.append('grave_workplace[end_year]', workEditPlace.end_year);
      formdata.append(
        'grave_workplace[description]',
        workEditPlace.description
      );
      formdata.append(
        'grave_workplace[privacy_attributes][setting]',
        workEditPlace.publicPrivacy ? workEditPlace.publicPrivacy : 'public'
      );
      request(`/grave_workplaces/${workplaceId}`, formdata, 'put')
        .then((res) => {
          setOpenSaveModal(true);
          handelWorkSpaces(
            workspaces.map((w) =>
              w.id === workplaceId
                ? {
                    ...workEditPlace,
                  }
                : w
            )
          );
          saveWorkplaceRedux(
            workspaces.map((w) =>
              w.id === workplaceId
                ? {
                    ...workEditPlace,
                  }
                : w
            )
          );
        })
        .catch((err) => {
          showUserNotification('noe gikk galt, prøv igjen', 'warning');
        });
    } else {
      formdata.append('grave_workplace[grave_id]', graveId);
      formdata.append(
        'grave_workplace[company_name]',
        workEditPlace.company_name
      );
      formdata.append('grave_workplace[city]', workEditPlace.city);
      formdata.append('grave_workplace[position]', workEditPlace.position);
      formdata.append('grave_workplace[start_year]', workEditPlace.start_year);
      formdata.append('grave_workplace[end_year]', workEditPlace.end_year);
      formdata.append(
        'grave_workplace[description]',
        workEditPlace.description
      );
      formdata.append(
        'grave_workplace[privacy_attributes][setting]',
        workEditPlace.publicPrivacy ? workEditPlace.publicPrivacy : 'public'
      );
      request(`/grave_workplaces`, formdata, 'post')
        .then((res) => {
          setOpenSaveModal(true);
          handelWorkSpaces([
            { ...workEditPlace, id: res.data.grave_workplace_id },
            ...workspaces,
          ]);
          saveWorkplaceRedux([
            { ...workEditPlace, id: res.data.grave_workplace_id },
            ...workspaces,
          ]);
          clearWorkspace();
          setOpenSaveModal(true);
        })
        .catch((err) => {
          showUserNotification('noe gikk galt, prøv igjen', 'warning');
        });
    }
  };

  const deleteWorkspace = () => {
    if (workEditPlace.id) {
      request(`/grave_workplaces/${workEditPlace.id}`, null, 'delete')
        .then((res) => {
          const newArray = workspaces.filter(
            (work) => work.id !== workEditPlace.id
          );
          saveWorkplaceRedux(newArray);
          handelWorkSpaces(newArray);
          setOpenDeleteModal(false);
          showUserNotification('Arbeidsplassen er slettet', 'success');
        })
        .catch((err) => {
          showUserNotification(
            'Noe gikk galt ved sletting av arbeidsplass',
            'warning'
          );
        });
      setOpenDeleteModal(false);
    }
  };

  const clearWorkspace = () => {
    setEditWorkPlace({
      id: '',
      grave_id: '',
      company_name: '',
      city: '',
      position: '',
      start_year: 0,
      end_year: 0,
      description: '',
      publicPrivacy: 'relatives',
    });
  };

  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 32) {
      return true;
    } else if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
      return false;
    } else if (
      [33, 36, 37, 38, 39, 64].includes(keyCode) ||
      (keyCode >= 35 && keyCode <= 38) ||
      (keyCode >= 42 && keyCode <= 44) ||
      (keyCode >= 46 && keyCode <= 47)
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <>
      <div>
        {!disable && (
          <div className={styles['grave-actions-container']}>
            <div
              className={styles['grave-information-edit-delete-container']}
              style={{ marginBottom: '14px' }}
            >
              <div
                className={styles['action-btn']}
                onClick={() => {
                  handelEditWorkPlaceId(workEditPlace.id);
                  handelCloseForm();
                }}
              >
                <img src={EditIcon} alt='Edit' />
                <button type='button'>Redigere</button>
              </div>
              <div
                className={styles['action-btn']}
                onClick={() => {
                  setOpenDeleteModal(true);
                }}
              >
                <img src={DeleteIcon} alt='Delete' />
                <button type='button'>Slett</button>
              </div>
            </div>
          </div>
        )}
        <div className={styles['company-name-container-list']}>
          <div className={styles['input-element-list']}>
            <div className={styles['title']}>Tittel holdt</div>
            <input
              disabled={!disable}
              className={styles['inputs']}
              type='text'
              required={true}
              placeholder='angi tittel som holdes'
              value={workEditPlace?.position ? workEditPlace?.position : ''}
              onKeyPress={onKeyPress}
              onChange={(e) => {
                const inputValue = e.target.value;
                const trimmedValue = inputValue
                  .replace(/^\s+/, '')
                  .replace(/ {2,}$/, ' ');
                handelEditForm('position', trimmedValue);
              }}
            />
          </div>
          <div className={styles['input-element-list']}>
            <div className={styles['title']}>by</div>
            <input
              disabled={!disable}
              className={styles['inputs']}
              type='text'
              required={true}
              placeholder='By'
              value={workEditPlace?.city ? workEditPlace.city : ''}
              onKeyPress={onKeyPress}
              onChange={(e) => {
                const inputValue = e.target.value;
                const trimmedValue = inputValue
                  .replace(/^\s+/, '')
                  .replace(/ {2,}$/, ' ');
                handelEditForm('city', trimmedValue);
              }}
            />
          </div>
          <div className={styles['input-element-list']}>
            <div className={styles['title']}>Selskapsnavn</div>
            <input
              disabled={!disable}
              type='text'
              className={styles['inputs']}
              required={true}
              placeholder='Skriv inn firmanavn'
              value={
                workEditPlace?.company_name ? workEditPlace.company_name : ''
              }
              onChange={(e) => handelEditForm('company_name', e.target.value)}
            />
          </div>
        </div>
        <div className={styles['company-name-container']}>
          <div className={styles['input-element']}>
            <div className={styles['title']}>Start av ansettelsesåret</div>
            <DatePickerValue
              disable={!disable}
              date={
                workEditPlace?.start_year
                  ? workEditPlace.start_year.toString()
                  : ''
              }
              setDatePickerValue={(value) =>
                handelEditForm('start_year', value)
              }
            />
          </div>
          <div className={styles['input-element']}>
            <div className={styles['title']}>Slutt på ansettelsesåret</div>
            <DatePickerValue
              date={
                workEditPlace?.end_year ? workEditPlace.end_year.toString() : ''
              }
              disable={!disable}
              setDatePickerValue={(value) => handelEditForm('end_year', value)}
            />
          </div>
        </div>
        <div className={styles['workspace-content-textarea']}>
          <div className={styles['input-element']}>
            <div className={styles['title']}>Beskrivelse</div>
            <textarea
              disabled={!disable}
              value={
                workEditPlace?.description ? workEditPlace.description : ''
              }
              onChange={(e) => handelEditForm('description', e.target.value)}
              placeholder='Beskrivelse av arbeidet'
            />
          </div>
        </div>
        <div>
          <PrivacySelect
            disable={!disable}
            value={
              workEditPlace.publicPrivacy
                ? workEditPlace?.publicPrivacy
                : 'relatives'
            }
            onChange={(newValue) => handelEditForm('publicPrivacy', newValue)}
          />
        </div>
        {disable && (
          <div className={styles['grave-btn-container']}>
            <div className={styles['grave-information_content-verify']}>
              <button
                className={styles['rootDiscard']}
                onClick={() => {
                  if (isChanged) {
                    setOpenDiscardModal(true);
                  } else {
                    closeDiscardModal();
                  }
                }}
              >
                Avbryt
              </button>
              <button
                className={styles['rootVerify']}
                onClick={createNewWorkspace}
              >
                Lagre
              </button>
            </div>
          </div>
        )}
      </div>
      {openDiscardModal && (
        <DiscardModal
          open={openDiscardModal}
          handleClose={(value) => setOpenDiscardModal(value)}
          onConfirm={closeDiscardModal}
          title='workplace'
        />
      )}
      {openSaveModal && (
        <SavedPopupModal
          open={openSaveModal}
          titleKey='Workplace'
          handleClose={closeSaveModal}
        />
      )}
      {openDeleteModal && (
        <ConformModal
          title='workplace'
          open={openDeleteModal}
          handleClose={closeDeleteModal}
          deletePayment={deleteWorkspace}
        />
      )}
    </>
  );
};

const PrivacySelect = ({ value = 'public', onChange, disable }) => {
  const classes = useStyle();
  return (
    <Select
      disabled={disable}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className='profile-photo_body-content_chosen-menu'
      classes={{
        icon: classes.rootIconMenu,
        iconOpen: classes.rootIconMenuOpen,
      }}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      }}
    >
      <MenuItem value='default' disabled={true}>
        Del med
      </MenuItem>
      <MenuItem value='public'>
        <img
          loading='lazy'
          src={Everyone}
          alt='Family Silhouette'
          className='memories-header_right-icon'
        />
        Alle
      </MenuItem>
      <MenuItem value='relatives'>
        <img
          loading='lazy'
          src={FamilySilhouette}
          alt='Family Silhouette'
          className='memories-header_right-icon'
        />
        Familien
      </MenuItem>
      <MenuItem value='private'>
        <img
          loading='lazy'
          src={OnlyMe}
          alt='Family Silhouette'
          className='memories-header_right-icon'
          style={{ marginLeft: '3px', marginRight: '7px' }}
        />
        Privat
      </MenuItem>
    </Select>
  );
};

export default WorkspaceForm;
