import React, { useState } from 'react';
import styles from './MediaPhotos.module.css';
import { Modal } from '@material-ui/core';
import downloadIcon from '../../../img/DownloadIcon.svg';
import DeleteIcon from '../../../img/DeleteIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveBackgroundPhoto,
  saveListOfPhotosFromCropper,
  setCurrentGravePhotoIndex,
  setListOfTimelinesPhotos,
  goToChosenGrave,
} from '../../../redux/actions';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import { request } from '../../../service/request';
import DeleteModal from '../../NewDeceased/atoms/GravePictureGallery/DeleteModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const MediaPhotos = ({
  image,
  isAuth,
  canAddPhotos,
  images,
  category,
  user,
}) => {
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const grave = useSelector((state) => state.grave.grave);

  const canDeleteImage = () => {
    if (user && user?.id) {
      return user.id === image.user_id;
    }
  };

  const handleDownload = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const downloadLink = document.createElement('a');
      const blobUrl = window.URL.createObjectURL(blob);
      downloadLink.href = blobUrl;
      downloadLink.download = 'downloaded_image.jpg';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(blobUrl);
      showUserNotification('bilde lastet ned vellykket!', 'success');
    } catch (error) {
      console.error(error);
      showUserNotification('Nedlasting mislykket', 'error');
    }
  };
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const deletePhoto = async () => {
    try {
      await request(`/grave_images/${image.id}`, null, 'DELETE');
      const formData = new FormData();
      if (grave.cover_image_id === image.id) {
        try {
          formData.append('grave[cover_image_id]', null);
          const newInfo = await request(
            `/graves/${grave.slug}`,
            formData,
            'put'
          );
          dispatch(goToChosenGrave(newInfo.data));
        } catch (error) {
          console.log(error);
        }
      }
      const updatedImages = images.filter((photo) => photo.id !== image.id);
      if (category === 'display') {
        dispatch(saveListOfPhotosFromCropper(updatedImages));
      } else {
        dispatch(setListOfTimelinesPhotos(updatedImages));
      }
      if (images.length - 1 === 0) {
        dispatch(saveBackgroundPhoto(''));
      } else if (images.length - 1 !== 0) {
        dispatch(setCurrentGravePhotoIndex(0));
      }
      handleCloseDeleteModal();
      showUserNotification('Bildet er slettet', 'success');
    } catch (err) {
      // document.location.reload();
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      {openDeleteModal && (
        <DeleteModal
          deleteCoverPhoto={deletePhoto}
          closeModal={handleCloseDeleteModal}
          open={openDeleteModal}
        />
      )}
      <div className={styles['image-wrapper']}>
        <LazyLoadImage
          src={image.file_url}
          alt='Grave_imag'
          className={styles['grave-image']}
          effect='blur'
          onLoad={() => window.dispatchEvent(new Event('resize'))}
        />
        {isAuth && canDeleteImage() && (
          <div className={styles['delete-container']}>
            <button
              className={styles['delete-btn']}
              onClick={() => handleOpenDeleteModal()}
            >
              <img
                className={styles['delete-icon']}
                src={DeleteIcon}
                alt='Delete'
              />
            </button>
          </div>
        )}
        {isAuth && (
          <img
            className={styles['download-icon']}
            src={downloadIcon}
            alt='Download'
            onClick={() => handleDownload(image.file_url)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default MediaPhotos;
