import React from 'react';
import styles from './Subscriptions.module.css';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const SubscriptionButton = ({
  action,
  setOpenOptionsModal,
  messageKey,
  setActionKey,
}) => {
  const history = useHistory();
  return (
    <button
      className={styles['change-plan']}
      onClick={() => {
        if (action?.action) {
          action.handelAction();
        } else {
          setOpenOptionsModal(true);
          setActionKey(messageKey);
        }
      }}
    >
      {action?.action ? action.action : <FormattedMessage id='change' />}
    </button>
  );
};

export default SubscriptionButton;
