import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { NotFoundPage } from '../index';
import { connect } from 'react-redux';
import animClasses from '../Router.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PlansPage from './PlansPage';
import NewPaymentPage from '../../containers/Payment/NewPaymentPage';

const PlansRouter = ({ component: Component, isAuthorized, ...props }) => {
  const action = props.location.pathname.match(/([^*/]+)/g)[1];

  return (
    <TransitionGroup>
      <CSSTransition
        key={action}
        appear
        unmountOnExit
        classNames={{ ...animClasses }}
      >
        <Switch location={props.location}>
          <Route exact path='/pricing' component={PlansPage} />

          <Route
            path='/pricing/payment'
            render={(routeProps) =>
              isAuthorized ? (
                <NewPaymentPage {...routeProps} />
              ) : (
                <Redirect to='/' /> // Redirect if the user is not authorized
              )
            }
          />

          <Route component={NotFoundPage} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

const mapStateToProps = (state) => ({
  isAuthorized: state.auth.isAuthorized,
});

export default connect(mapStateToProps)(PlansRouter);
