import React from 'react';
import SettingsPage from '../../containers/NewSettings/SettingsPage';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Settings = () => {
  const isAuth = useSelector((state) => state.auth.isAuthorized);

  return (
    <div>
      {!isAuth && (
        <Redirect
          to={{
            pathname: '/auth/sign-in',
          }}
        />
      )}
      <SettingsPage />
    </div>
  );
};

export default Settings;
