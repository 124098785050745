import React from 'react';
import { useSelector } from 'react-redux';
import './SelectedPlan.css';
import PlanPaymentDetails from '../PlanPaymentDetails/PlanPaymentDetails';
import PlansDescription from '../PlansDescription/PlansDescription';
import { FormattedMessage } from 'react-intl';

const SelectedPlan = ({
  subscriptionPeriods,
  plans,
  chosenPlan,
  handleProductChosen,
  disable,
  handleSubscribe,
  isFreePlan,
  subscriptionPeriod,
  isCurrentPlan,
  discountsList,
}) => {
  const amount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalPrice
  );

  return (
    <div>
      <PlanPaymentDetails
        subscriptionPeriods={subscriptionPeriods}
        handleProductChosen={handleProductChosen}
        chosenPlan={chosenPlan}
        plans={plans}
        discountsList={discountsList}
        isCurrentPlan={isCurrentPlan}
      />
      <div className='subscribe-btn'>
        <button
          variant='contained'
          onClick={() => {
            handleSubscribe();
          }}
        >
          <FormattedMessage id='subscribe' />
        </button>
      </div>
      <PlansDescription
        isFreePlan={isFreePlan}
        amount={amount}
        subscriptionPeriod={subscriptionPeriod}
        chosenPlan={chosenPlan}
      />
    </div>
  );
};

export default SelectedPlan;
