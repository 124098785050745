import Header from '../Component/Header';
import Content from './Content';
import Paginator from '../Component/Paginator';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { fetch_request } from '../../../../../service/request';
import Loader from '../../../../../components/Loader/Loader';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

const Index = (props) => {
  const history = useHistory();
  const intl = useIntl();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const cemeterySlug = props.cemeterySlug;
  const [gravesCount, setGravesCount] = useState(0);
  const [cemeteryName, setCemeteryName] = useState('');
  const [gravesList, setGravesList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchInputValue]);

  useEffect(() => {
    if (cemeterySlug) {
      fetch_request(`/api/graves_count?cemetery_slug=${cemeterySlug}`)
        .then((response) => response.json())
        .then((data) => {
          setGravesCount(data.count);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    if (cemeterySlug) {
      fetch_request(`/api/graveyards/${cemeterySlug}`)
        .then((response) => response.json())
        .then((data) => {
          setCemeteryName(data.name);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    // if (searchInputValue.trim() !== '')
    //   history.push(`/search?find-grave=${searchInputValue}`);
    fetchGraves();
  }, [searchInputValue, currentPage]);

  const fetchGraves = () => {
    if (cemeterySlug) {
      setLoading(true);
      fetch_request(
        `/api/search_graves?input=${searchInputValue}&page=${currentPage}&cemetery_slug=${cemeterySlug}`
      )
        .then((response) => response.json())
        .then((data) => {
          setGravesList(data.graves);
          setTotalPages(data.total_pages);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const Description = () => {
    return (
      <span className='search-graves_none-text'>
        <FormattedMessage id='it_is' />{' '}
        <span>
          <FormattedMessage
            id='find_grave_count'
            values={{ count: gravesCount }}
          />
        </span>{' '}
        <FormattedMessage id='grave_description' />
      </span>
    );
  };
  return (
    <div className='municipality-index'>
      <Header
        title={<FormattedMessage id='graves' />}
        setSearchInputValue={setSearchInputValue}
        placeholder={intl.formatMessage({ id: 'search_grave' })}
        description={<Description />}
      />
      {gravesCount !== 0 && (
        <Paginator
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      )}
      {!loading ? (
        <Content searchInputValue={searchInputValue} list={gravesList} />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Index;
