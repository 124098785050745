import React from 'react';
import styles from './CreateMembership.module.css';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

const BottomMessage = ({ url, messageKey, textKey }) => {
  const history = useHistory();
  return (
    <div className={styles['login-container']}>
      <FormattedMessage id={textKey} />
      <span className={styles['login-here']} onClick={() => history.push(url)}>
        <FormattedMessage id={messageKey} />
      </span>
    </div>
  );
};

export default BottomMessage;
