import React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import styles from '../SubscriptionMember/SubscriptionMembers.module.css';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import { CONSTANTS } from '../../../../../service/constants';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));
const DeleteSubscriptionMembers = ({
  members,
  invitations,
  currentSubscriptionData,
  updateSubscription,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const handelRemoveMember = (id) => {
    if (id) {
      fetch(`${CONSTANTS.baseUrl}/api/subscription_members/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          const newArray = currentSubscriptionData.subscription_members.filter(
            (member) => member.id !== id
          );
          updateSubscription('members', newArray);
          showUserNotification(
            intl.formatMessage({ id: 'members_removed' }),
            'success'
          );
        })
        .catch((err) => {
          showUserNotification(
            intl.formatMessage({ id: 'something_wrong' }),
            'error'
          );
        });
    }
  };

  const handelRemoveInvitation = (id) => {
    if (id) {
      fetch(`${CONSTANTS.baseUrl}/api/subscription_invitations/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          console.log('delete-invitation');
          const newArray =
            currentSubscriptionData.subscription_invitations.filter(
              (invitation) => invitation.invitation_id !== id
            );
          updateSubscription('invitation', newArray);
          showUserNotification(
            intl.formatMessage({ id: 'invitation_removed' }),
            'success'
          );
        })
        .catch((error) => {
          console.log('error');
          showUserNotification(
            intl.formatMessage({ id: 'something_wrong' }),
            'error'
          );
        });
    }
  };

  return (
    <div style={{ marginTop: 24 }}>
      <div className={styles['subscription-members']}>
        <div className={styles['member-heading']}>
          <FormattedMessage id='active_members' />
        </div>
        <div className={styles['member-sub-heading']}>
          <FormattedMessage id='active_members_message' />
        </div>
        {members.length > 0 ? (
          members.map((member, index) => {
            return (
              <div className={styles['member-container']} key={index}>
                <div className={styles['avatar-wrapper']}>
                  <Avatar src={member?.user_avatar} className={classes.large} />
                  <div className={styles['user-name']}>{member?.full_name}</div>
                </div>
                <button
                  className={styles['remove-button']}
                  onClick={() => handelRemoveMember(member.id)}
                >
                  <FormattedMessage id='settings_remove' />
                </button>
              </div>
            );
          })
        ) : (
          <div className={styles['empty-message']}>
            <FormattedMessage id='active_members_empty_message' />
          </div>
        )}
      </div>
      <div className={styles['subscription-invitations']}>
        <div className={styles['member-heading']}>
          <FormattedMessage id='active_invitations' />
        </div>
        <div className={styles['member-sub-heading']}>
          <FormattedMessage id='active_invitations_message' />
        </div>
        {invitations.length > 0 ? (
          invitations.map((invite, index) => {
            return (
              <div className={styles['member-container']} key={index}>
                <div className={styles['invitation-wrapper']}>
                  <div className={styles['user-phone-number']}>
                    {invite?.phone_number}
                  </div>
                  <div className={styles['user-name']}>{invite?.full_name}</div>
                </div>
                <button
                  className={styles['remove-button']}
                  onClick={() => handelRemoveInvitation(invite.invitation_id)}
                >
                  <FormattedMessage id='settings_remove' />
                </button>
              </div>
            );
          })
        ) : (
          <div className={styles['empty-message']}>
            <FormattedMessage id='active_invitations_empty_message' />
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteSubscriptionMembers;
