import React, { useEffect, useState } from 'react';
import styles from './Memories.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCommentOnToggle,
  addMemory,
  disableMemoryPublishBtn,
  setReturnPage,
  setSubscription,
  setUploadingMemory,
} from '../../../../redux/actions';
import CircularProgress from '@mui/material/CircularProgress';
import { request } from '../../../../service/request';
import CommentOnToggle from '../CommentTextArea/CommentOnToggle';
import MemoriesPost from '../MemoriesPosts/MemoriesPost';
import MemoriesHeader from '../MemoriesHeader/MemoriesHeader';
import { TextareaAutosize } from '@material-ui/core';
import MemoriesButtonsGroup from '../MemoriesButtonsGroup/MemoriesButtonsGroup';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { Link, useLocation } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

const Memories = (props) => {
  const location = useLocation();
  const [menuAnchorElem, setMenuAnchorElem] = React.useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
    handleCloseMenu();
  };

  const bigAmountOfPhotos = () =>
    showUserNotification('Velg ferre en 10 bilder og prøv igjen', 'warning');
  const [text, setText] = useState('');
  const [privacy, setPrivacy] = useState('relatives');

  const {
    openVideoModalMobile,
    openMusicModalMobile,
    openVideoModal,
    openMusicModal,
    openImageModal,
    isAuth,
    is_relative,
    user,
  } = props;

  const graveName = useSelector((state) => state.grave.grave.name);
  const graveId = useSelector((state) => state.grave.grave.id);
  const currentComment = useSelector((state) => state.grave.commentOnToggle);
  const memories = useSelector((state) => state.grave.memories);
  const dispatch = useDispatch();
  const canAddMemory = useSelector(
    (state) => state.user.activeSubscription?.can_add_memories
  );
  const isPostButtonDisabled = useSelector((state) => state.grave.disable);
  const isUploading = useSelector((state) => state.grave.uploading);

  useEffect(() => {
    let { photos, video, music, embeddedPhotos, embeddedVideo } =
      currentComment;
    if (
      !isEmpty(text) ||
      !isEmpty(photos) ||
      !isEmpty(embeddedPhotos) ||
      !isEmpty(embeddedVideo) ||
      !isEmpty(video) ||
      !isEmpty(music)
    ) {
      dispatch(disableMemoryPublishBtn(false));
    } else {
      dispatch(disableMemoryPublishBtn(true));
    }
  }, [currentComment, text]);

  const addPhoto = (data) => {
    const photosOnToggle = currentComment.photos ? currentComment.photos : [];
    if (photosOnToggle.length + data.length <= 10) {
      dispatch(
        addCommentOnToggle({
          ...currentComment,
          photos: [...photosOnToggle, ...data],
        })
      );
    } else {
      bigAmountOfPhotos();
    }
  };

  const deletePhoto = (id) => {
    if (currentComment.photos && currentComment.photos.length > 0) {
      const photo = currentComment.photos.find((photo) => photo.id === id);
      if (photo) {
        const newArray = currentComment.photos.filter(
          (image) => image.id !== photo.id
        );
        dispatch(addCommentOnToggle({ ...currentComment, photos: newArray }));
      }
    }
    if (
      currentComment.embeddedPhotos &&
      currentComment.embeddedPhotos.length > 0
    ) {
      const photo = currentComment.embeddedPhotos.find(
        (photo) => photo.id === id
      );
      if (photo) {
        const newArray = currentComment.embeddedPhotos.filter(
          (image) => image.id !== photo.id
        );
        dispatch(
          addCommentOnToggle({ ...currentComment, embeddedPhotos: newArray })
        );
      }
    }
  };

  const handleCloseMenu = () => {
    setMenuAnchorElem(null);
  };

  const addPostToMemories = async () => {
    try {
      if (
        text !== '' ||
        currentComment.photos ||
        currentComment.embeddedPhotos ||
        currentComment.video ||
        currentComment.embeddedVideo ||
        currentComment.music
      ) {
        dispatch(disableMemoryPublishBtn(true));
        dispatch(setUploadingMemory(true));
        const formData = new FormData();
        formData.append('memory[grave_id]', graveId);
        formData.append('memory[contents_attributes][][description]', text);
        formData.append('memory[privacy_attributes][setting]', privacy);
        const response = await request(`/memories`, formData, 'post');

        await uploadFiles(response.data.memory_id);

        const newData = await request(`/memories?memory[grave_id]=${graveId}`);
        if (newData.data.memories.length > 0) {
          dispatch(addMemory(newData.data.memories));
          dispatch(setSubscription(newData.data.subscription));
          dispatch(setUploadingMemory(false));
        }

        dispatch(addCommentOnToggle({}));
        setText('');
        showUserNotification('Sendt til og lagret på graven', 'success');
      } else {
        showUserNotification(
          'Legg til innhold, for å sende og lagre det på minnetavlen',
          'warning'
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const uploadFiles = async (memoryId) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    try {
      if (currentComment.photos && currentComment.photos.length > 0) {
        currentComment.photos.map(async (photo) => {
          const formData = new FormData();
          formData.append('memory[contents_attributes][][category]', 'image');
          formData.append('memory[contents_attributes][][title]', photo.title);
          formData.append(
            'memory[contents_attributes][][file]',
            photo.file,
            `${photo.file.name}`
          );
          request(`/memories/${memoryId}`, formData, 'put', headers).then(
            async (res) => {
              const newData = await request(
                `/memories?memory[grave_id]=${graveId}`
              );
              if (newData.data.memories.length > 0) {
                dispatch(addMemory(newData.data.memories));
              }
            }
          );
        });
      }
      if (
        currentComment.embeddedPhotos &&
        currentComment.embeddedPhotos.length > 0
      ) {
        currentComment.embeddedPhotos.map(async (photo) => {
          // const formData = new FormData();
          const formData = {
            memory: {
              contents_attributes: [
                {
                  category: 'image',
                  file: photo.file,
                  title: photo.title,
                },
              ],
            },
          };

          // formData.append('memory[contents_attributes][][category]', 'image');
          // formData.append(
          //   'memory[contents_attributes][][file]',
          //   photo.file,
          //   `image`
          // );
          request(`/memories/${memoryId}`, formData, 'put', headers).then(
            async (res) => {
              const newData = await request(
                `/memories?memory[grave_id]=${graveId}`
              );
              if (newData.data.memories.length > 0) {
                dispatch(addMemory(newData.data.memories));
              }
            }
          );
        });
      }
      if (currentComment.embeddedVideo) {
        // await request(`/memories?memory[grave_id]=${graveId}`);
        const formData = new FormData();
        formData.append('memory[contents_attributes][][category]', 'video');
        formData.append(
          'memory[contents_attributes][][title]',
          currentComment.embeddedVideo.title
        );
        formData.append(
          'memory[contents_attributes][][file]',
          currentComment.embeddedVideo.video
        );
        request(`/memories/${memoryId}`, formData, 'put', headers).then(
          async (res) => {
            const newData = await request(
              `/memories?memory[grave_id]=${graveId}`
            );
            if (newData.data.memories.length > 0) {
              dispatch(addMemory(newData.data.memories));
            }
          }
        );
      }
      if (currentComment.video) {
        // await request(`/memories?memory[grave_id]=${graveId}`);
        const formData = new FormData();
        formData.append('memory[contents_attributes][][category]', 'video');
        formData.append(
          'memory[contents_attributes][][title]',
          currentComment.video.title
        );
        formData.append(
          'memory[contents_attributes][][file]',
          currentComment.video.file
        );
        request(`/memories/${memoryId}`, formData, 'put', headers).then(
          async (res) => {
            const newData = await request(
              `/memories?memory[grave_id]=${graveId}`
            );
            if (newData.data.memories.length > 0) {
              dispatch(addMemory(newData.data.memories));
            }
          }
        );
      }
      if (currentComment.music && currentComment.music.length > 0) {
        currentComment.music.map(async (music) => {
          const formData = new FormData();
          formData.append('memory[contents_attributes][][category]', 'audio');
          formData.append(
            'memory[contents_attributes][][file]',
            music.file,
            `${music.file?.name}`
          );
          request(`/memories/${memoryId}`, formData, 'put', headers).then(
            async (res) => {
              const newData = await request(
                `/memories?memory[grave_id]=${graveId}`
              );
              if (newData.data?.memories?.length > 0) {
                dispatch(addMemory(newData.data.memories));
              }
            }
          );
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return isAuth ? (
    <>
      <div className={styles['memories-section']}>
        {canAddMemory ? (
          is_relative && (
            <div className={styles['new-memory-section']}>
              <MemoriesHeader
                user={user}
                setPrivacy={setPrivacy}
                privacy={privacy}
              />
              <div className={styles['memory-text-area-wrapper']}>
                <TextareaAutosize
                  value={text}
                  onChange={(e) => {
                    e.target.value[0] !== ' ' && setText(e.target.value);
                  }}
                  placeholder={`Skriv på graven til ${graveName}`}
                  className={styles['memories-section_text-area']}
                />
              </div>
              {currentComment && (
                <CommentOnToggle
                  deletePhoto={deletePhoto}
                  isUploading={isUploading}
                />
              )}
              <MemoriesButtonsGroup
                openVideoModalMobile={openVideoModalMobile}
                privacy={privacy}
                openMusicModalMobile={openMusicModalMobile}
                openVideoModal={openVideoModal}
                openMusicModal={openMusicModal}
                openImageModal={openImageModal}
                addPhoto={addPhoto}
                addPostToMemories={addPostToMemories}
                isPostButtonDisabled={isPostButtonDisabled}
                isUploading={isUploading}
              />
              {isUploading && (
                <div className={styles['loading-container']}>
                  <div className={styles['loader']}>
                    <CircularProgress style={{ color: '#fea65a' }} />
                    <span>Sender...</span>
                  </div>
                </div>
              )}
            </div>
          )
        ) : (
          <div className={styles['new-memory-section']}>
            <div className={styles['upgrade-memory_plan-prompt_body_wrapper']}>
              <div
                className={styles['upgrade-memory_plan-prompt_body_subheading']}
              >
                Oppgrader abonnementet ditt for å sende og lagre ubegrenset
                innlegg på gravene.
              </div>
              <div className={styles['upgrade-plan-prompt_body_content']}>
                <span>
                  Gå til{' '}
                  <Link
                    to='/pricing'
                    onClick={() =>
                      dispatch(setReturnPage(`${location.pathname}`))
                    }
                  >
                    planene
                  </Link>{' '}
                  for å velge abonnementet som passer deg.
                </span>
              </div>
            </div>
          </div>
        )}
        {/* {is_relative && (
          <div className="new-memory-section">
            <MemoriesHeader
              user={user}
              setPrivacy={setPrivacy}
              privacy={privacy}
            />
            <div className="memory-text-area-wrapper">
              <TextareaAutosize
                value={text}
                onChange={(e) => {
                  e.target.value[0] !== " " && setText(e.target.value);
                }}
                placeholder={`Skriv på Minnetavlen til ${graveName}`}
                className="memories-section_text-area"
              />
            </div>
            {currentComment && (
              <CommentOnToggle photos={photos} deletePhoto={deletePhoto} />
            )}
            <MemoriesButtonsGroup
              openVideoModalMobile={openVideoModalMobile}
              privacy={privacy}
              openMusicModalMobile={openMusicModalMobile}
              openVideoModal={openVideoModal}
              openMusicModal={openMusicModal}
              addPhoto={addPhoto}
              addPostToMemories={addPostToMemories}
              isPostButtonDisabled={isPostButtonDisabled}
            />
          </div>
        )} */}

        <div className={styles['memories-section_comments']}>
          {memories?.map((mem, index) => (
            <MemoriesPost
              handleOpenDeleteModal={handleOpenDeleteModal}
              key={index}
              post={mem}
              isRelative={is_relative}
            />
          ))}
        </div>
        {memories?.length === 0 && (
          <span>Ivareta historien og møt merkedager her på graven</span>
        )}
        {memories?.length === 0 && (
          <div className={styles['family-image']}></div>
        )}
      </div>
    </>
  ) : (
    <div />
  );
};

export default Memories;
