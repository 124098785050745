import React, { useEffect, useState } from 'react';
import Heading from './Heading';
import styles from './Plans.module.css';
import Description from './Description';
import Price from './Price';
import UserCount from './UserCount';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPaymentMethods,
  setSelectedPlan,
  setSubscriptionCount,
  setReturnPage,
  setPaymentMethod,
  setShowCardMethod,
  setSelectedCardMethod,
  setBillingInformation,
  setSelectedVippsMethod,
  setShowVippsPaymentMethod,
  setVippsPaymentMethods,
  setVippsPhoneNumber,
  setSubscriptionPeriod,
  setSubscriptionTotalPrice,
  setSubscriptionDiscount,
} from '../../../../redux/actions';
import SubscriptionButton from './SubscriptionButton';
import { FormattedMessage } from 'react-intl';
import { planKey } from '../../../../service/PlanKey';
import Features from './Features';
import { ProductKeys, CONSTANTS } from '../../../../service/constants';
import { useHistory } from 'react-router-dom';
import DeleteInvitationsModal from './DeleteInvitationsModal/DeleteInvitationsModal';
import CustomModal from '../../../../components/CustomModal';
import PlanLength from '../../../Payment/atoms/PlanPaymentDetails/PlanLength';
import ProcessModal from '../../../Payment/atoms/ProcessModal';
import ErrorModal from '../../../Payment/atoms/ErrorModal/ErrorModal';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { updateSubscription } from '../../../../service/updateSubscription';
import MembersCounter from '../../../Payment/atoms/PlanPaymentDetails/MembersCounter';
import { getSubscriptionDiscount } from '../../../../service/Utils';
import PlanDetailsRow from '../../../Payment/atoms/PlanPaymentDetails/PlanDetailsRow';
import { SubscriptionPeriodKey } from '../../../../service/constants';
import isEmpty from 'lodash.isempty';
import DiscountInfo from '../../../../components/DiscountsInfo/DiscountsInfo';

const Plan = ({
  plan,
  currentSubscription,
  updateSubscriptionMember,
  subscriptionPeriods,
  paymentMethod,
  setOpenOptionsModal,
  fetchSubscriptionData,
  discountsList,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const isCurrentPlan = plan.key === currentSubscription.plan_key;
  const [members, setMembers] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [discount, setDiscount] = useState({});
  const [selectedPeriod, setSelectedPeriod] = useState({});

  useEffect(() => {
    if (discountsList.length > 0) {
      const defaultDiscount = discountsList.find(
        (discount) => discount.user_count === 1
      );
      setDiscount(defaultDiscount);
    }
  }, [discountsList]);

  useEffect(() => {
    if (subscriptionPeriods.length > 0) {
      let defaultPeriod = null;
      defaultPeriod = subscriptionPeriods.find(
        (period) => period.key === SubscriptionPeriodKey.annually
      );
      defaultPeriod = defaultPeriod ? defaultPeriod : subscriptionPeriods[0];
      setSelectedPeriod(defaultPeriod);
    }
  }, [subscriptionPeriods]);

  const handleShowModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const setAdditionalMembers = (value) => {
    setMembers(value);
    dispatch(setSubscriptionCount(value));
    const total = plan.price * value;
    dispatch(setSubscriptionTotalPrice(total));
  };

  const findMember = () => {
    const invitations = currentSubscription.subscription_invitations;
    const members = currentSubscription.subscription_members;

    const isMembersArray = Array.isArray(members) && members.length > 0;
    const isInvitationsArray =
      Array.isArray(invitations) && invitations.length > 0;

    if (isMembersArray) {
      const currentUser = members.filter(
        (member) => member.user_id !== currentSubscription.user_id
      );
      return currentUser.length > 0 || isInvitationsArray;
    } else {
      return false;
    }
  };

  const handleServerResponse = () => {
    fetchSubscriptionData();
    setOpenOptionsModal(false);
    dispatch(setShowCardMethod(false));
    dispatch(setShowVippsPaymentMethod(false));
    dispatch(setBillingInformation({}));
    dispatch(setPaymentMethod({}));
    dispatch(setSelectedVippsMethod({}));
    dispatch(setVippsPaymentMethods([]));
    dispatch(setPaymentMethods([]));
    dispatch(setSelectedCardMethod('vipps'));
    dispatch(setReturnPage(null));
    dispatch(setVippsPhoneNumber(''));
    dispatch(setSubscriptionPeriod({}));
  };

  const handleUpdateSubscription = async () => {
    handleShowModal();
    const url = `${CONSTANTS.baseUrl}/api/subscriptions/${currentSubscription.id}.json`;
    const token = localStorage.getItem('token');
    const params = {
      payment_method_id: paymentMethod.id,
      user_count: parseInt(members),
      product_id: plan.id,
      period: selectedPeriod.key,
    };

    const result = await updateSubscription(url, token, params);

    if (result.success) {
      handleCloseModal();
      handleServerResponse();
    } else {
      showUserNotification(result.error, 'error');
      handleCloseModal();
      setErrorMessage(result.error);
      setOpenErrorModal(true);
    }
  };

  const handlePlanSelect = () => {
    const isFreePlan = currentSubscription.plan_key === ProductKeys.free;
    if (isAuth && isFreePlan) {
      dispatch(setSubscriptionDiscount(discount));
      dispatch(setSubscriptionPeriod(selectedPeriod));
      history.push('/pricing/payment?payment_method_type=vipps');
    } else if (isAuth && !isFreePlan) {
      handleUpdateSubscription();
    } else {
      history.push('/registration');
    }
  };

  const handelSubscriptionButton = () => {
    if (findMember()) {
      dispatch(setSelectedPlan(plan));
      setOpenDeleteModal(true);
    } else {
      dispatch(setSelectedPlan(plan));
      handlePlanSelect();
    }
  };

  const getDeleteModalTitle = () => {
    return currentSubscription.plan_price > plan.price ? (
      <FormattedMessage id='downgrade_plan' />
    ) : (
      <FormattedMessage id='upgrade_plan' />
    );
  };

  const getSubscriptionMessageKey = () => {
    if (!isEmpty(currentSubscription) || !currentSubscription.plan_key) {
      return 'get_started';
    }

    const { plan_key: currentPlanKey } = currentSubscription;
    const { key: planKey } = plan;

    if (currentPlanKey === ProductKeys.conscious) {
      if (planKey === ProductKeys.free) {
        return 'downgrade_to';
      }
      if (planKey === ProductKeys.conscious_plus) {
        return 'upgrade_to';
      }
    }

    if (currentPlanKey === ProductKeys.free) {
      if (planKey === ProductKeys.conscious) {
        return 'upgrade_to';
      }
      if (planKey === ProductKeys.conscious_plus) {
        return 'upgrade_to';
      }
    }

    if (currentPlanKey === ProductKeys.conscious_plus) {
      if (planKey === ProductKeys.conscious) {
        return 'downgrade_to';
      }
      if (planKey === ProductKeys.free) {
        return 'downgrade_to';
      }
    }
    return 'get_started';
  };

  const getSubscriptionButtonTitle = () => {
    return (
      <FormattedMessage
        id={getSubscriptionMessageKey()}
        values={{
          planName: <FormattedMessage id={planKey(plan.key)} />,
        }}
        disabled={isCurrentPlan}
      />
    );
  };

  const addMembers = () => {
    setAdditionalMembers(members + 1);
    const discount = getSubscriptionDiscount(discountsList, members + 1);
    setDiscount(discount);
  };

  const removeMembers = () => {
    if (members > 1) {
      setAdditionalMembers(members - 1);
      const discount = getSubscriptionDiscount(discountsList, members - 1);
      setDiscount(discount);
    }
  };

  const getSubtotal = () => {
    return plan.price * members * selectedPeriod.length;
  };

  const getDiscountPrice = () => {
    return getSubtotal() * (discount.discount_percentage / 100);
  };

  const getTotalAmount = () => {
    return getSubtotal() - getDiscountPrice();
  };

  const getSubscriptionAmount = () => {
    return (
      <>
        {`NOK ${currentSubscription.price} / `}
        {!isEmpty(currentSubscription) && currentSubscription.period ? (
          <FormattedMessage id={currentSubscription.period} />
        ) : (
          <FormattedMessage id='monthly' />
        )}
      </>
    );
  };

  const onSelectPeriod = (value) => {
    setSelectedPeriod(value);
  };

  return (
    <div
      className={
        isCurrentPlan
          ? `${styles['current']} ${styles['plan-details']}`
          : styles['plan-details']
      }
    >
      <Heading plan={plan} isCurrentPlan={isCurrentPlan} />
      <Description plan={plan} />
      <Price plan={plan} />
      {isCurrentPlan ? (
        <React.Fragment>
          <UserCount
            labelKey='current_members'
            value={currentSubscription.user_count}
          />
          <UserCount labelKey={'amount'} value={getSubscriptionAmount()} />
        </React.Fragment>
      ) : (
        plan.key !== ProductKeys.free && (
          <React.Fragment>
            <MembersCounter
              messageKey={'added_members'}
              addMembers={addMembers}
              removeMembers={removeMembers}
              count={members}
            />
            <PlanLength
              subscriptionPeriods={subscriptionPeriods}
              onSelect={onSelectPeriod}
              selectedPeriod={selectedPeriod}
            />
            <PlanDetailsRow
              messageKey='sub_total'
              value={`NOK ${getSubtotal().toFixed(2)}`}
            />
            <PlanDetailsRow
              style={{
                color: '#ea9449',
              }}
              messageKey='discount'
              value={
                <DiscountInfo
                  discountsList={discountsList}
                  discount={getDiscountPrice()}
                />
              }
            />
            <PlanDetailsRow
              messageKey='total'
              value={`NOK ${getTotalAmount().toFixed(2)}`}
            />
          </React.Fragment>
        )
      )}
      {isCurrentPlan ? (
        <SubscriptionButton
          children={<FormattedMessage id='your_current_plan' />}
          disabled={isCurrentPlan}
          onClickHandel={handelSubscriptionButton}
        />
      ) : (
        <SubscriptionButton
          onClickHandel={handelSubscriptionButton}
          disabled={plan.key === ProductKeys.free && isAuth}
          children={getSubscriptionButtonTitle()}
        />
      )}
      <Features plan={plan} />
      {openDeleteModal && (
        <CustomModal
          isOpen={openDeleteModal}
          children={
            <DeleteInvitationsModal
              currentSubscriptionData={currentSubscription}
              updateSubscription={updateSubscriptionMember}
              onClose={() => setOpenDeleteModal(false)}
            />
          }
          closeModal={() => setOpenDeleteModal(false)}
          title={getDeleteModalTitle()}
        />
      )}
      {openModal && (
        <ProcessModal open={openModal} messageKey={'payment_progress'} />
      )}
      {openErrorModal && (
        <ErrorModal
          message={errorMessage}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
        />
      )}
    </div>
  );
};

export default Plan;
