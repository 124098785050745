import React from 'react';
import { Modal, makeStyles } from '@material-ui/core';
import styles from './ConformDeleteModal.module.css';
import X from '../../../img/X.svg';
import { FormattedMessage } from 'react-intl';
const appearance = {
  theme: 'night',
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    padding: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'maxContent',
    backgroundColor: 'white',
    borderRadius: '10px',
    outline: 'none',
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const ConformModal = ({ open, handleClose, deletePayment, title }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <img
          style={{ cursor: 'pointer' }}
          loading='lazy'
          src={X}
          alt='x'
          onClick={handleClose}
          className={styles['image']}
        />
        <div className={styles['message']}>
          <FormattedMessage id='delete_modalHeading' />{' '}
          <FormattedMessage id={title} />
        </div>
        <div className={styles['footer']}>
          <button
            className={styles['rootDiscard']}
            onClick={() => handleClose()}
          >
            <FormattedMessage id='no' />
          </button>
          <button
            className={styles['rootVerify']}
            onClick={() => deletePayment()}
          >
            <FormattedMessage id='yes' />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConformModal;
