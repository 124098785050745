import React, { useState, useEffect } from 'react';
import { fetch_request } from '../../../service/request';
import HomepageFindGraveFifth from './FindGrave/HomepageFindGraveFifth';
import HomepageFindGraveFirst from './FindGrave/HomepageFindGraveFirst';
import HomepageFindGraveFourth from './FindGrave/HomepageFindGraveFourth';
import HomepageFindGraveSecond from './FindGrave/HomepageFindGraveSecond';
// import HomepageFindGraveSeventh from './FindGrave/HomepageFindGraveSeventh';
// import HomepageFindGraveSixth from './FindGrave/HomepageFindGraveSixth';
import HomepageFindGraveThird from './FindGrave/HomepageFindGraveThird';
import './HomepageFindGrave.css';
import { useParams } from 'react-router-dom';

const HomepageFindGrave = (props) => {
  let { municipality, cemetery } = useParams();
  const [municipalityName, setMunicipalityName] = useState('');
  const [cemeteryName, setCemeteryName] = useState('');

  useEffect(() => {
    if (municipality) {
      fetch_request(`/api/municipalities/${municipality}`)
        .then((response) => response.json())
        .then((data) => {
          setMunicipalityName(data.name);
        })
        .catch((err) => console.log(err));
    }
  }, [municipality]);

  useEffect(() => {
    if (cemetery) {
      fetch_request(`/api/graveyards/${cemetery}`)
        .then((response) => response.json())
        .then((data) => {
          setCemeteryName(data.name);
        })
        .catch((err) => console.log(err));
    }
  }, [cemetery]);

  return (
    <div className='home-fine-grave-page'>
      <div className='homepage-findgrave'>
        <HomepageFindGraveFirst
          municipalityName={municipalityName}
          cemeteryName={cemeteryName}
        />
        <HomepageFindGraveSecond />
        <HomepageFindGraveThird location={props.location} />
        <HomepageFindGraveFourth />
        <HomepageFindGraveFifth />
        {/* <HomepageFindGraveSixth/> */}
        {/* <HomepageFindGraveSeventh/> */}
      </div>
    </div>
  );
};

export default HomepageFindGrave;
