import React, { useState } from 'react';
import './HomepageFindGraveFirst.css';
import MainImage from '../../../../img/searchpage1.png';
import { Button } from '@material-ui/core';
import { useHistory, useParams, Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

const HomepageFindGraveFirst = (props) => {
  const [searchVal, setSearchVal] = useState('');
  const history = useHistory();
  const intl = useIntl();
  let { municipality, cemetery } = useParams();

  if (municipality && !cemetery) {
    return (
      <div className='header-submain_wrapper'>
        <span className='header-submain'>
          {
            <Link to='/finn-en-grav'>
              <FormattedMessage id='find_grave' />
            </Link>
          }{' '}
          → {props.municipalityName}
        </span>
      </div>
    );
  }

  if (municipality && cemetery) {
    return (
      <div className='header-submain_wrapper'>
        <span className='header-submain'>
          {
            <Link to='/finn-en-grav'>
              <FormattedMessage id='find_grave' />
            </Link>
          }{' '}
          →{' '}
          {
            <Link to={`/finn-en-grav/${municipality}`}>
              {props.municipalityName}
            </Link>
          }{' '}
          → {props.cemeteryName}
        </span>
      </div>
    );
  }

  return (
    <div className='homepage-findgrave_first'>
      <div className='homepage-findgrave_first-content'>
        <div className='homepage-findgrave_first-content_header'>
          <span className='homepage-findgrave_first-content_header-main'>
            <FormattedMessage id='find_grave_norway' />
          </span>
          <span className='homepage-findgrave_first-content_header-submain'>
            <FormattedMessage id='search_grave' /> &#8594;{' '}
            <FormattedMessage id='find_grave' />
          </span>
          <div className='homepage-findgrave_first-content_header-input'>
            <input
              type='text'
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
              placeholder={intl.formatMessage({ id: 'search_grave' })}
            />
            <Button
              className='homepage-findgrave_first-content_header-input_button'
              onClick={() => history.push(`/graves?find-grave=${searchVal}`)}
            >
              <FormattedMessage id='find_grave' />
            </Button>
          </div>
          <span className='homepage-findgrave_first-content_header-submain added_here'>
            <FormattedMessage id='or' />{' '}
            <Link to='/ny-grav'>
              <FormattedMessage id='create_netgrave' />
            </Link>
          </span>
        </div>
        <img
          loading='lazy'
          src={MainImage}
          alt='MainImage'
          className='homepage-findgrave_first-content_bg'
        />
      </div>
    </div>
  );
};

export default HomepageFindGraveFirst;
