import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './CreateMembership.module.css';
import { useHistory } from 'react-router-dom';
import GroupButtons from './GroupButtons';

const SuccessMessage = ({ name, email, setHidePage }) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const renderMessage = (id, values = {}) => (
    <div className={styles['message']}>
      <FormattedMessage id={id} values={values} />
    </div>
  );

  return (
    <div className={styles['message-container']}>
      <div className={styles['message-header']}>
        <FormattedMessage id='signup_message_heading' />
      </div>
      <div className={styles['message-subheading']}>
        <FormattedMessage id='signup_message_subheading' values={{ name }} />
      </div>
      {renderMessage('signup_first_message', { email })}
      {renderMessage('signup_second_message')}
      {renderMessage('signup_third_message')}
      {renderMessage('signup_forth_message')}
      {renderMessage('signup_fifth_message')}
      <div className={styles['message-bottom']}>
        <FormattedMessage id='cheers' />
      </div>
      <div className={styles['message-team']}>
        <FormattedMessage id='the_team' />
      </div>
      <GroupButtons
        backKey='auth_back'
        nextKey='auth_login_here'
        onBack={() => setHidePage(false)}
        onNext={() => history.push('/auth/sign-in')}
      />
    </div>
  );
};

export default SuccessMessage;
