import React from 'react';
import './Grave.css';
import PopoverModal from './PopoverModal';
import GraveButton from './GraveButton';
import GraveImage from './GraveImage';
import GraveCategory from './GraveCategory';
import GraveTooltip from './GraveTooltip';
import GraveDate from './GraveDate';
import GraveLocation from './GraveLocation';

const Grave = (props) => {
  const { grave } = props;
  const [openPhysicalPopover, setOpenPhysicalPopover] = React.useState(null);
  const open = Boolean(openPhysicalPopover);
  const handlePopoverOpen = (event) => {
    setOpenPhysicalPopover(event.currentTarget);
  };
  const grave_images = grave.grave_images;
  const grave_display_image =
    grave_images[Math.floor(Math.random() * grave_images.length)];

  const handlePopoverClose = () => {
    setOpenPhysicalPopover(null);
  };

  return (
    <div className='grave' style={props.style}>
      {open && (
        <PopoverModal
          open={open}
          grave={grave}
          openPhysicalPopover={openPhysicalPopover}
          handlePopoverClose={handlePopoverClose}
        />
      )}
      <GraveImage avatar={grave_display_image?.file_url} />
      <div className='grave-name'>{grave.name}</div>
      <div className='physical-grave'>
        {grave?.category === 'physical' ? (
          <GraveCategory
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
          />
        ) : (
          <GraveTooltip />
        )}
      </div>
      <GraveDate grave={grave} />
      <GraveLocation grave={grave} />
      <div className='plot_number'>
        {grave.plot_number && grave.plot_number !== 'undefined'
          ? grave.plot_number
          : ' '}
      </div>
      <GraveButton grave={grave} />
    </div>
  );
};

export default Grave;
