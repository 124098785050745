import React, { useState } from 'react';
import { Menu, MenuItem, makeStyles } from '@material-ui/core';
import isEmpty from 'lodash.isempty';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styles from './LanguageSelector.module.css';

const useStyles = makeStyles((theme) => ({
  children: {
    fontSize: '14px',
    whiteSpace: 'normal',
    backgroundColor: '#fff!important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)!important',
    },
  },
}));

const LanguageSelector = ({ languages = [], onChanged, preferredLanguage }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async (id) => {
    handleClose();
    onChanged(id);
  };

  return (
    <div>
      <div
        className={styles['language']}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <span>
          {preferredLanguage.trim() !== '' ? preferredLanguage : 'Lng'}
        </span>
        <ArrowDropDownIcon />
      </div>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        elevation={3}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {Array.isArray(languages) &&
          languages.map((lan) => (
            <MenuItem
              key={lan.id}
              onClick={() => handleChange(lan)}
              className={classes.children}
            >
              {lan.key}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default LanguageSelector;
