import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  button: {
    backgroundColor: '#404D56',
    color: '#fff',
    borderRadius: 6,
    padding: '8px 16px',
    width: 'max-content',
    '&:hover': {
      backgroundColor: '#36434B',
    },
    '&:disabled': {
      color: '#404D56 !important',
      backgroundColor: '#eee !important',
      cursor: 'not-allowed !important',
    },
  },
}));

const PrimaryButton = ({
  onClick,
  children,
  style,
  disabled,
  type = 'button',
}) => {
  const classes = useStyle();

  return (
    <Button
      type={type}
      className={classes.button}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
