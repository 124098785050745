import React, { useEffect, useState, useCallback } from 'react';
import DiscardModal from '../../../NewDeceased/atoms/DiscardPopupModal/DiscardModal';
import SavedPopupModal from '../../../NewDeceased/atoms/SavedPopupModal/SavedPopupModal';
import { request } from '../../../../service/request';
import DeleteIcon from '../../../../img/DeleteRedIcon.svg';
import EditIcon from '../../../../img/EditSmallIcon.svg';
import ConformModal from '../ConformDeleteModal';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import CopyInvitationLink from './CopyInvitationLink/CopyInvitationLink';
import ProcessModal from '../../../Payment/atoms/ProcessModal';
import isEmpty from 'lodash.isempty';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash.debounce';
import EditButton from '../../../../components/EditButton/EditButton';
import Input from '../../../../components/Input/Input';
import styles from './GiftSubscriptionForm.module.css';

const GiftSubscriptionForm = ({
  subscriptionInvitations,
  handelSubscriptionInvitations,
  invitation,
  invitationId,
  subscriptionId,
  disable,
  handelInvitationId,
  handleTabChange,
  handelRemainingCount,
  setCount,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openProcessModal, setOpenProcessModal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [copied, setCopied] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    userName: '',
  });

  const validateEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const debouncedValidate = useCallback(
    debounce((value) => {
      if (validateEmail(value)) {
        setIsValidEmail(false);
      } else {
        setIsValidEmail(true);
      }
    }, 500),
    []
  );

  const handleEmailChange = (value) => {
    setFormData({ ...formData, email: value });
    setIsChanged(true);
    debouncedValidate(value);
  };

  const getUserInformation = () => {
    if (formData.email.trim() !== '' && !isValidEmail) {
      const userData = {
        email: formData.email,
      };
      request('/users/find_by_email', userData, 'post')
        .then((res) => {
          if (res.data.full_name) {
            setFormData({
              ...formData,
              userName: res.data.full_name,
              slug: res.data.user_slug,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  let formInputs = [
    {
      label: 'settings_billingEmail',
      type: 'email',
      placeholder: 'type_email',
      value: formData.email,
      autoComplete: 'off',
      error: isValidEmail,
      errorKey: 'email_error',
      disabled: !disable,
      onBlur: getUserInformation,
      action: handleEmailChange,
    },
    {
      label: 'invited_person_name',
      type: 'text',
      placeholder: 'invited_person_name',
      value: formData.userName,
      autoComplete: 'off',
      error: false,
      errorKey: 'auth_notSame',
      disabled: !disable,
      action: (value) => {
        if (value[0] !== ' ') {
          setIsChanged(true);
          setFormData({
            ...formData,
            userName: value
              .replace(/[0-9]/g, '')
              .replace(/[\"'@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/g, ''),
          });
        }
      },
    },
  ];

  useEffect(() => {
    if (!isEmpty(invitation)) {
      setFormData({
        ...formData,
        id: invitation.id,
        userName: invitation.full_name,
        email: invitation.email,
        invitation_token: invitation.invitation_token,
      });
    }
  }, [invitation]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [copied]);

  useEffect(() => {
    if (isFieldsNotEmpty()) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [formData]);

  const isFieldsNotEmpty = () => {
    return (
      formData.userName.trim() !== '' && formData.email !== '' && !isValidEmail
    );
  };

  const deleteRelation = () => {
    if (formData.id) {
      request(`/subscription_invitations/${formData.id}`, null, 'delete')
        .then((res) => {
          const newArray = subscriptionInvitations.filter(
            (invitation) => invitation.id !== formData.id
          );
          handelSubscriptionInvitations(newArray);
          setCount();
          setOpenDeleteModal(false);
          showUserNotification('invitasjonen er slettet', 'success');
        })
        .catch((err) => {
          showUserNotification('noe gikk galt, prøv igjen', 'warning');
        });
      setOpenDeleteModal(false);
    }
  };

  const closeDiscardModal = () => {
    setOpenDiscardModal(false);
    setIsChanged(false);
    if (!isEmpty(invitation)) {
      setFormData({
        ...formData,
        userName: invitation.full_name,
        email: invitation.email,
        id: invitation.id,
      });
      setIsValidEmail(false);
      handelInvitationId(null);
    } else {
      clearRelation();
      setIsValidEmail(false);
      handelInvitationId(null);
    }
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeSaveModal = () => {
    setOpenSaveModal(false);
    handelInvitationId(null);
    handleTabChange();
  };

  const define_env = () => {
    switch (process.env.REACT_APP_ENV) {
      case 'staging':
        return 'https://stage.nettgrav.no';
      case 'production':
        return 'https://nettgrav.no';
      case 'local':
        return 'http://localhost:3000';
      default:
        return 'https://stage.nettgrav.no';
    }
  };

  const baseUrl = define_env();

  const createNewRelative = async () => {
    if (invitationId) {
      const inviteRelation = {
        subscription_invitation: {
          full_name: formData.userName,
          email: formData.email,
          subscription_id: subscriptionId,
        },
      };
      request(
        `/subscription_invitations/${invitationId}`,
        inviteRelation,
        'put'
      )
        .then((res) => {
          setOpenProcessModal(false);
          setOpenSaveModal(true);
          handelSubscriptionInvitations(
            subscriptionInvitations.map((w) =>
              w.id === invitationId
                ? {
                    ...res.data,
                  }
                : w
            )
          );
        })
        .catch((err) => {
          setOpenProcessModal(false);
          showUserNotification('noe gikk galt, prøv igjen', 'error');
        });
    } else {
      const inviteRelation = {
        subscription_invitation: {
          full_name: formData.userName,
          email: formData.email,
          subscription_id: subscriptionId,
          user_slug: formData?.slug ? formData?.slug : null,
        },
      };
      request(`/subscription_invitations`, inviteRelation, 'post')
        .then((res) => {
          handelSubscriptionInvitations([res.data, ...subscriptionInvitations]);
          setOpenProcessModal(false);
          setOpenSaveModal(true);
          clearRelation();
          handelRemainingCount();
        })
        .catch((error) => {
          if (error.response) {
            setOpenProcessModal(false);
            showUserNotification(
              error.response.data.error || error.message,
              'error'
            );
          }
        });
    }
  };

  const clearRelation = () => {
    setFormData({
      email: '',
      userName: '',
    });
  };

  const onEdit = () => {
    handelInvitationId(formData.id);
  };

  return (
    <>
      <div>
        {!disable && (
          <div className={styles['grave-actions-container']}>
            <div className={styles['grave-information-edit-delete-container']}>
              <EditButton
                title='settings_edit'
                onClick={onEdit}
                icon={EditIcon}
              />
              <EditButton
                title='delete'
                onClick={() => setOpenDeleteModal(true)}
                icon={DeleteIcon}
              />
            </div>
          </div>
        )}
        <div className={styles['company-name-container']}>
          {formInputs.map((item, index) => (
            <Input item={item} key={index} />
          ))}
        </div>
        {formData.invitation_token &&
          formData.invitation_token.trim() !== '' && (
            <div className={styles['Link-container']}>
              <div className={styles['link-wrapper']}>
                <div className={styles['link-sub-heading']}>
                  <FormattedMessage id='settings_shareLink' />
                </div>
                {copied && (
                  <div className={styles['copy-link']}>
                    <FormattedMessage id='settings_copyToClipboard' />
                  </div>
                )}
              </div>
              <CopyInvitationLink
                handelSetCopied={(value) => setCopied(value)}
                link={`${baseUrl}/registration?invitation_token=${formData.invitation_token}`}
              />
            </div>
          )}
        {disable && (
          <div className={styles['grave-btn-container']}>
            <div className={styles['grave-information_content-verify']}>
              <button
                type='button'
                className={styles['rootDiscard']}
                onClick={() => {
                  if (isChanged) {
                    setOpenDiscardModal(true);
                  } else {
                    closeDiscardModal();
                  }
                }}
              >
                <FormattedMessage id='settings_cancel' />
              </button>
              <button
                className={
                  disableBtn ? styles['disabledButton'] : styles['rootVerify']
                }
                disabled={disableBtn}
                type='submit'
                onClick={() => {
                  setOpenProcessModal(true);
                  createNewRelative();
                }}
              >
                <FormattedMessage id='auth_save' />
              </button>
            </div>
          </div>
        )}
      </div>
      {openDiscardModal && (
        <DiscardModal
          open={openDiscardModal}
          handleClose={(value) => setOpenDiscardModal(value)}
          onConfirm={closeDiscardModal}
          title='invitation'
        />
      )}
      {openSaveModal && (
        <SavedPopupModal
          open={openSaveModal}
          titleKey='subscription_invitation'
          handleClose={closeSaveModal}
        />
      )}
      {openDeleteModal && (
        <ConformModal
          title='invitation'
          open={openDeleteModal}
          handleClose={closeDeleteModal}
          deletePayment={deleteRelation}
        />
      )}
      {openProcessModal && (
        <ProcessModal open={openProcessModal} messageKey={'payment_progress'} />
      )}
    </>
  );
};

export default GiftSubscriptionForm;
