import React from 'react';
import { Button } from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { FormattedMessage } from 'react-intl';

const TimePickerInput = ({ time, setTimePickerValue }) => {
  const customTimePicker = {
    '& .MuiInputBase-root': {
      border: '1px solid transparent !important',
      background: '#f5f5f5',
      padding: '11px 16px',
      borderRadius: '4px',
      cursor: 'pointer',
      marginBottom: '12px',
      '&:hover': {
        borderColor: '#36434b !important',
      },
    },
  };

  const handleDateChange = (newValue) => {
    if (newValue) {
      setTimePickerValue(newValue);
    } else {
      setTimePickerValue(null);
    }
  };

  const clearTime = (e) => {
    e.preventDefault();
    setTimePickerValue(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        value={time}
        onChange={handleDateChange}
        ampm={false}
        format='HH:mm'
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
        }}
        slotProps={{
          textField: {
            variant: 'standard',
            fullWidth: true,
            sx: customTimePicker,
          },
        }}
      />
      {time && (
        <Button onClick={clearTime} color='#737a7f' type='text'>
          <FormattedMessage id='reset_time' />
        </Button>
      )}
    </LocalizationProvider>
  );
};

export default TimePickerInput;
