import React from 'react';
import styles from './GraveUserForm.module.css';

const UserElement = ({ value, style }) => {
  return (
    <div className={styles['user-name']} style={style}>
      {value}
    </div>
  );
};

export default UserElement;
