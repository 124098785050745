import React, { useEffect, useState } from 'react';
import './Filters.css';
import Input from '../../../components/Input/Input';
import { makeStyles } from '@material-ui/core';
import { fetch_request } from '../../../service/request';
import isEmpty from 'lodash.isempty';
import { fontSize } from '@mui/system';

const Filters = (props) => {
  const [countries, setCountries] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [cemeteries, setCemeteries] = useState([]);

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  useEffect(() => {
    fetch_request('/api/countries', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    props.setChosenCemetery(null);
    props.setChosenMunicipality(null);

    if (props.chosenCountry) {
      fetch_request(
        `/api/municipalities?country_id=${props.chosenCountry.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setMunicipalities(data.municipalities);
        })
        .catch((err) => console.log(err));
    } else {
      setMunicipalities([]);
      setCemeteries([]);
    }
  }, [props.chosenCountry]);

  useEffect(() => {
    props.setChosenCemetery(null);
    if (props.chosenMunicipality) {
      fetch_request(
        `/api/graveyards?graveyard[municipality_id][]=${props.chosenMunicipality.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setCemeteries(data.graveyards);
        })
        .catch((err) => console.log(err));
    } else setCemeteries([]);
  }, [props.chosenMunicipality]);

  const handleOnSelectCountry = (event, value, reason) => {
    if (reason === 'clear') {
      props.setChosenCountry({});
    } else if (reason === 'selectOption') {
      props.setChosenCountry(value);
    }
  };

  const handleOnSelectMunicipality = (event, value, reason) => {
    if (reason === 'clear') {
      props.setChosenMunicipality({});
    } else if (reason === 'selectOption') {
      props.setChosenMunicipality(value);
    }
  };

  const handleOnSelectCemetery = (event, value, reason) => {
    if (reason === 'clear') {
      props.setChosenCemetery({});
    } else if (reason === 'selectOption') {
      props.setChosenCemetery(value);
    }
  };

  let formInputs = [
    {
      label: 'select_country',
      type: 'autocomplete',
      placeholder: 'select_country',
      value: props.chosenCountry,
      options: countries,
      autoComplete: 'off',
      error: false,
      errorKey: 'select_country_error',
      disabled: false,
      labelStyle: {
        fontSize: '16px',
        lineHeight: '16px',
      },
      action: handleOnSelectCountry,
    },
    {
      label: 'select_municipality',
      type: 'autocomplete',
      placeholder: 'select_municipality',
      value: props.chosenMunicipality,
      options: municipalities,
      autoComplete: 'off',
      error: false,
      errorKey: 'select_municipality_error',
      disabled: isEmpty(props.chosenCountry),
      labelStyle: {
        fontSize: '16px',
        lineHeight: '16px',
      },
      action: handleOnSelectMunicipality,
    },
    {
      label: 'select_cemetery',
      type: 'autocomplete',
      placeholder: 'select_cemetery',
      value: props.chosenCemetery,
      options: cemeteries,
      autoComplete: 'off',
      error: false,
      errorKey: 'select_cemetery_error',
      disabled: isEmpty(props.chosenMunicipality),
      labelStyle: {
        fontSize: '16px',
        lineHeight: '16px',
      },
      action: handleOnSelectCemetery,
    },
  ];

  return (
    <div className='search-filters'>
      {formInputs.map((item, index) => (
        <Input key={index} item={item} />
      ))}
    </div>
  );
};

export default Filters;
