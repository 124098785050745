export const isVideoScrolledIntoView = (el) => {
  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;

  var isVisible = elemTop <= 500 && elemTop >= 100;

  return isVisible;
};

export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}/${formattedMonth}/${year}`;
};

export const getSubscriptionDiscount = (discounts, members) => {
  const sortedDiscounts = discounts.sort((a, b) => a.user_count - b.user_count);

  const maxDiscountObject = sortedDiscounts[sortedDiscounts.length - 1];
  const maxUserCount = maxDiscountObject.user_count;

  if (members > maxUserCount) {
    return maxDiscountObject;
  }

  const foundDiscount = sortedDiscounts.find(
    (discount) => discount.user_count === members
  );

  return foundDiscount
    ? foundDiscount
    : { user_count: members, discount_percentage: 0 };
};
