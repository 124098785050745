import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import styles from '../CreateMembership/CreateMembership.module.css';
import loginStyles from './LoginForm.module.css';
import TermsAndCondition from '../CreateMembership/TermsAndCondition';
import PrimaryButton from '../../../components/PrimaryButton';
import Input from '../../../components/Input/Input';
import Header from '../CreateMembership/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomMessage from '../CreateMembership/BottomMessage';
import { useDispatch, useSelector } from 'react-redux';
import {
  setUserId,
  setSubscriptionInvitationData,
  setSubscription,
  setConfirmSignIn,
  getListOfCountries,
} from '../../../redux/actions';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import { request, fetch_request } from '../../../service/request';
import isEmpty from 'lodash.isempty';
import Invitation from './Invitation';
import VerificationModal from './VerificationModal';
import ForgotPassword from './ForgotPassword';

const LoginForm = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    phoneNumber: '',
    rememberMe: false,
    sendMessages: false,
    country_id: '',
  });
  const [hideLoginForm, setHideLoginForm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [hideInput, setHideInput] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [countries, setCountries] = useState([]);
  const userInvitation = useSelector(
    (state) => state.invitationToken.subscriptionInvitationData
  );

  useEffect(() => {
    if (!isEmpty(userInvitation) && userInvitation.email) {
      setFormData({ ...formData, email: userInvitation.email });
    }
  }, [userInvitation]);

  useEffect(() => {
    fetch_request('/api/countries')
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        dispatch(getListOfCountries(data));
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  const validateEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const debouncedValidate = useCallback(
    debounce((value) => {
      if (validateEmail(value)) {
        setIsValidEmail(false);
      } else {
        setIsValidEmail(true);
      }
    }, 500),
    []
  );

  const handleEmailChange = (value) => {
    setFormData({ ...formData, email: value });
    debouncedValidate(value);
  };

  const handlePhoneNumberChange = (value) => {
    const str = value.replace(/[^\d]/g, '');
    if (str.length > 0) {
      setFormData({ ...formData, phoneNumber: str });
    } else {
      setFormData({ ...formData, phoneNumber: '' });
    }
  };

  const findCountry = (countries) => {
    if (countries.length > 0) {
      const defaultCountry = countries.find(
        (country) => country.dial_code === '+47'
      );
      const country = countries.find(
        (country) => country.id === formData.country_id
      );
      return country ? country : defaultCountry;
    }
  };

  const handelHideInput = (type) => {
    if (type === 'email') {
      setFormData({ ...formData, email: '' });
      setIsValidEmail(false);
      setHideInput(!hideInput);
    } else {
      handlePhoneNumberChange('');
      setHideInput(!hideInput);
    }
  };

  let formInputs = [
    {
      label: 'settings_billingEmail',
      type: 'email',
      placeholder: 'type_your_email',
      value: formData.email,
      error: isValidEmail,
      errorKey: 'email_error',
      disabled: false,
      changeInput: {
        label: 'login_with_phone',
        action: (type) => handelHideInput(type),
      },
      action: (value) => {
        handleEmailChange(value);
      },
      hide: hideInput,
    },
    {
      label: 'auth_mobileNumber',
      type: 'text',
      placeholder: 'auth_enterPhoneNumber',
      value: formData.phoneNumber,
      error: false,
      errorKey: 'add_validNumber',
      disabled: false,
      country: {
        country: findCountry(countries),
        setCountry: (value) =>
          setFormData({ ...formData, country_id: value.id }),
      },
      changeInput: {
        label: 'login_with_email',
        action: (type) => handelHideInput(type),
      },
      action: (value) => {
        handlePhoneNumberChange(value);
      },
      hide: !hideInput,
    },
    {
      label: 'settings_password',
      type: 'password',
      placeholder: 'enter_your_password',
      value: formData.password,
      autoComplete: 'password',
      action: (value) => {
        setFormData({ ...formData, password: value });
      },
    },
  ];

  const handleRememberMeChange = () => {
    setFormData({ ...formData, rememberMe: !formData.rememberMe });
  };

  const handleMessageChange = () => {
    setFormData({ ...formData, sendMessages: !formData.sendMessages });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (isValidEmail && formData.email.length === 0) {
      showUserNotification(intl.formatMessage({ id: 'email_error' }));
      return;
    }

    if (formData.password.length === 0) {
      showUserNotification(
        intl.formatMessage({ id: 'set_new_password_error' })
      );
      return;
    }

    const payload = {
      password: formData.password,
    };

    if (formData.phoneNumber.length > 0) {
      payload['phone_number'] = formData.phoneNumber;
      payload['country_id'] = formData.country_id;
    }

    if (formData.email.length > 0) {
      payload['email'] = formData.email;
    }

    request('/sessions', payload, 'post')
      .then((response) => {
        if (response.data.success) {
          const userId = response.data.user.id;
          dispatch(setUserId(userId));
          dispatch(setSubscriptionInvitationData({}));
          dispatch(setSubscription(response.data.user.subscription));
          localStorage.setItem(
            'token',
            response.headers['x-authentication-token']
          );
          localStorage.setItem('auth', 'true');
          localStorage.setItem('user-slug', response.data.user.slug);
          localStorage.setItem('rememberme', formData.rememberMe);

          if (formData.rememberMe) {
            localStorage.setItem('id', response.data.user.id);
          }
          dispatch(setConfirmSignIn());
        }
      })
      .catch((err) => {
        if (err.response.data.status) {
          setOpenModal(true);
        }
        if (err.response.data.error && !err.response.data.status) {
          showUserNotification(err.response.data.error, 'error');
        }
      });
  };

  const resendVerificationEmail = () => {
    setOpenModal(false);
    const payload = {
      user: {
        email: formData.email,
      },
    };
    request(
      `/account_confirmations/resend_account_confirmation_email`,
      payload,
      'post'
    )
      .then((response) => {
        showUserNotification(
          intl.formatMessage({ id: 'sending_verification_email' }),
          'success'
        );
      })
      .catch((err) => {
        showUserNotification(err.response.data.error || err.message, 'error');
      });
  };

  return (
    <React.Fragment>
      <Invitation />
      <div className={loginStyles['login-form']}>
        {!hideLoginForm ? (
          <React.Fragment>
            <Header messageKey='login_description' headingKey='auth_welcome' />
            <form autoComplete='off' onSubmit={handleFormSubmit}>
              {formInputs.map((item, index) =>
                item.hide ? null : <Input item={item} key={index} />
              )}
              <div
                className={loginStyles['forgot']}
                onClick={() => setHideLoginForm(true)}
              >
                <FormattedMessage id='auth_forgotPassword' />
              </div>
              <TermsAndCondition
                checked={formData.rememberMe}
                handleCheckBoxChange={handleRememberMeChange}
                messageKey='auth_rememberMe'
                style={{ marginBottom: '24px' }}
              />
              <TermsAndCondition
                checked={formData.sendMessages}
                handleCheckBoxChange={handleMessageChange}
                messageKey='auth_get_newsletr'
              />
              <div className={styles['btn-container']}>
                <PrimaryButton
                  type='submit'
                  children={<FormattedMessage id='auth_login' />}
                  onClick={handleFormSubmit}
                />
              </div>
            </form>
            <BottomMessage
              messageKey='auth_register'
              url='/registration'
              textKey='auth_notRegistered'
            />
          </React.Fragment>
        ) : (
          <ForgotPassword setHideLoginForm={setHideLoginForm} />
        )}
      </div>
      {openModal && (
        <VerificationModal
          open={openModal}
          close={() => setOpenModal(false)}
          onResend={resendVerificationEmail}
        />
      )}
    </React.Fragment>
  );
};

export default LoginForm;
