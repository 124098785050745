import '../pages/UserProfile/components/UserProfileHeader.css';
import './UserProfileImage.css';
import { makeStyles, Dialog } from '@material-ui/core';
import Cropper from 'react-cropper';
import Camera from '../../src/img/Camera.svg';
import React, { useState, useRef, useEffect } from 'react';
import loadingSpinner from '../../src/service/Loading_animation.gif';
import { makeFileFromURL } from '../../src/service/makeFileFromBlob';
import { request } from '../../src/service/request';
import { useDispatch } from 'react-redux';
import { setInformationAboutUser } from '../../src/redux/actions';
import showUserNotification from '../components/UserNotification/showUserNotification';
import noGender from '../../src/img/SexTypeGrey.svg';
import { fetch_request } from '../../src/service/request';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import BoyAvatar from '../img/BoyAvatar.svg';
import GirlAvatar from '../img/GirlsAvatar.svg';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    '& .MuiDialog-paperWidthSm': {
      width: '900px !important',
    },
  },
}));

const UserProfileImage = (props) => {
  const classes = useStyles();
  const user = props.user;
  const [openCropper, setOpenCropper] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [newCoverPhoto, setNewCoverPhoto] = useState(null);
  const [photoType, setPhotoType] = useState('');
  const [tempImage, setTempImage] = useState('');
  const [tempCoverPhoto, setTempCoverPhoto] = useState('');
  const [crop, setCrop] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isCoverPhotoLoading, setIsCoverPhotoLoading] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuthorized);

  const handleCloseCropper = () => setOpenCropper(false);
  const inputFileProfilePhoto = useRef(null);
  const inputFileCoverPhoto = useRef(null);
  const [relationList, setRelationList] = useState([]);
  const [openSetUserConnectionModal, setOpenUserConnectionModal] =
    useState(false);
  const handleCloseUserConnectionModal = () =>
    setOpenUserConnectionModal(false);
  const [requestAlreadyInQueue, setRequestAlreadyInQueue] = useState(false);
  const handleRequestAlreadyInQueue = () => {
    setRequestAlreadyInQueue(true);
  };

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    fetch_request('/api/relations', requestOptions)
      .then((response) => response.json())
      .then((relations) => setRelationList(relations))
      .catch((err) => console.log(err));
    setRequestAlreadyInQueue(false);
    setTempCoverPhoto('');
    setTempImage('');
  }, [user]);

  const selectTheImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.addEventListener('load', async () => {
        if (file.size / (1024 * 1024) <= 20) {
          if (photoType === 'coverPhoto') {
            setNewCoverPhoto({ file, url: reader.result });
            setOpenCropper(true);
            setAnchorEl(false);
          } else {
            setNewImage({ file, url: reader.result });
            setOpenCropper(true);
            setAnchorEl(false);
          }
        } else {
          showUserNotification(
            'Velg en fil som ikke er større enn 20M',
            'warning'
          );
        }
      });
    }
  };

  const saveCroppedImage = async () => {
    setLoading(true);
    if (crop && newImage) {
      const croppedImage = await makeFileFromURL(
        crop.getCroppedCanvas().toDataURL(),
        newImage.file?.name
      );
      try {
        const formdata = new FormData();
        formdata.append('user[avatar]', croppedImage, newImage.file?.name);
        const headers = {
          'Content-Type': 'multipart/form-data',
        };
        const response = await request(
          `/users/${user.slug}`,
          formdata,
          'put',
          headers
        );
        if (response.status >= 200 && response.status < 300) {
          try {
            const newUserInfo = await request(`/users/${user.slug}`);
            dispatch(setInformationAboutUser(newUserInfo.data));
            setTempImage(newUserInfo.data.user.avatar);
            showUserNotification('Profilbildet er oppdatert', 'success');
            handleCloseCropper();
            setAnchorEl(false);
          } catch (err) {
            console.log(err);
          }
        }
        setLoading(false);
      } catch (err) {
        showUserNotification(err.message, 'warning');
      }
    }
  };

  const saveCoverPhoto = async () => {
    setIsCoverPhotoLoading(true);
    if (crop && newCoverPhoto) {
      const croppedImage = await makeFileFromURL(
        crop.getCroppedCanvas().toDataURL(),
        newCoverPhoto.file?.name
      );
      try {
        const formdata = new FormData();
        formdata.append(
          'user[cover_photo]',
          croppedImage,
          newCoverPhoto.file?.name
        );
        setAnchorEl(false);
        const headers = {
          'Content-Type': 'multipart/form-data',
        };
        const response = await request(
          `/users/${user.slug}`,
          formdata,
          'put',
          headers
        );
        if (response.status >= 200 && response.status < 300) {
          try {
            const newUserInfo = await request(`/users/${user.slug}`);
            dispatch(setInformationAboutUser(newUserInfo.data));
            setTempCoverPhoto(newUserInfo.data.user.cover_photo);
            showUserNotification(
              'Ditt oversiktsbildet er oppdatert',
              'success'
            );
            setAnchorEl(false);
            handleCloseCropper();
          } catch (err) {
            console.log(err);
          }
        }
        setIsCoverPhotoLoading(false);
      } catch (err) {
        showUserNotification(err.message, 'warning');
      }
    }
  };

  const handleProfilePhotoSelection = () => {
    inputFileProfilePhoto.current.click();
    setPhotoType('profilePhoto');
  };

  const handleCoverPhotoSelection = () => {
    inputFileCoverPhoto.current.click();
    setPhotoType('coverPhoto');
  };

  const isUserCurrentUser = () => {
    return (
      user.slug === localStorage.getItem('user-slug') ||
      user.slug === sessionStorage.getItem('user-slug')
    );
  };

  return (
    <>
      <Dialog open={openCropper} onClose={handleCloseCropper}>
        <Cropper
          style={{
            height: '450px',
            width: '100%',
            minHeight: 300,
            minWidth: 300,
          }}
          src={photoType === 'coverPhoto' ? newCoverPhoto?.url : newImage?.url}
          initialAspectRatio={1}
          viewMode={1}
          guides={true}
          zoomOnWheel={false}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          aspectRatio={photoType === 'coverPhoto' ? 16 / 9 : 235 / 260}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCrop(instance);
          }}
        />
        <div className='settings-page_header_cropper-buttons'>
          <button className='cancel-btn' onClick={handleCloseCropper}>
            Avbryt
          </button>
          {!isLoading && photoType === 'profilePhoto' && (
            <button className='save-btn' onClick={saveCroppedImage}>
              Lagre
            </button>
          )}
          {!isCoverPhotoLoading && photoType === 'coverPhoto' && (
            <button className='save-btn' onClick={saveCoverPhoto}>
              Lagre
            </button>
          )}
          {isLoading && (
            <img
              style={{ height: '60px', width: '60px' }}
              src={loadingSpinner}
              alt='2323232'
            />
          )}
          {isCoverPhotoLoading && (
            <img
              style={{ height: '60px', width: '60px' }}
              src={loadingSpinner}
              alt='2323232'
            />
          )}
        </div>
      </Dialog>
      <div className='member-header-content'></div>
      <div
        className='user-profile-display-image-container'
        onClick={handleProfilePhotoSelection}
      >
        <img
          loading='lazy'
          src={
            tempImage
              ? tempImage
              : isEmpty(props.user?.avatar)
              ? props.user?.gender === 'mann'
                ? BoyAvatar
                : GirlAvatar
              : props.user.avatar
              ? props.user.avatar
              : noGender
          }
          alt='user Profile'
          className={
            tempImage
              ? 'user-avatar'
              : isEmpty(props.user?.avatar)
              ? 'user-gender-avatar'
              : props.user.avatar
              ? 'user-profile'
              : 'no-gender'
          }
          key={props.user.avatar}
        />
      </div>

      {isUserCurrentUser() && (
        <div
          className='member-photo_selector'
          onClick={handleProfilePhotoSelection}
        >
          <input
            style={{ display: 'none' }}
            ref={inputFileProfilePhoto}
            type='file'
            accept='image/jpeg, image/jpg, image/png'
            className='test'
            onChange={selectTheImage}
          />
          <img src={Camera} alt='black-camera' />
        </div>
      )}
    </>
  );
};

export default UserProfileImage;
