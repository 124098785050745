import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './AutoComplete.css';
import { useIntl } from 'react-intl';
import WarningMessage from './WarningMessage';

const AutoComplete = ({ item }) => {
  const intl = useIntl();

  const optionsList = {
    options: item.options,
    getOptionLabel: (option) => {
      if (option.key) {
        return intl.formatMessage({ id: option.key });
      } else {
        return option.name || option.reason || option.dialect || '';
      }
    },
  };

  const getValue = (value) => {
    if (value?.key) {
      return {
        ...value,
        key: intl.formatMessage({ id: value.key }),
      };
    } else {
      return value;
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        className={`autocomplete-input ${
          item.error ? 'autocomplete-input-error' : ''
        }`}
        {...optionsList}
        id='relation-combo-box'
        disabled={item.disabled}
        value={getValue(item.value)}
        onChange={item.action}
        renderInput={(params) => (
          <TextField
            placeholder={intl.formatMessage({ id: item.placeholder })}
            {...params}
          />
        )}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#eee',
              padding: '6px',
            },
        }}
      />
      {item.error && <WarningMessage messageKey={item.errorKey} />}
    </React.Fragment>
  );
};

export default AutoComplete;
